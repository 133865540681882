import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, ScrollView } from 'react-native';
import CustomButton from '../../Components/CustomButton'
import FooterText from '../../Components/FooterText'
import global from "../../Styles/global.js"

export default class CreateAccountScreen extends Component {
        constructor(){
                super()
        }

        onSelectRole = (e) =>{
          this.props.navigation.navigate('SignUp',e);
        }

        render() {
                return (
                        <View style = {styles.container}>
                          <ScrollView style={{width:'100%'}} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                          <View style= {Platform.OS === 'ios' || Platform.OS ==='android'? global.ItemsContainerMobile:global.ItemsContainerWeb}>
                            <Text style= {{color: '#1D2195',width:'100%', textAlign: 'left', paddingLeft:28, fontSize:20,fontWeight: 'bold', marginBottom:20}}>Create an Account</Text>
                            <View style= {{width: '100%', display:"flex", justifyContent:"center", alignItems:"center",paddingLeft:30, paddingRight:30}}>
                              <CustomButton title = "Citizen/patient" type="createAccount" screen="createAccount" navigation= {this.props.navigation} onPress={()=>this.onSelectRole("Citizen/patient")}/>
                                <CustomButton title = "Provider" type="createAccount" screen="createAccount" navigation= {this.props.navigation} onPress={()=>this.onSelectRole("Provider")}/>

                              <Text style= {{width:'90%', textAlign: 'left',marginBottom:40, marginTop:25, fontSize:16, color:"#707070"}} >If you are a provider (GP, Ambulance or Specialist you need to apply for a Provider account. Your provider account has to be approved before it will be accesable. </Text>
                            </View>
                            <FooterText/>
                          </View>
                        </ScrollView>
                        </View>
                );
        }
}

const styles = StyleSheet.create({
        container: {
                flex:1,
                width:'100%',
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor:'#501D95',
                paddingTop:50,
              },

        headerContainer:{
                flex:2,
                height:'100%',
                marginTop:30,
                width: '100%',
                flexDirection: 'column',
                alignItems:'center',
                justifyContent:'center',
        },
        formContainer:{
                flex:4,
                width: '100%',
                marginTop:30,
                alignItems:'center',
                justifyContent:'center',
        },
        footerContainer:{
                flex:1,
                width: '100%',
                alignItems:'center',
                marginBottom:30,
                justifyContent:'center',
        },
        InputContainer:{
                flexDirection:'row',
                alignItems:'center',
                paddingLeft:10,
                justifyContent:'space-between',
                marginTop:10,
                marginBottom:10,
                width:'90%',
                // justifyContent:'center',

        },
        buttonContainer:{
                flexDirection:'row',
                alignItems:'center',
                marginTop:50,
                marginBottom:10,
                width:'90%',
                // justifyContent:'center',

        },
        loginButton:{
                borderRadius:5,

        },
        seperator:{
                backgroundColor:'#ffffff66',
                width:'90%',
                height:2,
                marginTop: -10
        },
        seperator1:{
                backgroundColor:'#ffffff33',
                width:'90%',
                height:1,
                marginTop: -7
        }
 })
