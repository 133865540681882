import React, { Component } from 'react';
import { View,Text,StyleSheet, Image, ImageBackground} from 'react-native';

export default class CustomHeader extends Component {
  render() {
    return (
      <View style = {{ display:'flex', flexDirection:'row',width:'100%', alignItems:'center', justifyContent:'center', width:"100%"}}>
        {this.props.title == "showImage"?
          <View style={{display:'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'center', paddingLeft:-10}}>
            <ImageBackground source={require('../../assets/Headline.png')} style={{width: 150, height: 28}}/>
            {/*
              <ImageBackground source={require('../../assets/Heart-1.png')} style={{width: 42, height: 36, marginLeft:10, marginRight:10}}/>
              */}
            </View>
            :
            <View style={{display:'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'center'}}>
              {this.props.circleValue !=null?
                <View style= {{width:"15%", alignSelf:"flex-start", justifyContent:"flex-start"}}>
                  <View style= {{width:30, height:30,alignItems:'center', justifyContent:'center' , borderRadius:100,backgroundColor:'#9126FB'}}>
                    <Text style= {{textAlign:'center', color:'white', fontWeight:'700',fontSize:18}}>{this.props.circleValue}</Text>
                  </View>
                </View>
                :null
              }
              <View style= {{width:"85%"}}>
                {
                  this.props.circleValue !=null
                  ?
                  <Text style={{textAlign:'center', marginLeft: '-17%',fontSize:this.props.fontSize, fontWeight:'bold',color:'#1D2195' }}>{this.props.title}</Text>
                  :
                  <Text style={{textAlign:'center', fontSize:this.props.fontSize, fontWeight:'bold',color:'#1D2195' }}>{this.props.title}</Text>

                }
                {
                  (this.props.subTitle && this.props.circleValue !=null)
                  ?
                  <Text style={{textAlign:'center', marginLeft: '-17%', fontSize:this.props.fontSub, color:'#1D2195' }}>{this.props.subTitle}</Text>
                  :
                  (this.props.subTitle)
                  ?
                  <Text style={{textAlign:'center', fontSize:this.props.fontSub, color:'#1D2195' }}>{this.props.subTitle}</Text>
                  :
                  null
                }

              </View>
            </View>
          }
        </View>
      );
    }
  }
