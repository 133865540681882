import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions,
  KeyboardAvoidingView
} from 'react-native';
import {  CheckBox } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import CustomMainScreenItem from '../../Components/CustomMainScreenItem'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import CustomDropDown from '../../Components/CustomDropDown'
import PurpleCircle from '../../Components/PurpleCircle';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export default class WitnessScreen extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,

    name: '',
    phone: '',
    dropDownState :false,
    dropDown1: 'Registered professionals such as teachers, nurses, doctors or pharmacists',
    dropDown2: 'Lawyers or Justices of the Peace (JP)',
    dropDown3: 'Local, State or Commonwealth Government employees with more than five (5) years continuous service',
    dropDown4: 'Bank managers or police officers with more than five (5) years continuous service',
    dropDown5: 'Ministers of religion or marriage celebrants',

    mainSubstitute:false,
    skipSubstitute:false
  }
  console.log('global.userData', global.userData.userData.acd.witness);
  this.jsonUser = global.userData.userData.acd.witness

  this.getLocalKeys()
}


getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}
componentDidMount () {
  this.setValues();
}
setValues =()=>{

  console.log('this.jsonUser', this.jsonUser);
  this.setState(this.jsonUser)
  setTimeout( () => {
    console.log('this.state', this.state);

  }, 100);
}

handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}


handleWitnessHelp = (e) =>{
  console.log('in 53');
  this.props.navigation.navigate("WitnessScreen53")
}

handleSubstitutionMaker = (e) =>{
  if(this.state.name && this.state.phone && this.state.category){
    console.log("handle2Next", this.jsonUser);
    this.jsonUser.name = this.state.name
    this.jsonUser.phone = this.state.phone
    this.jsonUser.category = this.state.category
    this.jsonUser.email = this.state.email
    this.jsonUser.executuion_statement = this.state.executuion_statement
    this.jsonUser.isComplete = true
    console.log('this.jsonUser', this.jsonUser);
    global.userData.userData.acd.witness = this.jsonUser
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

    console.log('global.userData.userData.acd.witness', global.userData.userData.acd.witness);
    console.log('global.userData.userData.acd', global.userData.userData.acd);

    this.props.navigation.navigate("WitnessScreen54")
  }else{
    console.log('in else');
    alert("Complete All Fields")
  }

}


handleDropDown =()=>{
  this.setState({
    dropDownState: !this.state.dropDownState
  })
}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

handleSelect = (e) =>{
  console.log("selected value :: ", e);
}
handleDropDownValue = (e) =>{

  this.setState({category: e, dropDownState: !this.state.dropDownState})


}
render() {
  return (

    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>

        {
          (Platform.OS === 'ios' || Platform.OS ==='android')
          ?
          <KeyboardAwareScrollView style={global.scrollContainer}
            enableOnAndroid
            >

            <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

              {this.state.UserComponent}

              <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
                <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                  <TouchableOpacity style={{width:'100%'}}>
                    <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                      <CustomMainScreenItem color='#1D2195' disable='true' onPress={this.handleWitnessHelp} title = "            Witness" purpleCircle='enable' fontSize={14} />
                    </View>
                  </TouchableOpacity>

                  <View style={{width:"90%", alignItems: 'center', marginTop: 20,justifyContent: 'center'}}>
                    <View style = {styles.inputField}>

                      <TextInput
                        editable
                        style={styles.textInputStyle}
                        onChangeText={name => this.setState({ name })}
                        value={this.state.name}
                        ref={(ip) => this.fullName = ip}
                        />
                      <Text style= {styles.textstyle} onPress={() => {this.fullName.focus()}}>Full Name</Text>
                    </View>

                    <View style = {styles.inputField}>

                      <TextInput
                        editable
                        style={styles.textInputStyle}
                        keyboardType = "phone-pad"
                        onChangeText={phone => this.setState({ phone })}
                        value={this.state.phone}
                        ref={(ip) => this.Phone = ip}
                        />
                      <Text style= {styles.textstyle} onPress={() => {this.Phone.focus()}}>Phone</Text>
                    </View>

                    <View style = {styles.inputField}>

                      <TextInput
                        editable
                        style={styles.textInputStyle}
                        keyboardType = "email-address"
                        onChangeText={email => this.setState({ email })}
                        value={this.state.email}
                        ref={(ip) => this.Email = ip}
                        />
                      <Text style= {styles.textstyle} onPress={() => {this.Phone.focus()}}>Email</Text>
                    </View>

                    <View style = {[styles.inputField,{ alignItems:'flex-start'}]}>

                      <TextInput
                        editable
                        multiline = {true}
                        style= {this.state.category ?[styles.textInputStyle2, {width:'60%', textAlignVertical: "top"}]:[styles.textInputStyle,{width:'60%', textAlignVertical: "top"}]}
                        value={this.state.category}
                        />
                      <Text onPress={this.handleDropDown} style= {[styles.textstyle,{display:'flex', flexDirection:'row' , marginTop:10}]}>Witness category {"\n"} ▼</Text>
                    </View>
                    {this.state.dropDownState == false ? null
                      :
                      <View style = {{ display:'flex', flexDirection:'column',width:'100%', alignItems:'center', justifyContent:'center',marginTop:-30}}>
                        <TouchableOpacity onPress={() => this.handleDropDownValue(this.state.dropDown1)} style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                          <View style={{width:"100%", alignItems: 'center',height:60, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                            <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                              <Text style={styles.descriptionText}>{this.state.dropDown1}</Text>
                            </View>
                          </View>
                        </TouchableOpacity>



                        <TouchableOpacity onPress={() => this.handleDropDownValue(this.state.dropDown2)} style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                          <View style={{width:"100%", alignItems: 'center',height:60, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                            <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                              <Text style={styles.descriptionText}>{this.state.dropDown2}</Text>
                            </View>
                          </View>
                        </TouchableOpacity>


                        <TouchableOpacity onPress={() => this.handleDropDownValue(this.state.dropDown3)} style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                          <View style={{width:"100%", alignItems: 'center',height:80, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                            <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                              <Text style={styles.descriptionText}>{this.state.dropDown3}</Text>
                            </View>
                          </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => this.handleDropDownValue(this.state.dropDown4)} style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                          <View style={{width:"100%", alignItems: 'center',height:80, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                            <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                              <Text style={styles.descriptionText}>{this.state.dropDown4}</Text>
                            </View>
                          </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => this.handleDropDownValue(this.state.dropDown5)} style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                          <View style={{width:"100%", alignItems: 'center',height:80, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                            <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                              <Text style={styles.descriptionText}>{this.state.dropDown5}</Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    }
                    <Text style={{fontWeight:'bold',fontSize:14, width:'95%', fontWeight:"bold", color:"grey"}}>Space for extra execution statement:</Text>

                    <View style={{  borderRadius:5,width:'90%',height:120,paddingTop:5,marginTop:20, marginBottom:20 ,borderColor: '#1D2195',borderWidth: 1,}}>
                      <TextInput
                        multiline
                        numberOfLines={20}
                        onChangeText={(executuion_statement) => {this.setState({executuion_statement})}}
                        value={this.state.executuion_statement}
                        style={{paddingLeft:10, paddingTop:4, paddingRight:10, height:120, textAlignVertical: "top"}}
                        />
                    </View>

                  </View>


                </View>
              </View>
            </View>
          </KeyboardAwareScrollView>

          :
          <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

            {this.state.UserComponent}

            <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
              <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                <TouchableOpacity style={{width:'100%'}}>
                  <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                    <CustomMainScreenItem color='#1D2195' disable='true' onPress={this.handleWitnessHelp} title = "Witness" marginLeft="45%" marginRight="3%" purpleCircle='enable' fontSize={14} />
                  </View>
                </TouchableOpacity>


                <View style={{width:"90%", alignItems: 'center', marginTop: 20,justifyContent: 'center'}}>
                  <View style = {styles.inputField}>

                    <TextInput
                      editable
                      style={styles.textInputStyle}
                      onChangeText={name => this.setState({ name })}
                      value={this.state.name}
                      ref={(ip) => this.fullName = ip}
                      />
                    <Text style= {styles.textstyle} onPress={() => {this.fullName.focus()}}>Full Name</Text>
                  </View>

                  <View style = {styles.inputField}>

                    <TextInput
                      editable
                      style={styles.textInputStyle}
                      keyboardType = "phone-pad"
                      onChangeText={phone => this.setState({ phone })}
                      value={this.state.phone}
                      ref={(ip) => this.Phone = ip}
                      />
                    <Text style= {styles.textstyle} onPress={() => {this.Phone.focus()}}>Phone</Text>
                  </View>

                  <View style = {styles.inputField}>

                    <TextInput
                      editable
                      style={styles.textInputStyle}
                      keyboardType = "email-address"
                      onChangeText={email => this.setState({ email })}
                      value={this.state.email}
                      ref={(ip) => this.Email = ip}
                      />
                    <Text style= {styles.textstyle} onPress={() => {this.Phone.focus()}}>Email</Text>
                  </View>

                  <View style = {[styles.inputField,{ alignItems:'flex-start'}]}>

                    <TextInput
                      editable
                      multiline = {true}
                      style= {this.state.category ?[styles.textInputStyle2, {width:'60%', textAlignVertical: "top"}]:[styles.textInputStyle,{width:'60%', textAlignVertical: "top"}]}
                      value={this.state.category}
                      />
                    <Text onPress={this.handleDropDown} style= {[styles.textstyle,{display:'flex', flexDirection:'row' , marginTop:10}]}>Witness category {"\n"} ▼</Text>
                  </View>
                  {this.state.dropDownState == false ? null
                    :
                    <View style = {{ display:'flex', flexDirection:'column',width:'100%', alignItems:'center', justifyContent:'center',marginTop:-30}}>
                      <TouchableOpacity onPress={() => this.handleDropDownValue(this.state.dropDown1)} style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                        <View style={{width:"100%", alignItems: 'center',height:60, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                          <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                            <Text style={styles.descriptionText}>{this.state.dropDown1}</Text>
                          </View>
                        </View>
                      </TouchableOpacity>



                      <TouchableOpacity onPress={() => this.handleDropDownValue(this.state.dropDown2)} style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                        <View style={{width:"100%", alignItems: 'center',height:60, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                          <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                            <Text style={styles.descriptionText}>{this.state.dropDown2}</Text>
                          </View>
                        </View>
                      </TouchableOpacity>


                      <TouchableOpacity onPress={() => this.handleDropDownValue(this.state.dropDown3)} style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                        <View style={{width:"100%", alignItems: 'center',height:80, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                          <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                            <Text style={styles.descriptionText}>{this.state.dropDown3}</Text>
                          </View>
                        </View>
                      </TouchableOpacity>

                      <TouchableOpacity onPress={() => this.handleDropDownValue(this.state.dropDown4)} style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                        <View style={{width:"100%", alignItems: 'center',height:80, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                          <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                            <Text style={styles.descriptionText}>{this.state.dropDown4}</Text>
                          </View>
                        </View>
                      </TouchableOpacity>

                      <TouchableOpacity onPress={() => this.handleDropDownValue(this.state.dropDown5)} style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                        <View style={{width:"100%", alignItems: 'center',height:80, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                          <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                            <Text style={styles.descriptionText}>{this.state.dropDown5}</Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  }
                  <Text style={{fontWeight:'bold',fontSize:14, width:'95%', fontWeight:"bold", color:"grey"}}>Space for extra execution statement:</Text>

                  <View style={{  borderRadius:5,width:'90%',height:120,paddingTop:5,marginTop:20, marginBottom:20 ,borderColor: '#1D2195',borderWidth: 1,}}>
                    <TextInput
                      multiline
                      numberOfLines={20}
                      onChangeText={(executuion_statement) => {this.setState({executuion_statement})}}
                      value={this.state.executuion_statement}
                      style={{paddingLeft:10, paddingTop:4, paddingRight:10, height:120, textAlignVertical: "top"}}
                      />
                  </View>

                </View>


              </View>
            </View>
          </View>

        }
      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleSubstitutionMaker}/>
        <ACDfooter  title="6" marginLeft="80%" />
      </ScrollView>
    </View>
  );

}
}

const styles = StyleSheet.create({

  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    minHeight:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    minHeight: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textInputStyle2:{
    minHeight: 65,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
  descriptionText:{
    marginBottom:10,
    fontWeight:'bold',
    marginLeft:15,
    marginRight:15,
    textAlign:'left',
    color:'#1D2195',
  },


})
