import React, { Component } from 'react';
import { View, Text, StyleSheet, ImageBackground , TouchableOpacity} from 'react-native';

export default class NoButtonNorthern extends Component {
  render() {
    // var icon = require('../../assets/icons/address.png')
    // if (this.props.icon == 'name') {
    //   icon = require('../../assets/icons/name.png')
    // }
    // if (this.props.icon == 'email') {
    //   icon = require('../../assets/icons/email.png')
    // }
    // if (this.props.icon == 'phone') {
    //   icon = require('../../assets/icons/Phone.png')
    // }
    // if (this.props.icon == 'gp') {
    //   icon = require('../../assets/icons/email.png')
    // }

    return (
      <TouchableOpacity onPress={this.props.onPress}>
          <Text style={{color:'green', fontSize:12}}>{this.props.title}</Text>
      </TouchableOpacity>
    );
  }
}
