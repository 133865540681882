import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, AsyncStorage, Alert, ScrollView, ActivityIndicator, TouchableOpacity } from 'react-native';
import CustomButton from '../../Components/CustomButton'
import FooterText from '../../Components/FooterText'
import { Avatar } from 'react-native-elements';
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import global from "../../Styles/global.js"
import axios from 'axios';
import EventBus from 'eventing-bus'
import UserComponent from '../../Components/UserComponent'

export default class UploadImageContact extends Component {
  constructor(){
    super()
    this.state = {
      image:"https://res.cloudinary.com/hqepnl8tg/image/upload/v1571642768/cam_pnlffd.png",
      showButton: false
    }
    this.getLocalKeys()
  }
  componentDidMount() {
    this.getPermissionAsync();

  }

  getPermissionAsync = async () => {
    if (Constants.platform.ios) {
      const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    }
  }

  // getLocalKeys = async () =>{
  //   const value = await AsyncStorage.getItem('@UserData');
  //   if (value !== null) {
  //     var data = JSON.parse(value)
  //     console.log("UserData :: ", data);
  //     if (data.accountStatus == "pending") {
  //       this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
  //     }
  //     else {
  //       this.setState({data:data})
  //       this.setState({image:data.profileImg})
  //     }
  //   }
  // }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);


      this.setState({userName:data.name})
      this.setState({userAddress:data.address})
      console.log('====================================');
      console.log("Data::::::", data);
      console.log('====================================');
      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
      console.log('====================================');
      console.log("Data:::::: after setstate", data);
      console.log('====================================');

    }
  }



  uploadFromGallery = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      // aspect: [4, 3],
      base64: false

    });


    console.log(result);


    if (!result.cancelled) {
      this.setState({ image:result.uri, showButton: true });

    }


  }

  uploadFromCamera = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      // aspect: [4, 3],
      base64: false

    });


    console.log(result);

    if (!result.cancelled) {
      this.setState({ image:result.uri, showButton: true });
    }
  }


  // pickImage = async () => {
  //   let result = await ImagePicker.launchImageLibraryAsync({
  //     mediaTypes: ImagePicker.MediaTypeOptions.All,
  //     allowsEditing: true,
  //     aspect: [4, 3],
  //     base64: false
  //
  //   });
  //
  //
  //   console.log(result);
  //
  //
  //   if (!result.cancelled) {
  //     this.setState({ image:result.uri });
  //
  //   }
  // }


  pickImage = async () => {
    console.log("Alert");
    if (Platform.OS !== 'web') {
      Alert.alert(
        'Alert',
        'Select option to upload file',
        [
          { text: 'Cancel', onPress: () => console.log('Cancel Pressed') },
          {
            text: 'Upload from Gallery',
            onPress: () => this.uploadFromGallery(),
            style: 'cancel',
          },
          { text: 'Open Camera', onPress: () => this.uploadFromCamera() },
        ],
        { cancelable: true }
      );
    }
    else {
      this.uploadFromGallery()
    }
  }


  handleUploadImage = async ()=>{
    console.log('this.state.image', this.state.image);

    this.setState({activityProcess: true})
    const user = await AsyncStorage.getItem('@UserData');
    let userParsed = JSON.parse(user)
    let token = await AsyncStorage.getItem('@token');

    console.log('this.state.image', this.state.image);
    console.log('user', user);
    console.log('user', userParsed);

    let bodyData = await new FormData();
    if(Platform.OS === 'ios' || Platform.OS ==='android'){
      bodyData.append("userFile", {
        uri:this.state.image,
        type:'image/jpeg', // or photo.type
        name:'upload.png',
      } )
      console.log('url', global.url+ '/api/uploadProfileImg/' + userParsed._id);

      console.log('token', JSON.parse(token));
      fetch(global.url+ '/api/uploadProfileImg/' + userParsed._id,
      {
        method: 'POST',
        headers: {'Authorization': JSON.parse(token) } ,
        body: bodyData,
      }
    )
    .then( async (response)=> {
      let newResponse = await response
      console.log('###responce picture post', newResponse);
      if (response.status == 200) {
        console.log('in response.status 200');
        this.getSingleUser();

      }
    })
    .catch(err => console.log('###error api', err))
  }else{
    bodyData.append("base64Image",this.state.image)
    console.log('url', global.url+ '/api/uploadProfileImg/' + userParsed._id);

    console.log('token', JSON.parse(token), this.state.image);
    fetch(global.url+ '/api/uploadProfileImg/' + userParsed._id,
    {
      method: 'POST',
      headers: {} ,
      body: bodyData,
    }
  )
  .then( async (response)=> {
    let newResponse = await response
    console.log('###responce picture post', newResponse);
    if (response.status == 200) {
      console.log('in response.status 200');
      this.getSingleUser();

    }
  })
  .catch(err => console.log('###error api', err))
}
}

getSingleUser = async () =>{
  console.log('in getSingeluser');

  let token = await AsyncStorage.getItem('@token');
  const user = await AsyncStorage.getItem('@UserData');
  let userParsed = JSON.parse(user)
  console.log(userParsed);
  var optionsAxios = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(token)},
    url: global.url+'/api/getSingleUser'
  }
  console.log(" getUser Options axios ", optionsAxios );
  axios(optionsAxios)
  .then((response)=> {
    console.log("/user Response recieved ", response);
    if(response.data){
      global.profileImg = response.data.user.profileImg
      AsyncStorage.setItem("@UserData", JSON.stringify(response.data.user))
      EventBus.publish("changeProfile")
      setTimeout( () =>{
        // this.props.navigation.goBack()
        this.props.navigation.navigate("Login")

      }, 1000);


      console.log('in success response');
    }else{
      console.log(response.data.message);
    }
  })
  .catch((error)=>{

    console.log("error getUserData :: ", error);
  })
}

render() {
  return (

    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>

    <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'85%',width:'100%'}]:[{height:'85%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
    <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
    <View style = {global.mainInsideContainer}>
    <TouchableOpacity style={{width:'100%'}}>
    {this.state.UserComponent}
    </TouchableOpacity>
    </View>
    <Text style= {{marginTop: 10, color: '#1D2195',width:'100%', textAlign: 'left', paddingLeft:28, fontSize:20,fontWeight: 'bold'}}>Upload Image</Text>
    <View style= {{display:'flex', justifyContent:"center", alignItems:"center"}}>
    <View style= {{alignItems: 'center', justifyContent:"center", marginTop:80}}>
    <Avatar
    size="xlarge"
    rounded
    onPress={() => this.pickImage()}
    source={{
      uri: this.state.image
    }}

    />
    </View>

    </View>
    </View>
    </ScrollView>

    <ScrollView style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'15%', width:'100%'}]:[{height:'15%', width:'100%', maxWidth:800, minWidth:800}]}
    contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
    {
      (this.state.showButton)
      ?
      <View>

      {
        (this.state.activityProcess)
        ?
        <ActivityIndicator size="small" color="#9126fb" />
        :
        <CustomButton title = "Upload Image" type = "inScreen" onPress= {this.handleUploadImage} navigation= {this.props.navigation}/>
      }
      </View>
      :
      null
    }
    </ScrollView>

    </View>
  );
}
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
    paddingTop:50,
  },
  headerContainer:{
    flex:2,
    height:'100%',
    marginTop:30,
    width: '100%',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center',
  },
  formContainer:{
    flex:4,
    width: '100%',
    marginTop:30,
    alignItems:'center',
    justifyContent:'center',
  },
  footerContainer:{
    flex:1,
    width: '100%',
    alignItems:'center',
    marginBottom:30,
    justifyContent:'center',
  },
  InputContainer:{
    flexDirection:'row',
    alignItems:'center',
    paddingLeft:10,
    justifyContent:'space-between',
    marginTop:10,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  seperator:{
    backgroundColor:'#ffffff66',
    width:'90%',
    height:2,
    marginTop: -10
  },
  seperator1:{
    backgroundColor:'#ffffff33',
    width:'90%',
    height:1,
    marginTop: -7
  }
})
