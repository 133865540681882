import React, { Component } from 'react';
import { View,
  Text,
  StyleSheet,
  AsyncStorage,
  ScrollView,
  TextInput,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  ImageBackground ,
  Alert
} from 'react-native';
import global from "../../Styles/global";
import UserComponent from '../../Components/UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomButton from '../../Components/CustomButton';
import { NavigationEvents } from 'react-navigation';
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';

export default class ResourcesStart extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,

      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  // global.userData = require('../../../Data/northern_acd.json')
  global.jsonDatatest.forEach((i,idx,x)=>{
    if (i.title == 'ACD overeview - 15') {
      this.jsonData = i
    }
  })
  this.getLocalKeys()
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);


    this.setState({userName:data.name})
    this.setState({userAddress:data.address})
    console.log('====================================');
    console.log("Data::::::", data);
    console.log('====================================');
    this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})

    console.log('====================================');
    console.log("Data:::::: after setstate", data);
    console.log('====================================');

  }
}
onWillFocus = ()=>{
  this.getLocalKeys()
}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

pickImage = async () => {
  console.log("Alert");
  if (Platform.OS !== 'web') {
    Alert.alert(
      'Alert',
      'Select option to upload file',
      [
        { text: 'Cancel', onPress: () => console.log('Cancel Pressed') },
        {
          text: 'Upload from Gallery',
          onPress: () => this.uploadFromGallery(),
          style: 'cancel',
        },
        { text: 'Open Camera', onPress: () => this.uploadFromCamera() },
      ],
      { cancelable: true }
    );
  }
  else {
    this.uploadFromGallery()
  }
}

uploadFromGallery = async () => {
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    // aspect: [4, 3],
    base64: false

  });
  console.log(result);
  if (!result.cancelled) {
    this.setState({ image:result.uri });
    if(this.state.resource){
      this.props.navigation.navigate("ACDoverview16", {image:result.uri, resource:this.state.resource})
    }else{
      this.props.navigation.navigate("ACDoverview16", {image:result.uri, resource:'Resource'})
    }
  }
}

uploadFromCamera = async () => {
  let result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    // aspect: [4, 3],
    base64: false

  });

  console.log(result);
  if (!result.cancelled) {
    this.setState({ image:result.uri });
    if(this.state.resource){
      this.props.navigation.navigate("ACDoverview16", {image:result.uri, resource:this.state.resource})
    }else{
      this.props.navigation.navigate("ACDoverview16", {image:result.uri, resource:'Resource'})
    }
  }
}

render() {
  return (

    <View style={global.container}>
      <ScrollView style={{width:'100%'}} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center', marginBottom:80}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:80}]:[global.ItemsContainerWeb,{minHeight:675}]}>

          {this.state.UserComponent}

          <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontSize:14, color:"#1D2195", width:"90%", marginBottom:50}}>
              {this.jsonData.description[0]}

            </Text>

            <View style = {[styles.inputField,{marginBottom:60}]}>
              <TextInput
                editable
                ref={(ip) => this.email = ip}
                style={styles.textInputStyle}
                onChangeText={resource => this.setState({ resource })}
                value={this.state.resource}
                />
              <Text style= {styles.textstyle} onPress={() => {this.email.focus()}}>Resource</Text>
            </View>


            <TouchableOpacity onPress={() => this.pickImage()}>
              <ImageBackground
                source={require('../../../assets/cam.png')}
                style={{height:160, width:200}}/>
            </TouchableOpacity>


          </View>

        </View>

      </ScrollView>
    </View>
  );
}
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
    //     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },
  textSmall:{
    width:'100%',
    alignItems:'flex-start',
    marginTop:50,
    color:'#1D2195',
  },
  textBold:{
    width:'100%',
    alignItems:'flex-start',
    marginTop:50,
    color:'#1D2195',
    fontWeight:'bold'
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
})
