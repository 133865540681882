import React, { Component } from 'react';
import { View, Text, StyleSheet, ImageBackground, TouchableOpacity } from 'react-native';
import PurpleCircle from './PurpleCircle';

export default class CustomMainScreenItem extends Component {

  render() {
    let color = ''
    let weight = ''
    let bgColor ='#9126FB'
    if(this.props.color != ''){
      color = this.props.color
      weight = 'normal'

    }else{
      color = '#1D2195'
      weight = 'normal'
    }

    if(this.props.bgColor != '' && this.props.bgColor){
      bgColor = this.props.bgColor

    }else{
      bgColor = '#9126FB'
    }

    return (

      <View 
        style = {(this.props.title === 'Medication Summary' || this.props.title === 'Medical History') ?
          {display:'flex',flexDirection:'row',alignItems:'center', width:'100%',paddingTop:10,paddingBottom:10 ,backgroundColor:'#b19cd9', opacity:0.7}
          :{display:'flex',flexDirection:'row',alignItems:'center', width:'100%',paddingTop:10,paddingBottom:10 ,backgroundColor:'#F9F1FF'}}
         >

        { this.props.disable == 'true'?null
          :
          <View style={{width:'20%', alignItems:'center', justifyContent:'center'}}>

            <View style= {{width:40, height:40,alignItems:'center', justifyContent:'center' ,borderRadius:100, backgroundColor:bgColor}}>
              <Text style= {{textAlign:'center', color:'white', fontSize:24}}>{this.props.circleValue}</Text>
            </View>
          </View>
        }

        {
          this.props.disable == 'true'?
        <Text style={this.props.marginLeft ?[{textAlign:'left',marginLeft:this.props.marginLeft,marginRight: -40 ,width:'70%' ,fontSize:this.props.fontSize, color:color, fontWeight: weight}] : [{textAlign:'left',marginLeft:'25%',marginRight: -40 ,width:'70%' ,fontSize:this.props.fontSize, color:color, fontWeight: weight}]}>
          {this.props.title}
        </Text>
        :
        <Text style={{textAlign:'left',width:'70%' ,fontSize:this.props.fontSize, color:color, fontWeight: weight}}>{this.props.title}</Text>
        }

        {this.props.checkBox == 'show'?
          <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
            <ImageBackground source={require('../../assets/Checkbox_check.png')} style={{width: 20, height: 20}}/>
          </View>
          :null
        }
        {this.props.checkBox == 'empty'?
          <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
          </View>
          :null
        }

        {this.props.purpleCircle == 'enable'?
         <View style={this.props.marginRight ? [{marginRight:this.props.marginRight, width:'10%',height:25,width:25, alignItems:'center', justifyContent:'center'}] : [{ width:'10%',height:25,width:25, alignItems:'center', justifyContent:'center'}]}>
         <PurpleCircle circleText="?" onPress={this.props.onPress}/>
         </View>
         :null
        }

      </View>
    );
  }
}
