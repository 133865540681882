import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions, Image } from 'react-native';
import global from "../../../Styles/global";
import UserComponent from '../UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomMainScreenItem from '../CustomMainScreenItem';
import PurpleCircle from '../PurpleCircle';
import { NavigationEvents } from 'react-navigation';
import CustomButton from '../CustomButton'
import ACDfooter from '../ACDfooter';
import {  CheckBox } from 'react-native-elements'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DatePicker from 'react-native-datepicker'


export default class AppointingSubstitute1 extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,

      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  // require('../../assets/Profile.png')
  console.log('global.userData in advance_care_statement', global.userData.userData.acd);
  this.jsonUserMain = global.userData.userData.acd.appointing_substitute_decision_makers
  this.jsonUser = global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[0]

  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == "North Substitute decision-Maker 1") {
      this.jsonData = i
    }
  })


  this.getLocalKeys()
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);


    this.setState({userName:data.name})
    this.setState({userAddress:data.address})
    console.log('====================================');
    console.log("Data::::::", data);
    console.log('====================================');
    this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})

    console.log('====================================');
    console.log("Data:::::: after setstate", data);
    console.log('====================================');
    this.setValues()

  }
}
componentDidMount(){
  this.setValues()

}

setValues =()=>{

  console.log('this.jsonUser', this.jsonUser);
  this.setState(this.jsonUser)
  this.setState({checkbox1:this.jsonUser.checkBox1.isChecked, checkbox2:this.jsonUser.checkBox2.isChecked, checkbox3:this.jsonUser.checkBox3.isChecked, checkbox4:this.jsonUser.checkBox4.isChecked})

  setTimeout( () => {
    console.log('this.state', this.state);

  }, 100);
}

navigateProfile = () =>{
  // console.log('in UploadImage');
  // this.props.navigation.navigate("UploadImage")
}
onWillFocus = ()=>{
  console.log('in onWillFocus');

}

handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}
handleacd= () =>{
  this.props.navigation.navigate("AcdHome")
}
handleItemPress= ()=>{
  this.props.navigation.navigate('AcdSignUp')
}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}
UploadImage = () =>{
  this.props.navigation.navigate("UploadImage")

}
handleAcdOverview = () =>{
  this.props.navigation.navigate("AdvanceCare1")

}

handle2Next = () =>{
  this.props.navigation.navigate("AppointingSubstitute2")
}

handleSubstitutionMaker = (e) =>{

  const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g

  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


  if (!emailRegex.test(this.state.email )) {
    if (this.state.email == undefined || this.state.email == '') {
      alert("Email is required");
      return;
    }
    else{
      alert("Invalid Email")
      return;

    }
  }else if(!nameRegex.test(this.state.name )) {
    if(this.state.name == undefined || this.state.name == ''){
      alert("Name is required")
      return;

    }
    else{
      alert("Invalid Name")
      return;

    }
    // alert("Name is required");
  }else{
    if(this.state.checkbox4 === true){
      if(this.state.other){
        console.log("handle2Next", this.jsonUser);
        this.jsonUser.other = this.state.other
        this.jsonUser.isComplete = true
        console.log('this.jsonUser', this.jsonUser);
        this.jsonUser.checkBox1.isChecked = this.state.checkbox1
        this.jsonUser.checkBox2.isChecked = this.state.checkbox2
        this.jsonUser.checkBox3.isChecked = this.state.checkbox3
        this.jsonUser.checkBox4.isChecked = this.state.checkbox4

        this.jsonUser.name = this.state.name
        this.jsonUser.address = this.state.address
        this.jsonUser.birthday = this.state.birthday
        this.jsonUser.email = this.state.email
        this.jsonUser.mobile = this.state.mobile
        global.userData.userData.acd.appointing_substitute_decision_makers.isComplete = true
        global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[0] = this.jsonUser
        AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

        console.log('global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[0]', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[0]);
        console.log('global.userData.userData.acd', global.userData.userData.acd);
        if(this.state.skipSubstitute){

                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].name = '';
                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].address = '';
                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].birthday = '';
                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].email = '';
                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].mobile = '';
                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].isComplete = false;

                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].name = '';
                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].address = '';
                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].birthday = '';
                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].email = '';
                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].mobile = '';
                    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].isComplete = false;

          this.props.navigation.navigate("AppointingSubstitutePreference")
        }else{
          this.props.navigation.navigate("AppointingSubstitute2")
        }
      }else{
        alert('Please fill in details')
      }
    }else{
      console.log(this.jsonUser);

      if(this.state.checkbox4 === true || this.state.checkbox3 === true || this.state.checkbox2 === true ||this.state.checkbox1 === true){
        console.log('in or true');
        this.jsonUser.other = ''
        this.jsonUser.isComplete = true
        this.jsonUser.checkBox1.isChecked = this.state.checkbox1
        this.jsonUser.checkBox2.isChecked = this.state.checkbox2
        this.jsonUser.checkBox3.isChecked = this.state.checkbox3
        this.jsonUser.checkBox4.isChecked = this.state.checkbox4

        this.jsonUser.name = this.state.name
        this.jsonUser.address = this.state.address
        this.jsonUser.birthday = this.state.birthday
        this.jsonUser.email = this.state.email
        this.jsonUser.mobile = this.state.mobile

        global.userData.userData.acd.appointing_substitute_decision_makers.isComplete = true

        global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[0] = this.jsonUser
        console.log('data check', global.userData.userData.acd.appointing_substitute_decision_makers);
        AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )
        if(this.state.skipSubstitute){

          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].name = '';
          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].address = '';
          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].birthday = '';
          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].email = '';
          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].mobile = '';
          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[1].isComplete = false;


          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].name = '';
          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].address = '';
          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].birthday = '';
          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].email = '';
          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].mobile = '';
          global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2].isComplete = false;

          this.props.navigation.navigate("AppointingSubstitutePreference")
        }else{
          this.props.navigation.navigate("AppointingSubstitute2")
        }
      }else{
        console.log('in or false');
        alert('Please Check One')

      //   this.jsonUser.other = this.state.other
      //   this.jsonUser.isComplete = false
      //
      //   global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[0] = this.jsonUser
      //   AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )
      //   // this.props.navigation.navigate("AppointingSubstitute2")
      //   if(this.state.skipSubstitute){
      //     this.props.navigation.navigate("AppointingSubstitutePreference")
      //   }else{
      //     this.props.navigation.navigate("AppointingSubstitute2")
      //   }
      // }

    }
  }

}
}

render() {
  console.log('hhh', this.state.winHeight);
  return (

      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
        <NavigationEvents
          onWillFocus={() => this.onWillFocus()}
          onDidFocus={payload => console.log('did focus', payload)}
          onWillBlur={payload => console.log('will blur', payload)}
          onDidBlur={payload => console.log('did blur', payload)}
          />

        <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          {
            (Platform.OS === 'ios' || Platform.OS ==='android')
            ?
          <KeyboardAwareScrollView style={global.scrollContainer}
                enableOnAndroid
                >
                <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

              {this.state.UserComponent}

              <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{color:"#1D2195", fontSize:14, fontWeight:"bold", marginBottom:25}}> {this.jsonData.instructions[0]} </Text>

                <View style = {styles.inputField}>

                  <TextInput
                    editable
                    style={styles.textInputStyle}
                    onChangeText={name => this.setState({ name })}
                    value={this.state.name}
                    ref={(ip) => this.fullName = ip}
                    />
                  <Text style= {styles.textstyle} onPress={() => {this.fullName.focus()}}>Full Name</Text>
                </View>

                <View style = {styles.inputField}>

                  <TextInput
                    editable
                    style={styles.textInputStyle}
                    keyboardType = "email-address"
                    value={this.state.email}
                    onChangeText={email => this.setState({ email })}
                    ref={(ip) => this.email = ip}
                    />
                  <Text style= {styles.textstyle} onPress={() => {this.email.focus()}}>Email</Text>
                </View>

                <TouchableOpacity style = {styles.inputField}>
                  {Platform.OS !== 'web'
                    ?<DatePicker
                    maxDate = {this.maxDate}
                    placeholder= " "
                    date={this.state.birthday}
                    showIcon={false}
                    style={styles.inputFieldBirthday}
                    customStyles={{
                      dateInput: {
                        position: 'absolute',
                        left: 0,
                        marginLeft: 0,
                        borderWidth:0,
                        borderColor:"transparent",
                      }
                    }}
                    mode="date"
                    format="DD/MM/YYYY"
                    confirmBtnText="Confirm"
                    cancelBtnText="Cancel"
                    onDateChange={(birthday) => {this.setState({birthday: birthday})}}
                    />

                  :<TextInput
                  editable
                  style={styles.textInputStyle}
                  placeholder="DD/MM/YYYY"
                  onChangeText={birthday => this.setState({ birthday })}
                  value={this.state.birthday}
                  ref={(ip) => this.birthday = ip}

                  />}
                  {Platform.OS !== 'web'
                    ?
                    <Text style= {styles.textstyle}>Birthday</Text>

                    :
                    <Text style= {styles.textstyle} onPress={() => {this.birthday.focus()}}>Birthday</Text>
                  }
                </TouchableOpacity>

                <View style = {styles.inputField}>
                  <TextInput
                    editable
                    style={styles.textInputStyle}
                    onChangeText={address => this.setState({ address })}
                    value={this.state.address}
                    ref={(ip) => this.address = ip}

                    />
                  <Text style= {styles.textstyle} onPress={() => {this.address.focus()}}>Address</Text>
                </View>

                <View style = {styles.inputField}>

                  <TextInput
                    editable
                    style={[styles.textInputStyle, {width: "80%"}]}
                    keyboardType = "phone-pad"
                    value={this.state.mobile}
                    onChangeText={mobile => this.setState({ mobile })}
                    ref={(ip) => this.mobile = ip}
                    />
                  <Text style= {[styles.textstyle, {width: "20%"}]} onPress={() => {this.mobile.focus()}}>Mobile Number</Text>
                </View>

                <View style={{paddingTop: 10, display:"flex", flex:1, width:'100%',minHeight:30, flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                  <Text style={{
                      color:"#1D2195", fontWeight:'bold',fontSize:14, width:'90%'}}>
                      {this.jsonData.instructions[1]}
                    </Text>
                  </View>
                  <View style={{paddingTop: 10, width:'90%',minHeight:30, alignItems:'center', justifyContent:'center'}}>

                  <CheckBox
                    title={this.jsonData.instructions[2]}
                    textStyle={styles.CheckBoxTitle}
                    size={38}
                    containerStyle={styles.CheckBoxContainerStyle}
                    checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                    uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                    checked={this.state.checkbox1}
                    onPress={() => this.setState({checkbox1: !this.state.checkbox1, checkbox2: false, checkbox3: false, checkbox4: false})}
                    />

                  <CheckBox
                    title={this.jsonData.instructions[3]}
                    textStyle={styles.CheckBoxTitle}
                    size={38}
                    containerStyle={styles.CheckBoxContainerStyle}
                    checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                    uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                    checked={this.state.checkbox2}
                    onPress={() => this.setState({checkbox2: !this.state.checkbox2, checkbox1: false, checkbox3: false, checkbox4: false})}
                    />

                  <CheckBox
                    title={this.jsonData.instructions[4]}

                    textStyle={styles.CheckBoxTitle}
                    size={38}
                    containerStyle={styles.CheckBoxContainerStyle}
                    checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                    uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                    checked={this.state.checkbox3}
                    onPress={() => this.setState({checkbox3: !this.state.checkbox3, checkbox1: false, checkbox2: false, checkbox4: false})}
                    />

                  <CheckBox
                    title={this.jsonData.instructions[5]}

                    textStyle={styles.CheckBoxTitle}
                    size={38}
                    containerStyle={styles.CheckBoxContainerStyle}
                    checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                    uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                    checked={this.state.checkbox4}
                    onPress={() => this.setState({checkbox4: !this.state.checkbox4, checkbox1: false, checkbox3: false, checkbox2: false})}
                    />
                  </View>


                  <View style={{  minHeight:130 , borderRadius:5,width:'90%',marginTop:20, marginBottom:20 ,borderColor: '#1D2195',borderWidth: 1, paddingTop:5}}>
                    <TextInput
                      multiline
                      onChangeText={(other) => {this.setState({other})}}
                      value={this.state.other}
                      style={{paddingLeft:10, paddingTop:4, paddingRight:10, minHeight:130, textAlignVertical: "top"}}
                      />
                  </View>

                  <CheckBox
                    title={this.jsonData.instructions[6]}

                    textStyle={styles.CheckBoxTitle}
                    size={38}
                    containerStyle={styles.CheckBoxContainerStyle}
                    checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                    uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                    checked={this.state.skipSubstitute}
                    onPress={() => this.setState({skipSubstitute: !this.state.skipSubstitute})}
                    />


                </View>
              </View>
            </KeyboardAwareScrollView>
            :
            <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}

          <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{color:"#1D2195", fontSize:14, fontWeight:"bold", marginBottom:25}}> {this.jsonData.instructions[0]} </Text>

            <View style = {styles.inputField}>

              <TextInput
                editable
                style={styles.textInputStyle}
                onChangeText={name => this.setState({ name })}
                value={this.state.name}
                ref={(ip) => this.fullName = ip}
                />
              <Text style= {styles.textstyle} onPress={() => {this.fullName.focus()}}>Full Name</Text>
            </View>

            <View style = {styles.inputField}>

              <TextInput
                editable
                style={styles.textInputStyle}
                keyboardType = "email-address"
                value={this.state.email}
                onChangeText={email => this.setState({ email })}
                ref={(ip) => this.email = ip}
                />
              <Text style= {styles.textstyle} onPress={() => {this.email.focus()}}>Email</Text>
            </View>

            <TouchableOpacity style = {styles.inputField}>
              {Platform.OS !== 'web'
                ?<DatePicker
                maxDate = {this.maxDate}
                placeholder= " "
                date={this.state.birthday}
                showIcon={false}
                style={styles.inputFieldBirthday}
                customStyles={{
                  dateInput: {
                    position: 'absolute',
                    left: 0,
                    marginLeft: 0,
                    borderWidth:0,
                    borderColor:"transparent",
                  }
                }}
                mode="date"
                format="DD/MM/YYYY"
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                onDateChange={(birthday) => {this.setState({birthday: birthday})}}
                />

              :<TextInput
              editable
              style={styles.textInputStyle}
              placeholder="DD/MM/YYYY"
              onChangeText={birthday => this.setState({ birthday })}
              value={this.state.birthday}
              ref={(ip) => this.birthday = ip}

              />}
              {Platform.OS !== 'web'
                ?
                <Text style= {styles.textstyle}>Birthday</Text>

                :
                <Text style= {styles.textstyle} onPress={() => {this.birthday.focus()}}>Birthday</Text>
              }
            </TouchableOpacity>

            <View style = {styles.inputField}>
              <TextInput
                editable
                style={styles.textInputStyle}
                onChangeText={address => this.setState({ address })}
                value={this.state.address}
                ref={(ip) => this.address = ip}

                />
              <Text style= {styles.textstyle} onPress={() => {this.address.focus()}}>Address</Text>
            </View>

            <View style = {styles.inputField}>

              <TextInput
                editable
                style={[styles.textInputStyle]}
                keyboardType = "phone-pad"
                value={this.state.mobile}
                onChangeText={mobile => this.setState({ mobile })}
                ref={(ip) => this.mobile = ip}
                />
              <Text style= {[styles.textstyle]} onPress={() => {this.mobile.focus()}}>Mobile Number</Text>
            </View>

            <View style={{paddingTop: 10, display:"flex", flex:1, width:'100%',minHeight:30, flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
              <Text style={{
                  color:"#1D2195", fontWeight:'bold',fontSize:14, width:'90%'}}>
                  {this.jsonData.instructions[1]}
                </Text>
              </View>
              <View style={{paddingTop: 10, width:'90%',minHeight:30, alignItems:'center', justifyContent:'center'}}>

              <CheckBox
                title={this.jsonData.instructions[2]}
                textStyle={styles.CheckBoxTitle}
                size={38}
                containerStyle={styles.CheckBoxContainerStyle}
                checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                checked={this.state.checkbox1}
                onPress={() => this.setState({checkbox1: !this.state.checkbox1, checkbox2: false, checkbox3: false, checkbox4: false})}
                />

              <CheckBox
                title={this.jsonData.instructions[3]}
                textStyle={styles.CheckBoxTitle}
                size={38}
                containerStyle={styles.CheckBoxContainerStyle}
                checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                checked={this.state.checkbox2}
                onPress={() => this.setState({checkbox2: !this.state.checkbox2, checkbox1: false, checkbox3: false, checkbox4: false})}
                />

              <CheckBox
                title={this.jsonData.instructions[4]}

                textStyle={styles.CheckBoxTitle}
                size={38}
                containerStyle={styles.CheckBoxContainerStyle}
                checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                checked={this.state.checkbox3}
                onPress={() => this.setState({checkbox3: !this.state.checkbox3, checkbox1: false, checkbox2: false, checkbox4: false})}
                />

              <CheckBox
                title={this.jsonData.instructions[5]}

                textStyle={styles.CheckBoxTitle}
                size={38}
                containerStyle={styles.CheckBoxContainerStyle}
                checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                checked={this.state.checkbox4}
                onPress={() => this.setState({checkbox4: !this.state.checkbox4, checkbox1: false, checkbox3: false, checkbox2: false})}
                />
              </View>


              <View style={{  minHeight:130 , borderRadius:5,width:'90%',marginTop:20, marginBottom:20 ,borderColor: '#1D2195',borderWidth: 1, paddingTop:5}}>
                <TextInput
                  multiline
                  onChangeText={(other) => {this.setState({other})}}
                  value={this.state.other}
                  style={{paddingLeft:10, paddingTop:4, paddingRight:10, minHeight:130, textAlignVertical: "top"}}
                  />
              </View>

              <CheckBox
                title={this.jsonData.instructions[6]}

                textStyle={styles.CheckBoxTitle}
                size={38}
                containerStyle={styles.CheckBoxContainerStyle}
                checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                checked={this.state.skipSubstitute}
                onPress={() => this.setState({skipSubstitute: !this.state.skipSubstitute})}
                />


            </View>
          </View>
          }

          </ScrollView>

          <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
            contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleSubstitutionMaker}/>
              <ACDfooter title="D" marginLeft="75%" />

          </ScrollView>
        </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
    //     paddingTop:50,
  },
  scrollContainer: {
    width:'100%',
    height: '100%'
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    //     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },
  descriptionText:{
    marginBottom:10,
    marginLeft:15,
    textAlign:'left',
    color:'#1D2195',
  },
  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
  textstyle:{
    color: '#1D2195'
  }

})
