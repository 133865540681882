import React, { Component } from 'react';
import {
  Animated,
  Easing,
  StyleSheet,
  Text,
  Image,
  View,
  Dimensions,
  Platform,
  ScrollView,
  AsyncStorage,
  ActivityIndicator,TextInput,
  TouchableOpacity
} from 'react-native';
import SortableList from 'react-native-sortable-list';
import {  Button, CheckBox } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DraggableFlatList from 'react-native-draggable-flatlist'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

const window = Dimensions.get('window');


let data = [
];

export default class SubstituteDecisionMakerPriority extends Component {

  constructor(props) {
    super(props);
    var first = true;
    let sortedUserAcd = global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers
    // sortedUserAcd.sort((a, b) => (a.priority == true) ? -1 : 1)
    console.log('after sort' , sortedUserAcd);
    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    name: 'dani',
    email: '',
    address: '',
    birthday:'1999-05-15',
    mainSubstitute:false,
    skipSubstitute:false,

    disableScroll:true,
    enableSort:false,
    newData:sortedUserAcd.map((d, index) => ({

        key: `item-${index}`,
        name: d.name,
        priority: d.priority,
        address: d.address,
        birthday:d.birthday,
        email:d.email,
        isComplete: d.isComplete
      })),
    jsonUser:sortedUserAcd
  }
  // console.log('this.substituePriority', this.substituePriority);
  // console.log('this.state.newData', this.state.newData);
  // console.log('global.userData.decision_makers', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers);
  // console.log(global.userData.userData.acd);

  this.jsonUser = sortedUserAcd
  // data = this.jsonUser
  this.getLocalKeys()
  this.globalDataSort2()
  // console.log('globals, ', global.priorityJson);
  // data = global.priorityJson
}
globalDataSort = () =>{
  // d.priority? `item-${0}` : index == 0 ? `item-${1}`: `item-${index}`,
  // this.setState({})
    this.globalDataSort2()
}
globalDataSort2 =() =>{
  console.log('toSort 0', this.state.newData);
  let toSort = this.state.newData
  console.log('toSort 1', toSort);
    for(var x=0; x<=5; x++){
      const emptyIndex = toSort.findIndex(empty => (empty.name == ''));
      console.log('toSort emptyIndex', emptyIndex, x);
      if(emptyIndex >= 0){
        toSort.splice(emptyIndex, 1);
      }
    }

  console.log('toSort 2', toSort);


  const tomsIndex = toSort.findIndex(friend => friend.priority == true);
  toSort.push(...toSort.splice(0, tomsIndex));

  console.log('toSort 3', toSort);
  this.setState({newData:toSort})
}
testData = () =>{
  console.log(' in testData');


  let toPushButton = []

  this.state.jsonUser.map((x,index,z) =>{
    const array =

    <TouchableOpacity
      style={styles.contentContainer}
      >
      <View>

        {index == 0
          ?<View style= {{width:30,alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100, borderWidth:1,backgroundColor:"#1D2195"}}>
          <Text style={{fontSize:20, fontWeight:'bold', color:"white"}}>{index+1}</Text>
        </View>
        :<View style= {{width:30,alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100, borderWidth:1, borderColor:'#1D2195',backgroundColor:"white"}}>
        <Text style={{fontSize:20, fontWeight:'bold', color:"#1D2195"}}>{index+1}</Text>
      </View>
    }
    <TextInput
      editable = {false}
      style={styles.textInputStyle}
      value={x.name}
      />

    <Text style= {styles.textstyle}>Full Name</Text>
  </View>

  </TouchableOpacity>
  toPushButton.push(array)
  })
  this.setState({defaultList: toPushButton})
}
renderItem = ({ item, index, move, moveEnd, isActive }) => {
  return (
    <TouchableOpacity
      style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{width:'100%', alignItems: 'center', justifyContent: 'center'}]:[{width:'100%', alignItems: 'center', justifyContent: 'center'}]}
      onLongPress={move}
      onPressOut={moveEnd}
      >
      {
        (item.name !== '' && item.name !== null && item.name !== undefined)
        ?
      <View style = {[styles.inputField, index==0 ?{backgroundColor:"#F9F1FF"} :null]}>

        {index == 0
          ?<View style= {{width:30,alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100, borderWidth:1,backgroundColor:"#1D2195"}}>
          <Text style={{fontSize:20, fontWeight:'bold', color:"white"}}>{index+1}</Text>
        </View>
        :<View style= {{width:30,alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100, borderWidth:1, borderColor:'#1D2195',backgroundColor:"white"}}>
        <Text style={{fontSize:20, fontWeight:'bold', color:"#1D2195"}}>{index+1}</Text>
      </View>
    }

          <Text style= {styles.textstyle2}>{item.name}</Text>
    <Text style= {styles.textstyle}>Full Name</Text>
  </View>
  :
  null
}
</TouchableOpacity>
  )
}

noRenderItem = ({ item, index, move, moveEnd, isActive }) => {

    return (

        <TouchableOpacity
          style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{width:'100%', alignItems: 'center', justifyContent: 'center'}]:[{width:'100%', alignItems: 'center', justifyContent: 'center'}]}

          >
          {
            (item.name !== '' && item.name !== null && item.name !== undefined)
            ?
          <View style = {[styles.inputField, index==0 ?{backgroundColor:"#F9F1FF"} :null]}>

            {index == 0
              ?<View style= {{width:30,alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100, borderWidth:1,backgroundColor:"#1D2195"}}>
              <Text style={{fontSize:20, fontWeight:'bold', color:"white"}}>{index+1}</Text>
            </View>
            :<View style= {{width:30,alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100, borderWidth:1, borderColor:'#1D2195',backgroundColor:"white"}}>
            <Text style={{fontSize:20, fontWeight:'bold', color:"#1D2195"}}>{index+1}</Text>
          </View>
        }
        <TextInput
          editable = {false}
          style={styles.textInputStyle}
          value={item.name}
          />

        <Text style= {styles.textstyle}>Full Name</Text>
      </View>
      :
      <View style = {[styles.inputField, index==0 ?{backgroundColor:"#F9F1FF"} :null]}>

      </View>
        }
    </TouchableOpacity>


  )
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}

componentDidMount () {
  this.setValues();
  this.testData()
  // this.globalDataSort2()
  // this.globalDataSort()

}
setValues =()=>{

  console.log('this.jsonUser', this.state.jsonUser);
  this.state.jsonUser.map(x =>{
    console.log('x ix', x.name);
    // data.push(x.name)
  })
  setTimeout( () => {
    console.log('data', data);

  }, 100);
}

disableScroll=()=>{
  console.log("disabling scroll");
  this.setState({disableScroll:false})
}

enableScroll = () =>{
  this.setState({disableScroll:true})
}

handleNext = () =>{

  console.log('this.state.newData', this.state.newData);
  if(this.state.prioritySubstitute){
    this.state.newData.map((x, id, z)=>{
      if(x.key){
        delete x.key;
      }
      x.priority = false
    })
    this.state.newData[0].priority = true

    console.log('this.state.newData', this.state.newData);
    console.log('global.userData.userData.acd.appointing_substitute_decision_makers', global.userData.userData.acd.appointing_substitute_decision_makers);
    console.log('global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers);

    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers = this.state.newData
    console.log('after this.state.newData', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers);
    console.log('after this.state.newData', global.userData.userData.acd.appointing_substitute_decision_makers);

    global.userData.userData.acd.appointing_substitute_decision_makers.isComplete = true
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

    this.props.navigation.navigate("ConditionsForAppointment")

  }else{
    console.log('global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers);
    global.userData.userData.acd.appointing_substitute_decision_makers.isComplete = true
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

    this.props.navigation.navigate("ConditionsForAppointment")

  }

}

changePriority = () =>{
  this.setState({enableSort:true})
}

render() {
  return (
    <KeyboardAwareScrollView
      enableOnAndroid
      >
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>

      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}


          <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>

            <Text style={styles.descriptionText}>Substitute Decision Makers Priority</Text>
            {this.state.enableSort
              ?<Text style={styles.descriptionText}>Press and Hold to Drag and drop for correct order</Text>
              :null}

              <View style={{display:"flex", flexDirection:"row", overflowX:'hidden', overflowY:'hidden', width:"100%", alignItems: 'center', justifyContent: 'center'}}>
                {console.log('in render', this.state.newData)}
                {
                  (this.state.enableSort)
                  ?
                  <DraggableFlatList
                    style={styles.list}
                    data={this.state.newData}
                    renderItem={this.renderItem}
                    keyExtractor={(item, index) => `draggable-item-${item.key}`}
                    scrollPercent={5}
                    contentContainerStyle={styles.contentContainer1}
                    onMoveEnd={({ data }) => this.setState({newData: data })}
                    />
                  :
                  <DraggableFlatList
                    style={styles.list}
                    data={this.state.newData}
                    renderItem={this.noRenderItem}
                    keyExtractor={(item, index) => `draggable-item-${item.key}`}
                    scrollPercent={5}
                    contentContainerStyle={styles.contentContainer1}
                    />
                }
              </View>

{/*

            <SortableList
              style={styles.list}
              contentContainerStyle={styles.contentContainer}
              data={data}
              renderRow={this._renderRow}
              onActivateRow = {()=>{this.disableScroll()}}
              onReleaseRow = {()=>{this.enableScroll()}}
              sortingEnabled = {this.state.enableSort}
              />
*/}

              <View style={styles.buttonContainer}>

                {!this.state.enableSort?<Button
                  raised
                  title="Change Priority"
                  onPress = {this.changePriority}
                  titleStyle = {{fontSize:14, color:'#f9f1ff', fontWeight:'bold'}}
                  buttonStyle = {{borderRadius:5, backgroundColor:'#9126FB', height:48, width: '100%' ,alignSelf:'center', justifyContent:'center'}}
                  containerStyle= {{width:'100%'}}/>
                :null}

              </View>

              <CheckBox
                title='This is my priority of subtitute Decision-Makers.'
                textStyle={styles.CheckBoxTitle}
                size={38}
                containerStyle={styles.CheckBoxContainerStyle}
                checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                checked={this.state.prioritySubstitute}
                onPress={() => this.setState({prioritySubstitute: !this.state.prioritySubstitute})}
                />

            </View>
          </View>

        </ScrollView>
        <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
          contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleNext}/>
          <ACDfooter  title="2a" marginLeft="20%" />
        </ScrollView>
      </View>
    </KeyboardAwareScrollView>

    );
  }

  _renderRow = ({data, active, index}) => {
    console.log("Active :: ", active, index);
    return <Row data={data} active={active} index={index} />
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',

    ...Platform.select({
      ios: {
        paddingTop: 20,
      },
    }),
  },

  title: {
    fontSize: 20,
    paddingVertical: 20,
    color: '#999999',
  },

  list: {
    flex: 1,
  },

  contentContainer: {
    width: '100%',
    // backgroundColor:"yellow"
  },
  contentContainer1: {
    width: '100%',
    // backgroundColor:"yellow"
  },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 16,
    height: 60,
    flex: 1,
    marginTop: 7,
    marginBottom: 12,
    borderRadius: 4,
    justifyContent:"center",
    alignItems:"center",
    // backgroundColor:"gray",
    width:"90%"
  },

  text: {
    fontSize: 24,
    color: '#222222',
  },

  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:20,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 45,
    width:'60%',
    marginLeft:20,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  textstyle2:{
    color:'black',
    width:'50%',
    fontSize:15,
  },
  descriptionText:{
    fontWeight:'bold',
    marginBottom:10,
    color:'#1D2195',
  },
  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },

  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginBottom:15,
    marginTop:15,
    width:'85%',
    // justifyContent:'center',

  },

});
