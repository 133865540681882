import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions, Image } from 'react-native';
import global from "../../../Styles/global";
import UserComponent from '../UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomMainScreenItem from '../CustomMainScreenItem';
import PurpleCircle from '../PurpleCircle';
import { NavigationEvents } from 'react-navigation';
import CustomButton from '../CustomButton'
import ACDfooter from '../ACDfooter';
import {  CheckBox } from 'react-native-elements'


export default class AppointingSubstituteHomeNorthern extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,
      skipSubstitute:false,
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  // require('../../assets/Profile.png')
  this.jsonUserMain = global.userData.userData.acd.appointing_substitute_decision_makers
  // this.jsonUser = global.userData.userData.acd.advance_care_statement.questions[0]
  console.log(global.userData.userData.acd.appointing_substitute_decision_makers);
  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == "North Appointing substitute decision-Makers – 1") {
      this.jsonData = i
    }
  })
  this.getLocalKeys()
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);


    this.setState({userName:data.name})
    this.setState({userAddress:data.address})
    console.log('====================================');
    console.log("Data::::::", data);
    console.log('====================================');
    this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})

    console.log('====================================');
    console.log("Data:::::: after setstate", data);
    console.log('====================================');

  }
}
componentDidMount(){

}
navigateProfile = () =>{
  // console.log('in UploadImage');
  // this.props.navigation.navigate("UploadImage")
}
onWillFocus = ()=>{
  console.log('in onWillFocus');

}

handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}
handleacd= () =>{
  this.props.navigation.navigate("AcdHome")
}
handleItemPress= ()=>{
  this.props.navigation.navigate('AcdSignUp')
}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}
UploadImage = () =>{
  this.props.navigation.navigate("UploadImage")

}
handleAcdOverview = () =>{
  this.props.navigation.navigate("AdvanceCare1")

}

handleSubstitute = () =>{

  console.log(this.jsonUserMain);
  if(this.state.skipSubstitute === true){
    this.jsonUserMain.decision_makers.map((x,idx,z)=>{
      x.name = ''
      x.address = ''
      x.birthday = ''
      x.email = ''
      x.mobile = ''
      x.isComplete = false
    })
    this.jsonUserMain.decisions_made.checkBox1.isChecked = false
    this.jsonUserMain.decisions_made.checkBox2.isChecked = false
    this.jsonUserMain.decisions_made.checkBox3.isChecked = false

    global.userData.userData.acd.appointing_substitute_decision_makers = this.jsonUserMain

    global.userData.userData.acd.appointing_substitute_decision_makers.isComplete = false
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

    this.props.navigation.navigate("Witnessing")
  }else{
    this.props.navigation.navigate("AppointingSubstitute1")
  }

}

render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
          <NavigationEvents
            onWillFocus={() => this.onWillFocus()}
            onDidFocus={payload => console.log('did focus', payload)}
            onWillBlur={payload => console.log('will blur', payload)}
            onDidBlur={payload => console.log('did blur', payload)}
            />

          <View style = {global.mainInsideContainer}>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
              {this.state.UserComponent}
            </TouchableOpacity>

            <View style={{width:'90%', paddingBottom:10, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>

              <View style={{paddingTop: 10, display:"flex", flex:1, width:'100%',minHeight:30, flexDirection:'row', alignItems:'center', justifyContent:'center'}}>

                <Text style={{
                    color:"#1D2195", fontSize:14, width:'90%'}}>
                    {this.jsonData.description[0]}
                  </Text>
                </View>

                              <Text style={[global.textBold, {marginTop: 10}]}>

                              </Text>

              <View style={{width:"100%",minHeight:50, paddingTop: 10,marginTop:10, alignItems: 'center', backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                <TouchableOpacity style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                  <Text style={[styles.descriptionText, {fontWeight:'400', textAlign:'center'}]}>{this.jsonData.inScreenText[0]}</Text>
                </TouchableOpacity>
              </View>

              <Text style={[global.textBold, {marginTop: 10}]}>

              </Text>
              <CheckBox
                title={this.jsonData.instructions[0]}
                containerStyle={styles.CheckBoxContainerStyle}
                textStyle={styles.CheckBoxTitle}
                size={38}
                checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                checked={this.state.skipSubstitute}
                onPress={() => this.setState({skipSubstitute: !this.state.skipSubstitute})}
                />
            </View>


          </View>


        </View>
      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <CustomButton title = "➔" navigation = {this.props.navigation} onPress= {this.handleSubstitute}/>
        <ACDfooter title="D" marginLeft="75%" />

      </ScrollView>

    </View>
  );
}
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
    //     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    //     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },
  descriptionText:{
   marginBottom:10,
   marginLeft:15,
   textAlign:'left',
   color:'#1D2195',
 },
 CheckBoxTitle:{
   color:"#1D2195",
   fontSize:14,
   fontWeight:"bold",
   width:"90%"
 },
 CheckBoxContainerStyle:{
   backgroundColor:"transparent",
   borderWidth:0,
   width:"100%"
 },

})
