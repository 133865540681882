import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions
} from 'react-native';
import {  Button, CheckBox } from 'react-native-elements'
import UserComponent from '../../../Components/UserComponent'
import UserDetail from '../../../Components/UserDetail'
import CustomButton from '../../../Components/CustomButton'
import ACDfooter from '../../../Components/ACDfooter'
import global from '../../../Styles/global';
import DatePicker from 'react-native-datepicker'

export default class ACDoverview7 extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,

  }
  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'ACD overview - 7') {
      this.jsonData = i
    }
  })

  this.getLocalKeys()
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}

ACDfilledout = () =>{
  console.log("ACDfilledout");
}

handleNext = () => {
  this.props.navigation.navigate("ACDOverview18")
}


componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}

          <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontSize:14, color:"#1D2195", width:"90%", marginBottom:40}}>
              {this.jsonData.description[0]}
            </Text>

            <CustomButton
              title = "ACD filled out"
              backgroundColor = "#B9DD7A"
              border = {true}
              color = "#1D2195"
              navigation= {this.props.navigation}
              onPress = {this.ACDfilledout}
              type="multiples" />


              <CustomButton
                title = "ACD signed by Substitute Decision Maker"
                backgroundColor = "#DD7A7A"
                border = {true}
                color = "#1D2195"
                navigation= {this.props.navigation}
                onPress = {this.ACDfilledout}
                type="multiples" />

                <CustomButton
                  title = "ACD witnessed"
                  backgroundColor = "#DD7A95"
                  border = {true}
                  color = "#1D2195"
                  navigation= {this.props.navigation}
                  onPress = {this.ACDfilledout}
                  type="multiples" />

                <Text style={{fontSize:14, color:"#1D2195", width:"90%", marginTop:25}}>
                    {this.jsonData.description[1]}
                  </Text>

          </View>
        </View>

      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleNext}/>
        <ACDfooter hidePurple = {true}/>
      </ScrollView>
    </View>
  );

}
}

const styles = StyleSheet.create({
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#707070",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  }

})
