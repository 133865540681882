import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, FlatList } from 'react-native';
import global from "../Styles/global";
import UserComponentProvider from '../Components/UserComponentProvider'
import {Button, Icon} from 'react-native-elements'
import CustomButton from '../Components/CustomButton';
import axios from 'axios';

export default class MainScreenProvider extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      loading:true
  }
  global.jsonData = require('../Data/screens_data_new.json')
  console.log('in mainnnnnnnnnnnnnnnnnnnnnnnnnnn');

  this.getLocalKeys()
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus === "pending") {
      console.log('navigating to signedup main screen provider');
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({userName:data.name})
      this.setState({userAddress:data.address})

    }
  }
}

componentDidMount = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log('data in didmount', data);
    if (data.accountStatus !== "pending") {
      this.getAllPatientsForProvider()

    }
  }
}

getAllPatientsForProvider = async () =>{
  const token = await AsyncStorage.getItem('@token');

  let optionsAxios = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(token)},
    url: global.url+'/api/getAllPatientsWithAcds',
  }
  console.log("axios options :: ", optionsAxios);
  axios(optionsAxios)
  .then((response)=> {
    console.log("Response allPatients:: ",response.status ,response.data);
    console.log("Response :: ",response.status ,response.data);

    this.setState({loading:false, allUsers: response.data.result})
      this.setState({allUsersReset: response.data.result})
      // this.formatUsers()

      let updateAcd = ''
      let submittedAcd = ''
      let updateAcdArray = ''
      let submittedAcdArray = ''
      // response.data.result.map(x =>{
      //   let acd = x.patientsWithAcds.acdPdfCreated
      //   submittedAcd = acd[0].createdOn
      //   // acd.map(y, idx , z)=>{
      //   //   if(idx == z.length-1){
      //   //     updateAcd = y.createdOn
      //   //     updateAcdArray.push(updateAcd)
      //   //   }
      //   // }
      //   submittedAcdArray.push(submittedAcd)
      //
      // })
      console.log('updateAcdArray', updateAcdArray);
      console.log('submittedAcdArray', submittedAcdArray);
      this.setState({updateAcd:updateAcdArray,submittedAcd: submittedAcdArray})

      this.setState({isLoading:false})

  })
  .catch((err)=>{
    this.setState({loading:false})
    if(err.response){
      console.log("error getallpatients :: ", err.response);
      alert(err.response.data.message)
    }else{
      console.log("no error")
    }

    // alert(err.response.data.message)
  })
}

formatUsers = () =>{
  console.log('in formatusers');
  const single = []
  const array = []
  this.state.allUsers.map((x, idx, z) =>{

    if(idx <= 10){
      console.log('x ix', idx);
      single =
        <View style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{display:'flex', flexDirection:'row',width:'100%',minHeight:80, marginBottom:20,backgroundColor:'#F9F1FF',borderColor:'#BCE0FD', borderWidth:2, justifyContent:'space-between'}]:
          [{display:'flex', flexDirection:'row',width:'100%',minHeight:80, marginBottom:20,backgroundColor:'#F9F1FF',borderColor:'#BCE0FD', borderWidth:2, justifyContent:'space-between', marginTop:10}]}>
                <View style ={{width:"80%",display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                  <TouchableOpacity style = {{ width:"30%", maxWidth:100, flexDirection:'row'}} >
                        <Avatar
                          size="large"
                          rounded
                          source={{
                            uri: x.profileImg
                          }}
                          containerStyle={{marginLeft: 4,height:65, width:65}}
                          />
                  </TouchableOpacity>
                <View style = {{width:"70%", whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', flexDirection:'column'}}>
                        <Text numberOfLines={1} ellipsizeMode='tail' style={{color:'#1D2195',fontWeight:'bold' ,fontSize:20}}>{x.name}</Text>
                        <Text numberOfLines={4} ellipsizeMode='tail' style={{color:'#1D2195', fontSize:10}} >{x.address}</Text>
                </View>

               </View>
        </View>
        array.push(single)

    }
  })
  this.setState({UserComponent:array})
}

handlelogout = () =>{
  AsyncStorage.clear()
  console.log('navigating to login after logout');
  this.props.navigation.navigate("Login")
}
handleacd= (e, t, f, item) =>{
  console.log('e is ', e, f, item);
  // item.patientsWithAcds[0].userData.isComplete
  let reversedCreated =  f.reverse();

  if(reversedCreated[0]){
    console.log('navigating to handleacdf0');
    this.props.navigation.navigate("AcdHomeProvider" , {patientId:e, territoryId:t, acdCheck:reversedCreated[0].userData.isComplete, allData: item})
  }else{
    console.log('navigating to handleacd else');
    this.props.navigation.navigate("AcdHomeProvider" , {patientId:e, territoryId:t, acdCheck:false, allData: item})
  }
}

searchUsers = async () =>{
  console.log('this.state.search', this.state.search);
  const token = await AsyncStorage.getItem('@token');

  let optionsAxios = {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(token)},
    url: global.url+'/api/searchUserByName',
    data: {query:this.state.search}
  }
  console.log("axios options :: ", optionsAxios);
  axios(optionsAxios)
  .then((response)=> {
    console.log("Response allPatients:: ",response.status ,response.data);
    this.setState({isLoading:false})
    console.log("Response :: ",response.status ,response.data);
    if(response.data.success){
      this.setState({allUsers: response.data.user})
      this.setState({inSearch: true})
    }
      // this.formatUsers()

  })
  .catch((err)=>{
    this.setState({isLoading:false})
    if(err.response){
      console.log("error getallpatients :: ", err.response);
      alert(err.response.data.message)
    }else{
      console.log("no error")
    }

    // alert(err.response.data.message)
  })
}
clearSearch= ()=>{
  this.setState({allUsers: this.state.allUsersReset, inSearch: false, search:''})
}

render() {
  return (
    <View style= {styles.container}>

      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'90%',width:'100%'}]:[{height:'90%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?global.ItemsContainerMobile:[global.ItemsContainerWeb,{minHeight:675}]}>
          <View style = {global.mainInsideContainer}>
            <View style = {styles.inputField}>

              <TextInput
                editable
                style={styles.textInputStyle}
                placeholder="Search"
                onChangeText={search => this.setState({ search })}
                value={this.state.search}
                />

              <Button buttonStyle={{backgroundColor:'white'}} onPress={this.searchUsers} icon={ <Icon name="search" size={25} color="black" /> } />

            </View>
            {
              (this.state.inSearch)
              ?
              <Text style={{paddingBottom:5, paddingRight:15, display:'flex', flexDirection:'row', fontSize:10, alignSelf:'flex-end', color:'#1D2195'}} onPress={this.clearSearch}>
                Clear Search
              </Text>
              :
              null
            }
            {
              (this.state.loading)
              ?
              <ActivityIndicator size="large" color="#9126fb" />
              :
              <TouchableOpacity style={{width:'100%'}} >

                <FlatList
                  data={this.state.allUsers}
                  renderItem={({ item }) =>

                  <UserComponentProvider type='home' name={item.name} createdOn={item.patientsWithAcds[0] ? item.patientsWithAcds[0].acdPdfCreated : null} isComplete={item.patientsWithAcds[0] ? item.patientsWithAcds[0].userData.isComplete : null}
                    onPress={()=>this.handleacd(item._id, item.territory, item.patientsWithAcds, item)}
                    address={item.address} profileImg = {item.profileImg}/>

                }
                  keyExtractor={item => item.id}
                  />

              </TouchableOpacity>
            }


          </View>

        </View>
      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'10%', width:'100%'}]:[{height:'10%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>

          <CustomButton
            title = "LOG OUT"
            fontWeight = "bold" type="multiples"
            onPress={() => this.handlelogout()}
            navigation= {this.props.navigation}
          />
      </ScrollView>
    </View>
  );
}
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
    // paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },

})
