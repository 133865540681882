import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions, Image } from 'react-native';
import global from "../../../Styles/global";
import UserComponent from '../UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomMainScreenItem from '../CustomMainScreenItem';
// import PurpleCircleNorthern from '../PurpleCircleNorthern';
import CustomButton from '../CustomButton';
import { NavigationEvents } from 'react-navigation';
import ACDfooter from '../ACDfooter'
import {  CheckBox } from 'react-native-elements'



export default class AcdOverviewNorthernLast extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,
      UserComponentNorthern:<ActivityIndicator size="small" color="#9126fb" />
    }
    console.log('global.userData', global.userData.userData.acd);
    console.log(global.userData.userData.acd);
    this.jsonUser = global.userData.userData.acd
    this.getLocalKeys()
  }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);


        this.setState({userName:data.name})
        this.setState({userAddress:data.address})

        this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
      }
    }

    onWillFocus = ()=>{
      this.getLocalKeys()
    }

  handlelogout = () =>{
    AsyncStorage.clear()
    this.props.navigation.navigate("Login")
  }
  handleacd= () =>{
    this.props.navigation.navigate("AcdHome")
  }
  handleItemPress= (e)=>{
    if (e == "personalDetail") {
        this.props.navigation.navigate('PersonalDetailNorthern')
    }
    else if (e == "Advance Care Statements") {
        this.props.navigation.navigate('AdvanceCareNoteNorthern')
    }
    else if (e == "Legally binding Advance Consent Decision") {
        this.props.navigation.navigate('AdvanceConsentHomeNorthern')
    }
    else if (e == "Appoint decision maker(s)") {
        this.props.navigation.navigate('AppointingSubstituteHomeNorthern')
    }
    else if (e == "Signing clause") {
        this.props.navigation.navigate('Witnessing')
    }
}

handleAcdOverview = (e) =>{
  console.log("overview clicked ");
  if(this.state.approval){
    this.props.navigation.navigate('ACDoverview2Northern')
  }else{

  }
}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
console.log('eee', e);
this.setState({winHeight: e.window.height})
}

  render() {
    return (

      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
        <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
            <NavigationEvents
              onWillFocus={() => this.onWillFocus()}
              onDidFocus={payload => console.log('did focus', payload)}
              onWillBlur={payload => console.log('will blur', payload)}
              onDidBlur={payload => console.log('did blur', payload)}
              />

          <View style = {global.mainInsideContainer}>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
            {this.state.UserComponent}
            </TouchableOpacity>

            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CheckBox
                title='I have reviewed the information and am ready to view my completed Advance Care Statement.'
                containerStyle={styles.CheckBoxContainerStyle}
                textStyle={styles.CheckBoxTitle}
                size={38}
                checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_pressed.png')} />}
                uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../../assets/Radio_button_notpressed.png')} />}
                checked={this.state.approval}
                onPress={() => this.setState({approval: !this.state.approval})}
                />
            </View>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("personalDetail")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUser.personal_details.isComplete ? 'show' : 'empty'} circleValue='A' color='#1D2195' title = "Personal Details" fontSize={14} />

            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("Advance Care Statements")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUser.advance_care_statement.isComplete ? 'show' : 'empty'} circleValue='B' color='#1D2195' title = "Advance Care Statements" fontSize={14} />
            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("Legally binding Advance Consent Decision")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUser.advanced_consent_decision.isComplete ? 'show' : 'empty'} circleValue='C' color='#1D2195' title = "Legally binding Advance Consent Decision" fontSize={14} />
            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("Appoint decision maker(s)")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUser.appointing_substitute_decision_makers.isComplete ? 'show' : 'empty'} circleValue='D' color='#1D2195' title = "Appoint decision maker(s)" fontSize={14} />
            </View>
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("Signing clause")}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem checkBox = {this.jsonUser.signing_clause.witness.isComplete ? 'show' : 'empty'} circleValue='E' color='#1D2195' title = "Signing clause" fontSize={14} />
            </View>
            </TouchableOpacity>

          </View>
        </View>

          </ScrollView>
          <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
            contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            <CustomButton title = "➔" navigation = {this.props.navigation} onPress= {this.handleAcdOverview}/>
            <ACDfooter  hidePurple = {true}  />

          </ScrollView>

      </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
//     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
//     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },

  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },


})
