import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions,
  KeyboardAvoidingView
} from 'react-native';
import {  Button, CheckBox } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { NavigationEvents } from 'react-navigation';

export default class SubstituteDecisionMaker3 extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
      data:'',
      winHeight:Dimensions.get("window").height,

      name: '',
      email: '',
      address: '',
      birthday:'',

      mainSubstitute:false,
      skipSubstitute:false
    }
    this.maxDate = new Date()

    console.log('global.userData', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers);

    if(global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2]){
      this.jsonUser = global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2]
    }else{
      this.jsonUser = {
        "name": "",
        "priority": "",
        "address":"",
        "birthday":"",
        "email":"",
        "isComplete": false
      }
    }
    this.jsonUserMain = global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers

    this.getLocalKeys()
  }

  onWillFocus =()=>{

    console.log('i am in will focus', this.jsonUserMain);
    setTimeout(function () {
      console.log('i am in will focus', this.jsonUserMain);
      if(global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2]){
        console.log('i am in will focus if', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers);

        this.jsonUser = global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2]
      }else{
        console.log('i am in will focus else', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers);

        this.jsonUser = {
          "name": "",
          "priority": "",
          "address":"",
          "birthday":"",
          "email":"",
          "isComplete": false
        }
      }
      this.jsonUserMain = global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers

    }, 1000);
    setTimeout(function () {
      console.log('i am in will focus, this.jsonUserMain', this.jsonUserMain);

    }, 5000);
    this.render()
  }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);
      if (data.accountStatus == "pending") {
        this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
      }
      else {
        this.setState({data:data})

        this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
      }
    }
  }
  componentDidMount () {
    this.setValues();
  }
  setValues =()=>{

    console.log('this.jsonUser', this.jsonUser);
    this.setState(this.jsonUser)
    setTimeout( () => {
      console.log('this.state', this.state);

    }, 100);
    console.log('this.jsonUser in setvalues', this.jsonUser);
    if(this.jsonUser.priority == true){
      console.log('in this.jsonUser.priority');
      this.setState({mainSubstitute: true})
    }
  }

  handlelogout = () =>{
    AsyncStorage.clear()
    this.props.navigation.navigate("Login")
  }

  handle2Next = (e) =>{
    const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!emailRegex.test(this.state.email )) {
      if (this.state.email == undefined || this.state.email == '') {
        alert("Email is required");
      }
      else{
        alert("Invalid Email")
      }
    }else if(!nameRegex.test(this.state.name )) {
      if(this.state.name == undefined || this.state.name == ''){
        alert("Name is required")
      }
      else{
        alert("Invalid Name")
      }
      // alert("Name is required");
    }else{
      console.log("handle2Next", this.state.skipSubstitute);
      console.log('this.jsonUserMain only', this.jsonUserMain);


      console.log(this.state.name, this.state.address, this.state.birthday, this.state.email);
      let priorities =''
      let prrr = []
      if(this.state.name && this.state.address && this.state.birthday && this.state.email){

        if(this.state.birthday){
          console.log('this.state.birthday', this.state.birthday);

          const broken= this.state.birthday.split('/');
          if(broken[0] && broken[1] && broken[2]){
            console.log('broken', broken);
            if(broken[2].length == 4 && broken[0].length == 2 && broken[1].length == 2 && broken[0] < 32 && broken[1] < 13){

              if(this.state.skipSubstitute){

                if(this.state.mainSubstitute){
                  this.jsonUserMain.map((x, idx, z)=>{
                    console.log('all priorities', x.priority, idx);
                    console.log('this.jsonUserMain.priority', this.jsonUserMain);
                    x.priority = false
                    console.log('this.jsonUserMain.priority', this.jsonUserMain);
                    console.log('all priorities', x.priority, idx);
                  })
                }

                this.jsonUserMain.map((x, idx, z)=>{
                  if(idx !== 0 && idx !== 1){
                    x.name = ''
                    x.address = ''
                    x.birthday = ''
                    x.email = ''
                    x.priority = false
                    x.isComplete = false
                  }
                })

                // this.jsonUserMain[4].name && this.jsonUserMain[3].name = ''
                // this.jsonUserMain[4].address && this.jsonUserMain[3].address= ''
                // this.jsonUserMain[4].birthday && this.jsonUserMain[3].birthday= ''
                // this.jsonUserMain[4].email && this.jsonUserMain[3].email= ''
                // this.jsonUserMain[4].priority && this.jsonUserMain[3].priority= false
                // this.jsonUserMain[4].isComplete && this.jsonUserMain[3].isComplete= false

                global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers = this.jsonUserMain
                console.log('acd, dec', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers);
                console.log('acd, dec', this.jsonUserMain);

                this.jsonUser.name = this.state.name
                this.jsonUser.address = this.state.address
                this.jsonUser.birthday = this.state.birthday
                this.jsonUser.email = this.state.email
                this.jsonUser.priority = this.state.mainSubstitute
                this.jsonUser.isComplete = true

                console.log('this.jsonUser', this.jsonUser);
                global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2] = this.jsonUser
                AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

                console.log('global.userData.userData.acd.appointing_substitute_decision_makers', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers);
                console.log('global.userData.', global.userData);

                this.props.navigation.navigate("SubstituteDecisionMakerPriority")
              }else{

                if(this.state.mainSubstitute){
                  this.jsonUserMain.map((x, idx, z)=>{
                    console.log('all priorities', x.priority, idx);
                    console.log('this.jsonUserMain.priority', this.jsonUserMain);
                    x.priority = false
                    console.log('this.jsonUserMain.priority', this.jsonUserMain);
                    console.log('all priorities', x.priority, idx);
                  })
                }

                this.jsonUser.name = this.state.name
                this.jsonUser.address = this.state.address
                this.jsonUser.birthday = this.state.birthday
                this.jsonUser.email = this.state.email
                this.jsonUser.priority = this.state.mainSubstitute
                this.jsonUser.isComplete = true

                console.log('this.jsonUser', this.jsonUser);
                global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers[2] = this.jsonUser
                AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

                console.log('global.userData.userData.acd.appointing_substitute_decision_makers', global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers);
                console.log('global.userData.', global.userData);

                this.props.navigation.navigate("SubstituteDecisionMaker4")
              }
            }else{
              alert("Birthday should be in format DD/MM/YYYY")
            }
          }else{
            console.log('Birthday should be in format DD/MM/YYYY');
            alert("Birthday should be in format DD/MM/YYYY")
          }
          console.log('broken', broken);
        }else{
          alert("Birthday Is Missing")
        }
      }else{
        console.log('in else');
        alert("Complete All Fields")
      }
    }

  }

  componentWillMount(){
    Dimensions.addEventListener("change", this.handler);
  }

  componentWillUnmount(){
    Dimensions.removeEventListener("change", this.handler);
  }
  handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
  }

  render() {
    return (
      <KeyboardAwareScrollView
        enableOnAndroid
        >
      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
        <NavigationEvents
          onWillFocus={() => this.onWillFocus()}
          onDidFocus={payload => console.log('did focus', payload)}
          onWillBlur={payload => console.log('will blur', payload)}
          onDidBlur={payload => console.log('did blur', payload)}
          />
          <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

            {this.state.UserComponent}

            <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
              <Text style={{color:"#1D2195", fontSize:14, fontWeight:"bold", marginBottom:25}}> Substitute Decision Maker 3 </Text>

                <View style = {styles.inputField}>

                  <TextInput
                    editable
                    style={styles.textInputStyle}
                    onChangeText={name => this.setState({ name })}
                    value={this.state.name}
                    ref={(ip) => this.fullName = ip}
                    />
                  <Text style= {styles.textstyle} onPress={() => {this.fullName.focus()}}>Full Name</Text>
                </View>

                <View style = {styles.inputField}>

                  <TextInput
                    editable
                    style={styles.textInputStyle}
                    keyboardType = "email-address"
                    value={this.state.email}
                    onChangeText={email => this.setState({ email })}
                    ref={(ip) => this.email = ip}
                    />
                  <Text style= {styles.textstyle} onPress={() => {this.email.focus()}}>Email</Text>
                </View>

                <TouchableOpacity style = {styles.inputField}>
                  {Platform.OS !== 'web'
                  ?<DatePicker
                    maxDate = {this.maxDate}
                    placeholder= " "
                    date={this.state.birthday}
                    showIcon={false}
                    style={styles.inputFieldBirthday}
                    customStyles={{
                      dateInput: {
                        position: 'absolute',
                        left: 0,
                        marginLeft: 0,
                        borderWidth:0,
                        borderColor:"transparent",
                      }
                    }}
                    mode="date"
                    format="DD/MM/YYYY"
                    confirmBtnText="Confirm"
                    cancelBtnText="Cancel"
                    onDateChange={(birthday) => {this.setState({birthday: birthday})}}
                    />

                  :<TextInput
                    editable
                    style={styles.textInputStyle}
                    placeholder="DD/MM/YYYY"
                    onChangeText={birthday => this.setState({ birthday })}
                    value={this.state.birthday}
                    ref={(ip) => this.birthday = ip}

                    />}
                      {Platform.OS !== 'web'
                        ?
                        <Text style= {styles.textstyle}>Birthday</Text>

                        :
                        <Text style= {styles.textstyle} onPress={() => {this.birthday.focus()}}>Birthday</Text>
                      }
                  </TouchableOpacity>

                <View style = {styles.inputField}>
                  <TextInput
                    editable
                    style={styles.textInputStyle}
                    onChangeText={address => this.setState({ address })}
                    value={this.state.address}
                    ref={(ip) => this.address = ip}

                    />
                  <Text style= {styles.textstyle} onPress={() => {this.address.focus()}}>Address</Text>
                </View>

                  <CheckBox
                    title='This is my main substitute Decision-Maker'
                    textStyle={styles.CheckBoxTitle}
                    size={38}
                    containerStyle={styles.CheckBoxContainerStyle}
                    checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                    uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                    checked={this.state.mainSubstitute}
                    onPress={() => this.setState({mainSubstitute: !this.state.mainSubstitute})}
                    />

                    <CheckBox
                      title='I do not wish to appoint another Substitute Decision-Makers.'
                      textStyle={styles.CheckBoxTitle}
                      size={38}
                      containerStyle={styles.CheckBoxContainerStyle}
                      checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                      uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                      checked={this.state.skipSubstitute}
                      onPress={() => this.setState({skipSubstitute: !this.state.skipSubstitute})}
                      />


            </View>
            </View>

        </ScrollView>
        <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
          contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handle2Next}/>
              <ACDfooter  title="2a" marginLeft="20%" />
        </ScrollView>
      </View>
    </KeyboardAwareScrollView>
    );

  }
}

const styles = StyleSheet.create({
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#707070",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
  inputFieldBirthday:{
    width:'85%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    marginBottom:8,
    justifyContent:'space-between',
  },

})
