import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions
} from 'react-native';
import {  CheckBox } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import CustomMainScreenItem from '../../Components/CustomMainScreenItem'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import PurpleCircle from '../../Components/PurpleCircle';

export default class InterpreterStatement44 extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,

    name: '',
    email: '',
    address: '',
    birthday:'1999-05-15',

    mainSubstitute:false,
    skipSubstitute:false
  }
  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'What is important to me - A (example) – 44') {
      this.jsonData = i
    }
  })
  this.getLocalKeys()
}





getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}


handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}

handleSubstitutionMaker = (e) =>{
  console.log("handle2Next", this.state.skipSubstitute);
    this.props.navigation.navigate("InterpreterStatement52")
}
handleInterpreterHelp = (e) =>{
  this.props.navigation.navigate("InterpreterStatement43")

}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}
render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'75%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}

          <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
            <TouchableOpacity style={{width:'100%'}}>

              {
                Platform.OS === 'ios' || Platform.OS ==='android'
                ?
                <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <CustomMainScreenItem color='#1D2195' disable='true' title = "Interpreter statement" purpleCircle='enable' onPress = {this.handleInterpreterHelp} fontSize={14} />
                </View>
                :
                <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <CustomMainScreenItem color='#1D2195' disable='true' onPress = {this.handleInterpreterHelp} title = "Interpreter statement" marginLeft="40%" marginRight="3%" purpleCircle='enable' fontSize={14} />
                </View>
              }


            </TouchableOpacity>
            <View style={{width:'100%', marginBottom:7, marginTop:7}}>

            <Text style={styles.descriptionText}>
              {this.jsonData.instructions[0]}
              </Text>
          </View>


            <View style={{width:'100%', alignItems: 'center', paddingBottom:10}}>
              <Text style={{width:"90%", color:'#1D2195', fontSize:13}}>
                {this.jsonData.description[0]}
              </Text>
            </View>

          </View>

          </View>


      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'25%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleSubstitutionMaker}/>
            <ACDfooter  title="5" marginLeft="65%" />

      </ScrollView>
    </View>
  );

}
}

const styles = StyleSheet.create({

  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
   descriptionText:{
    marginBottom:10,
    fontWeight:'bold',
    marginLeft:15,
    marginRight:15,
    textAlign:'left',
    color:'#7F7F7F',
    fontSize:13
  },


})
