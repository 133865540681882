import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions } from 'react-native';
import global from "../../Styles/global";
import UserComponent from '../../Components/UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomMainScreenItem from '../../Components/CustomMainScreenItem';
import PurpleCircle from '../../Components/PurpleCircle';
import CustomButton from '../../Components/CustomButton';
import ACDfooter from '../../Components/ACDfooter'
import { NavigationEvents } from 'react-navigation';



export default class AcdOverview extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
    }
    console.log('global.userData', global.userData.userData.acd);
    console.log(global.userData.userData.acd);
    // this.jsonUser = global.userData.userData.acd
    this.jsonUser = global.userData.userData.acd
    this.getLocalKeys()
  }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);


        this.setState({userName:data.name})
        this.setState({userAddress:data.address})

        this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
      }
    }

    onWillFocus = ()=>{
      this.getLocalKeys()
    }

  handlelogout = () =>{
    AsyncStorage.clear()
    this.props.navigation.navigate("Login")
  }
  handleacd= () =>{
    this.props.navigation.navigate("AcdHome")
  }
  handleItemPress= (e)=>{
    if (e == "personalDetail") {
        this.props.navigation.navigate('PersonalDetail')
    }
    else if (e == "AppointingSubstituteDecisionMaker") {
        this.props.navigation.navigate('AppointingSubstituteDecisionMaker')
    }
    else if (e == "ConditionsForAppointment") {
        this.props.navigation.navigate('ConditionsForAppointment')
    }
    else if (e == "ValuesAndWishesScreen") {
        this.props.navigation.navigate('ValuesAndWishesScreen')
    }
    else if (e == "WhatIsImportantScreen") {
        this.props.navigation.navigate('QuestionG1')
    }
    else if (e == "InterpreterStatement") {
        this.props.navigation.navigate('InterpreterStatement5')
    }
    else if (e == "GivingAdvanceCare") {
        this.props.navigation.navigate('GivingAdvanceCare')
    }

}

handleAcdOverview = (e) =>{
  console.log("overview clicked ");
  this.props.navigation.navigate('PersonalDetail')
}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
console.log('eee', e);
this.setState({winHeight: e.window.height})
}

  render() {
    return (

      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
        <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
            <NavigationEvents
              onWillFocus={() => this.onWillFocus()}
              onDidFocus={payload => console.log('did focus', payload)}
              onWillBlur={payload => console.log('will blur', payload)}
              onDidBlur={payload => console.log('did blur', payload)}
              />

          <View style = {global.mainInsideContainer}>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
            {this.state.UserComponent}
            </TouchableOpacity>

            {
              (this.jsonUser.personal_details.isComplete)
              ?
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("personalDetail")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='1' color='#1D2195' checkBox = 'show' title = "Personal Details" fontSize={14} />
              </View>
              </TouchableOpacity>
              :
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("personalDetail")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='1' color='#1D2195' checkBox = 'empty' title = "Personal Details" fontSize={14} />
              </View>
              </TouchableOpacity>
            }


            {
              (this.jsonUser.appointing_substitute_decision_makers.isComplete)
              ?
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("AppointingSubstituteDecisionMaker")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='2a' color='#1D2195' checkBox = 'show' title = "Appointing Substitute Decision-Makers" fontSize={14}/>
              </View>
              </TouchableOpacity>
              :
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("AppointingSubstituteDecisionMaker")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='2a' color='#1D2195' checkBox = 'empty' title = "Appointing Substitute Decision-Makers" fontSize={14}/>
              </View>
              </TouchableOpacity>
            }


            {
              (this.jsonUser.conditions_for_appointment.isComplete)
              ?
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("ConditionsForAppointment")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='2b' color='#1D2195' checkBox = 'show' title = "Conditions of Appointment" fontSize={14}/>
              </View>
              </TouchableOpacity>
              :
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("ConditionsForAppointment")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='2b' color='#1D2195' checkBox = 'empty' title = "Conditions of Appointment" fontSize={14}/>
              </View>
              </TouchableOpacity>
            }



            {
              (this.jsonUser.values_and_wishes.isComplete)
              ?
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("ValuesAndWishesScreen")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='3' color='#1D2195' checkBox = 'show' title = "What is important to me - My values and wishes" fontSize={14}/>
              </View>
              </TouchableOpacity>
              :
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("ValuesAndWishesScreen")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='3' color='#1D2195' checkBox = 'empty' title = "What is important to me - My values and wishes" fontSize={14}/>
              </View>
              </TouchableOpacity>
            }


            {
              (this.jsonUser.refusals.isComplete)
              ?
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("WhatIsImportantScreen")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='4' color='#1D2195' checkBox = 'show' title = "Binding refusals of health care" fontSize={14}/>
              </View>
              </TouchableOpacity>
              :
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("WhatIsImportantScreen")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='4' color='#1D2195' checkBox = 'empty' title = "Binding refusals of health care" fontSize={14}/>
              </View>
              </TouchableOpacity>
            }


            {
              (this.jsonUser.interpreter_statment.isComplete)
              ?
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("InterpreterStatement")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='5' color='#1D2195' checkBox = 'show' title = "Interpreter statement" fontSize={14}/>
              </View>
              </TouchableOpacity>
              :
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("InterpreterStatement")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='5' color='#1D2195' checkBox = 'empty' title = "Interpreter statement" fontSize={14}/>
              </View>
              </TouchableOpacity>
            }

            {
              (this.jsonUser.witness.isComplete)
              ?
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("GivingAdvanceCare")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='6' color='#1D2195' checkBox = 'show' title = "Giving my Advanced Care Directive" fontSize={14}/>
              </View>
              </TouchableOpacity>
              :
              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress("GivingAdvanceCare")}>
              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem circleValue='6' color='#1D2195' checkBox = 'empty' title = "Giving my Advanced Care Directive" fontSize={14}/>
              </View>
              </TouchableOpacity>
            }


          </View>
        </View>

          </ScrollView>
          <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
            contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            <CustomButton title = "➔" navigation = {this.props.navigation} onPress= {this.handleAcdOverview}/>
                <ACDfooter  hidePurple = {true}  />

          </ScrollView>

      </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
//     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
//     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },

})
