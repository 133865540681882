import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions
} from 'react-native';
import {  CheckBox } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import CustomMainScreenItem from '../../Components/CustomMainScreenItem'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import PurpleCircle from '../../Components/PurpleCircle';

export default class GivingAdvanceCare1 extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,

    name: '',
    email: '',
    address: '',
    birthday:'1999-05-15',

    mainSubstitute:false,
    skipSubstitute:false
  }
  // global.jsonData = require('../../Data/screens_data_new.json')
  console.log('witness',global.userData.userData.acd.witness);
  this.jsonUserMain = global.userData.userData.acd.witness
  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'What is important to me - A (example) – 49') {
      this.jsonData = i

    }
  })
  this.getLocalKeys()
}


getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}


handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}

handleSubstitutionMaker = (e) =>{
  console.log("handle2Next", this.state.skipSubstitute);
  if(this.state.skipSubstitute){
    global.userData.userData.acd.witness.name = ''
    global.userData.userData.acd.witness.phone = ''
    global.userData.userData.acd.witness.category = ''

    global.userData.userData.acd.witness.isComplete = false
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )
    this.props.navigation.navigate("AcdFinalReview")
  }else{
    this.props.navigation.navigate("WitnessScreen")
  }
}
goToInformationStatement = () =>{
  console.log("goToInformationStatement");
  this.props.navigation.navigate("InformationStatement")
}
goToListOfWitnesses = () =>{
  console.log("goToListOfWitnesses");
  this.props.navigation.navigate("FullListOfWitness")
}
goToCertifiedCopies = () =>{
  console.log("goToCertifiedCopies");
  this.props.navigation.navigate("CertifiedCopies")
}


componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}
render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}

          <View style={{width:"100%", alignItems: 'center',marginTop:-10, justifyContent: 'center'}}>
            <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
              <View style={{width:"100%", alignItems: 'center', backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                  <Text style={[styles.descriptionText,{marginTop:10}]}>{this.jsonData.description[0]}
                  </Text>
                </View>
              </View>
            </View>
            <CustomButton border = "true" color="#1D2195" type="multiples" title= "The Advanced Care Directive Information Statement" backgroundColor = '#F9F1FF' onPress={this.goToInformationStatement}/>
            <CustomButton border = "true" color="#1D2195" type="multiples" title= "Full list of witness categories" backgroundColor = '#F9F1FF' onPress={this.goToListOfWitnesses}/>
            <CustomButton border = "true" color="#1D2195" type="multiples" title= "Information on making certified copies" backgroundColor = '#F9F1FF' onPress={this.goToCertifiedCopies} />


          </View>

          <CheckBox
            title='I do not know who my witness is at this time'
            textStyle={styles.CheckBoxTitle}
            size={38}
            containerStyle={styles.CheckBoxContainerStyle}
            checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
            uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
            checked={this.state.skipSubstitute}
            onPress={() => this.setState({skipSubstitute: !this.state.skipSubstitute})}
            />

        </View>
      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleSubstitutionMaker}/>
        <ACDfooter  title="6" marginLeft="80%" />
      </ScrollView>
    </View>
  );

}
}

const styles = StyleSheet.create({

  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
  descriptionText:{
    marginBottom:10,
    // fontWeight:'bold',
    width:'90%',
    marginLeft:10,
    marginRight:10,
    textAlign:'left',
    color:'#1D2195',
  },


})
