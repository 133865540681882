import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ScrollView,
  ActivityIndicator
} from 'react-native';
import {  Button } from 'react-native-elements'
import UserComponent from '../Components/UserComponent'
import global from '../Styles/global';
import PurpleCircle from '../Components/PurpleCircle';
import CustomMainScreenItem from '../Components/CustomMainScreenItem';

export default class MainScreen extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
    }

    this.getLocalKeys()
    global.jsonData = require('../Data/screens_data_new.json')
    console.log('in mmmmmmmmmmmmmmm');
  }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);
      if (data.accountStatus == "pending") {
        this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
      }
      else {
        this.setState({userName:data.name})
        this.setState({userAddress:data.address})

        this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
      }
    }
  }


  handlelogout = () =>{
    AsyncStorage.clear()
    this.props.navigation.navigate("Login")
  }
  handleacd= () =>{
    this.props.navigation.navigate("AcdHome")
  }

  render() {
    return (



      <View style={styles.container}>
          <ScrollView style={{width:'100%'}} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?global.ItemsContainerMobile:[global.ItemsContainerWeb,{minHeight:675}]}>
          <View style = {global.mainInsideContainer}>
            {this.state.UserComponent}
        <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
        <CustomMainScreenItem title = 'Purple Book' fontSize={24} color = '#7F7F7F'/>
        </TouchableOpacity>

          </View>
        </View>
        </ScrollView>
      </View>
    );

  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',

  },

  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },


})
