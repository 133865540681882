import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  TouchableHighlight
} from 'react-native';
import {  Button } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome';
export default class CustomButtonTouchable extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    var bgColor = '#9126FB'
    var color = 'white'
    if (this.props.backgroundColor) {
      bgColor = this.props.backgroundColor
    }

    if (this.props.color) {
      color = this.props.color
    }


    return (
      <View style={this.props.type === 'multiples'? styles.buttonContainerMultiples: styles.buttonContainer}>
        <TouchableOpacity onPress={this.props.onPress}
          style = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '100%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center', borderColor:"#1D2195", borderWidth:2}
            :{borderRadius:5, backgroundColor:bgColor, width: '100%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center'}}
            >
          <Image
            style={styles.button}
            source={this.props.imageSource}
          />
        </TouchableOpacity>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:10,
    marginBottom:60,
    width:'90%',
    // justifyContent:'center',
  },
  button:{
    height:12,
    width:19
  },
  buttonContainerMultiples:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:10,
    marginBottom:2,
    width:'90%',
    // justifyContent:'center',
  },
  loginButton:{
    borderRadius:5,
  },
  arrow:{
    fontSize: 20,
  },

})
