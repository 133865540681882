import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions } from 'react-native';
import global from "../../Styles/global";
// import UserComponent from '../../Components/UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomButton from '../../Components/CustomButton';
import { NavigationEvents } from 'react-navigation';
import UserComponentProvider from '../../Components/UserComponentProvider'


export default class SevenStart2Provider extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,

      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
    }
    // global.userData = require('../../../Data/northern_acd.json')

    this.getLocalKeys()
  }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);


      this.setState({userName:data.name})
      this.setState({userAddress:data.address})
      console.log('====================================');
      console.log("Data::::::", data);
      console.log('====================================');
      // this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
      this.setState({UserComponent:<UserComponentProvider isComplete={this.props.navigation.state.params.allData.patientsWithAcds[0].userData.acd.isComplete} name={this.props.navigation.state.params.allData.name} address={this.props.navigation.state.params.allData.address} profileImg = {this.props.navigation.state.params.allData.profileImg}/>})

      console.log('====================================');
      console.log("Data:::::: after setstate", data);
      console.log('====================================');

    }
  }
  onWillFocus = ()=>{
    this.getLocalKeys()
  }

  routeTo = (e) =>{
    console.log('in sevenstart2');
    if(e === 'new'){
      console.log('to SevenStartProvider');

      this.props.navigation.navigate('SevenStartProvider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})

      // this.props.navigation.navigate("SevenStart")
    }else{
      console.log('to sevenlogprovider');
      this.props.navigation.navigate('SevenLogProvider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})

      // this.props.navigation.navigate("SevenLog")
    }
  }

  handlelogout = () =>{
    AsyncStorage.clear()
    this.props.navigation.navigate("Login")
  }

  handleacd= () =>{
  }

  sevenNext=()=>{
    this.props.navigation.popToTop()

    // this.props.navigation.navigate('AcdHome')
  }

  componentWillMount(){
    Dimensions.addEventListener("change", this.handler);
  }

  componentWillUnmount(){
    Dimensions.removeEventListener("change", this.handler);
  }
  handler =(e) =>{
    console.log('eee', e);
    this.setState({winHeight: e.window.height})
  }

  render() {
    return (

      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'80%',width:'100%'}]:[{height:'80%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
      <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
      <NavigationEvents
      onWillFocus={() => this.onWillFocus()}
      onDidFocus={payload => console.log('did focus', payload)}
      onWillBlur={payload => console.log('will blur', payload)}
      onDidBlur={payload => console.log('did blur', payload)}
      />

      <View style = {global.mainInsideContainer}>
      <TouchableOpacity style={{width:'100%'}}>
      {this.state.UserComponent}
      </TouchableOpacity>

      <View style={{width:'90%', paddingTop:5,paddingBottom:10, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
      <Text style={[global.textBold, {marginTop:10, lineHeight: 25}]}>
      You have previously added a 7 step pathway document. You have two options
      </Text>

      </View>
      <View style={{paddingTop:15,paddingBottom:15, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
      </View>
      <CustomButton
        title = "Add new 7-step pathway"
        color = "white"
        fontWeight = "bold"
        navigation= {this.props.navigation}
        onPress = {() => this.routeTo('new')}
        type="multiples" />
        <View style={{paddingTop:15,paddingBottom:15, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
        </View>
        <CustomButton
          title = "See existing 7 step pathway"
          color = "white"
          fontWeight = "bold"
          navigation= {this.props.navigation}
          onPress = {() => this.routeTo('existing')}
          type="multiples" />
      </View>
      </View>
      </ScrollView>

      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'20%', width:'100%'}]:[{height:'20%', width:'100%', maxWidth:800, minWidth:800}]}
      contentContainerStyle={[global.bottom1, {width:'100%', alignItems:'center', justifyContent:'center'}]}>
      <CustomButton title = "Home" fontWeight = "bold" type="multiples" color='#F9F1FF' navigation = {this.props.navigation} onPress= {this.sevenNext}/>
      </ScrollView>

      </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
    //     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    //     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },
  textSmall:{
    width:'100%',
    alignItems:'flex-start',
    marginTop:50,
    color:'#1D2195',
  },
  textBold:{
    width:'100%',
    alignItems:'flex-start',
    marginTop:50,
    color:'#1D2195',
    fontWeight:'bold',
    lineHeight: 25,

  },
  descriptionText:{
   marginBottom:10,
   // fontWeight:'bold',
   width:'90%',
   marginLeft:10,
   marginRight:10,
   textAlign:'left',
   color:'#1D2195',
   lineHeight: 25,

 },
  bottom: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 36
  }

})
