import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions,
  KeyboardAvoidingView
} from 'react-native';
import {  CheckBox } from 'react-native-elements'
import UserComponent from '../../../Components/UserComponent'
import UserDetail from '../../../Components/UserDetail'
import CustomButton from '../../../Components/CustomButton'
import CustomMainScreenItem from '../../../Components/CustomMainScreenItem'
import ACDfooter from '../../../Components/ACDfooter'
import global from '../../../Styles/global';
import DatePicker from 'react-native-datepicker'
import PurpleCircle from '../../../Components/PurpleCircle';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export default class QuestionG2 extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,

    name: '',
    email: '',
    address: '',
    birthday:'1999-05-15',

    mainSubstitute:false,
    skipSubstitute:false
  }
  console.log('global.userData', global.userData.userData.acd);
  this.jsonUser = global.userData.userData.acd.refusals

  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'What is important to me - A (example) – 33') {
      this.jsonData = i
    }
  })

  this.getLocalKeys()
}





getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}

componentDidMount () {
  this.setValues();
}
setValues =()=>{

  console.log('this.jsonUser', this.jsonUser);
  this.setState(this.jsonUser)
  setTimeout( () => {
    console.log('this.state', this.state);

  }, 100);
}

handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}

handleSubstitutionMaker = (e) =>{
  if(this.state.conditions){
    console.log("handle2Next", this.jsonUser);
    this.jsonUser.conditions = this.state.conditions
    this.jsonUser.isComplete = true
    console.log('this.jsonUser', this.jsonUser);
    global.userData.userData.acd.refusals = this.jsonUser
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

    console.log('global.userData.userData.acd.refusals', global.userData.userData.acd.refusals);
    console.log('global.userData.userData.acd', global.userData.userData.acd);

    this.props.navigation.navigate("InterpreterStatement5")
  }else{
    console.log('in else');
    alert("Complete All Fields")
  }

}

handleDetail = (e) =>{
  this.props.navigation.navigate("QuestionRefusal")
}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}
render() {
  return (
    <KeyboardAwareScrollView
      enableOnAndroid
      >
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
          {this.state.UserComponent}

          <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
            <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
              <TouchableOpacity style={{width:'100%'}}>
                <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <CustomMainScreenItem circleValue='G' onPress={this.handleDetail} color='#1D2195' title = "Refusal of Health care" purpleCircle='enable' fontSize={14} />
                </View>
              </TouchableOpacity>

              <View style={{width:"100%",height:130, backgroundColor:'#F9F1FF',marginTop:30,justifyContent: 'center'}}>
                <View style={{width:'100%'}}>
                  <Text style={styles.descriptionText}>{this.jsonData.instructions[0]}</Text>
                </View>
              </View>
              <View style={{width:'100%'}}>
                <Text style={[styles.descriptionText, {color:'#7F7F7F', marginTop:30}]}>I make the following binding refusal/s of particular health care:</Text>
              </View>

              <View style={{paddingTop:5,  borderRadius:5,width:'90%',minHeight:150,marginTop:20, marginBottom:20 ,borderColor: '#1D2195',borderWidth: 1,}}>
                <TextInput multiline style = {{paddingLeft:10, paddingTop:4, paddingRight:10, minHeight:150, textAlignVertical: "top"}} numberOfLines={20} value={this.state.conditions} onChangeText={conditions => this.setState({conditions})} />
              </View>


            </View>
          </View>
        </View>
      </ScrollView>

      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleSubstitutionMaker}/>
        <ACDfooter  title="4" marginLeft="50%" />
      </ScrollView>

    </View>
  </KeyboardAwareScrollView>


  );

}
}

const styles = StyleSheet.create({

  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
   descriptionText:{
    marginBottom:10,
    fontWeight:'bold',
    marginLeft:15,
    textAlign:'left',
    color:'#1D2195',
  },


})
