import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions
} from 'react-native';
import {  Button, CheckBox } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'

// global.jsonData = require('../../Data/screens_data_new.json')
export default class AppointingSubstituteDecisionMaker extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',

    name: '',
    email: '',
    address: '',
    birthday:'1999-05-15',
    winHeight:Dimensions.get("window").height,

    mainSubstitute:false,
    skipSubstitute:false
  }
  console.log('globallllllllllll', global.userData.userData);
  this.jsonUser = global.userData.userData.acd.appointing_substitute_decision_makers
  this.jsonUserMain = global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers

  console.log('global.jsonData', global.jsonData);
  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'Appointing substitute decision-Makers') {
      this.jsonData = i
    }
  })

  this.getLocalKeys()
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}


handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}

handleSubstitutionMaker = (e) =>{
  console.log("handle2Next", this.state.skipSubstitute);
  if(this.state.skipSubstitute){
    this.jsonUserMain.map(x=>{
      x.name = ''
      x.address = ''
      x.birthday = ''
      x.email = ''
      x.priority = false
      x.isComplete = false
    })

    global.userData.userData.acd.appointing_substitute_decision_makers.decision_makers = this.jsonUserMain

    global.userData.userData.acd.appointing_substitute_decision_makers.isComplete = false
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

    this.props.navigation.navigate("ValuesAndWishesScreen")
  }else{
    this.props.navigation.navigate("SubstituteDecisionMaker1")
  }
}

handleHelpText =() =>{
  console.log('in handleinf');
  this.props.navigation.navigate("HelpText")
}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
console.log('eee', e);
this.setState({winHeight: e.window.height})
}

render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}

          <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
            <Text style={styles.descriptionText}>{this.jsonData.description[0]}</Text>

            <View style={styles.buttonContainer}>

              <Button
                raised
                title="Further information on appointing Substitute Decision-Makers."
                onPress = {this.handleHelpText}
                titleStyle = {{fontSize:14, color:'#f9f1ff'}}
                buttonStyle = {{borderRadius:5, backgroundColor:'#9126FB',  width: '100%' ,alignSelf:'center', justifyContent:'center'}}
                containerStyle= {{width:'100%'}}/>

            </View>
            <View style={{backgroundColor:"#f9f1ff", width:'100%', alignItems: 'center', justifyContent: 'center', paddingBottom:10, paddingTop:10}}>
              <Text style={{textAlign:'center', width:"85%", color:'#1D2195', fontSize:14}}>
                Substitute Decision-Makers need to be over 18 and will be required to sign the final version of this form.
              </Text>
            </View>
            <CheckBox
              title='I do not wish to appoint any Substitute Decision-Makers.'
              textStyle={styles.CheckBoxTitle}
              size={38}
              containerStyle={styles.CheckBoxContainerStyle}
              uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
              checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}

              checked={this.state.skipSubstitute}
              onPress={() => this.setState({skipSubstitute: !this.state.skipSubstitute})}
              />


          </View>
        </View>

      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleSubstitutionMaker}/>
            <ACDfooter  title="2a" marginLeft="20%" />
      </ScrollView>
    </View>
  );

}
}

const styles = StyleSheet.create({
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#707070",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
  descriptionText:{
    marginBottom:10,
    color:'#1D2195',
  },
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginBottom:30,
    width:'85%',
    // justifyContent:'center',

  },

})
