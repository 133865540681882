import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions
} from 'react-native';
import {  CheckBox } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import CustomMainScreenItem from '../../Components/CustomMainScreenItem'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'

export default class WhatIsImportantScreen extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',

    name: '',
    email: '',
    address: '',
    birthday:'1999-05-15',

    winHeight:Dimensions.get("window").height,

    mainSubstitute:false,
    skipSubstitute:false
  }
  console.log('main values_and_wishes', global.userData.userData.acd.values_and_wishes);
  this.jsonUserMain = global.userData.userData.acd.values_and_wishes.questions
  this.getLocalKeys()
}

handler =(e) =>{
console.log('eee', e);
this.setState({winHeight: e.window.height})
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}


handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}

handleSubstitutionMaker = (e) =>{
  console.log("handle2Next", this.state.mainSubstitute);
  if(this.state.mainSubstitute){
    this.jsonUserMain.map(x =>{
      x.answer = ''
    })
    global.userData.userData.acd.values_and_wishes.questions = this.jsonUserMain

    global.userData.userData.acd.values_and_wishes.isComplete = false
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

    this.props.navigation.navigate("QuestionG1")
  }else{
    this.props.navigation.navigate("QuestionA")
  }
}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
console.log('eee', e);
this.setState({winHeight: e.window.height})
}

getToQuestion = (e) =>{

  this.props.navigation.navigate("Question" + e)

}

render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}

          <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
                <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                <Text style={[styles.descriptionText,{fontWeight:'bold',fontSize:14, width:'95%'}]}>In this part you can write down:</Text>

                <TouchableOpacity style={{width:'100%'}} onPress={() => this.getToQuestion('A')}>
                  <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <CustomMainScreenItem circleValue='A' color='#1D2195' bgColor="#1D2195" title = "What is important to you" fontSize={14} />
                  </View>
                </TouchableOpacity>

                <TouchableOpacity style={{width:'100%'}} onPress={() => this.getToQuestion('B')}>
                  <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <CustomMainScreenItem circleValue='B' color='#1D2195' bgColor="#1D2195" title = "Outcome of care that I wish to avoid" fontSize={14} />
                  </View>
                </TouchableOpacity>

                <TouchableOpacity style={{width:'100%'}} onPress={() => this.getToQuestion('C')}>
                  <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <CustomMainScreenItem circleValue='C' color='#1D2195' bgColor="#1D2195" title = "Health Care you prefer" fontSize={14} />
                  </View>
                </TouchableOpacity>

                <TouchableOpacity style={{width:'100%'}} onPress={() => this.getToQuestion('D')}>
                  <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <CustomMainScreenItem circleValue='D' color='#1D2195' bgColor="#1D2195" title = "Where you wish to live" fontSize={14} />
                  </View>
                </TouchableOpacity>

                <TouchableOpacity style={{width:'100%'}} onPress={() => this.getToQuestion('E')}>
                  <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <CustomMainScreenItem circleValue='E' color='#1D2195' bgColor="#1D2195" title = "Other personal arrangement" fontSize={14} />
                  </View>
                </TouchableOpacity>

                <TouchableOpacity style={{width:'100%'}} onPress={() => this.getToQuestion('F')}>
                  <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <CustomMainScreenItem circleValue='F' color='#1D2195' bgColor="#1D2195" title = "Your dying wishes" fontSize={14} />
                  </View>
                </TouchableOpacity>

                <TouchableOpacity style={{width:'100%'}} onPress={() => this.getToQuestion('G')}>
                  <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                  <CustomMainScreenItem circleValue='G' color='#1D2195' bgColor="#1D2195" title = "My refusal of healthcare" fontSize={14} />
                  </View>
                </TouchableOpacity>


                <CheckBox
                    title='I do not want to write down what is Important to me.'
                    textStyle={styles.CheckBoxTitle}
                    size={38}
                    containerStyle={styles.CheckBoxContainerStyle}
                    checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                    uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                    checked={this.state.mainSubstitute}
                    onPress={() => this.setState({mainSubstitute: !this.state.mainSubstitute})}
                    />

           </View>
          </View>
        </View>
      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleSubstitutionMaker}/>
          <ACDfooter  title="3" marginLeft="40%" />

      </ScrollView>

    </View>
  );

}
}

const styles = StyleSheet.create({

  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
  descriptionText:{
    marginBottom:10,
    textAlign:'left',
    color:'#707070',
  },


})
