import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import {  Button } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome';
export default class CustomButtonContacts extends Component {
  constructor(props) {
    super(props);
  }

  handleBackButton=()=>{
      if (this.props.comingFrom == "SignedUpScreen") {
        AsyncStorage.clear()
        this.props.navigation.navigate("Login")
      }else{
        console.log('in go back');
        this.props.navigation.goBack()
      }
  }

  handleHomeButton=()=>{
    this.props.navigation.popToTop()
  }

  render() {
    var bgColor = '#9126FB'
    var color = 'white'
    var fontWeight = 'normal'
    if (this.props.backgroundColor) {
      bgColor = this.props.backgroundColor
    }

    if (this.props.color) {
      color = this.props.color
    }
    if (this.props.fontWeight) {
      fontWeight = this.props.fontWeight
    }



    return (
      <View style={(this.props.type === 'multiples')? styles.buttonContainerMultiples : (this.props.type === 'inScreen')? styles.buttonContainerinScreen: (this.props.type === 'createAccount')? styles.buttonContainerCreateAccount: (this.props.type === 'single') ? styles.buttonContainerSingle : styles.buttonContainer}>
        {
          this.props.type === 'single'
          ?
          <View style={{width:'100%', flexDirection:"row", display:"flex", justifyContent:'space-between'}}>

            <TouchableOpacity onPress={this.props.onPressSave}
              style = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '30%', height:40 ,alignSelf:'center', justifyContent:'center', alignItems:'center', borderColor:"#1D2195", borderWidth:2}
                :{borderRadius:5, backgroundColor:bgColor, width: '30%', height:40 ,alignSelf:'center', justifyContent:'center', alignItems:'center'}}
                >
                <Text style={{color:'white'}}>
                  {this.props.title}
                </Text>
            </TouchableOpacity>
          </View>
          :
          <View style={{width:'100%', flexDirection:"row", display:"flex", justifyContent:'space-between'}}>

            <TouchableOpacity onPress={this.props.onPressSave}
              style = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '40%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center', borderColor:"#1D2195", borderWidth:2}
                :{borderRadius:5, backgroundColor:bgColor, width: '40%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center'}}
                >
                <Text style={{color:'white'}}>
                  Save
                </Text>
            </TouchableOpacity>

              <TouchableOpacity onPress={this.props.onPressDelete}
                style = {this.props.border?{borderRadius:5, backgroundColor:bgColor, width: '40%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center', borderColor:"#1D2195", borderWidth:2}
                  :{borderRadius:5, backgroundColor:'#FF2E56', width: '40%', height:48 ,alignSelf:'center', justifyContent:'center', alignItems:'center'}}
                  >
                  <Text style={{color:'white'}}>
                    Delete
                  </Text>
              </TouchableOpacity>
          </View>
        }


      </View>
    );
  }
}
const styles = StyleSheet.create({
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:10,
    marginBottom:60,
    width:'90%',
    display:'flex', justifyContent:'space-between',

    // justifyContent:'center',
  },
  buttonContainerMultiples:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:10,
    marginBottom:2,
    width:'90%',
    // justifyContent:'center',
  },
  buttonContainerSingle:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:10,
    marginBottom:10,
    width:'90%',
  },
  buttonContainerinScreen:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:4,
    marginBottom:4,
    width:'90%',
    // justifyContent:'center',
  },
  buttonContainerCreateAccount:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:25,
    marginBottom:25,
    width:'90%',
    // justifyContent:'center',
  },
  loginButton:{
    borderRadius:5,
  },
  arrow:{
    fontSize: 20,
  },
  button:{
    height:12,
    width:19
  },
  buttonHome:{
    height:40,
    width:45
  }

})
