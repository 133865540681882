import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TextInput, ImageBackground, ScrollView , ActivityIndicator, Linking, Alert} from 'react-native';
import FooterText from '../../Components/FooterText'
import CustomButton from '../../Components/CustomButton';
import UserComponent from '../../Components/UserComponent'
import { AsyncStorage } from 'react-native';
import global from "../../Styles/global.js"
import axios from 'axios'
import EventBus from 'eventing-bus'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export default class SignedUpScreen extends Component {
  constructor(){
    super()
    this.state={
      providerText :'You have now applied for a PALCARE account. When your account has been approved you will be able to login.',
      patientText : 'You have now signed up for PALCARE You will receive an email to confirm your email and sign-up.',
      verificationPending: 'Email verification is pending, Confirm your email then you will be able to login.',
      code:'',
      isLoading:false
    }
    console.log('in sssssssssssssss');

  }

  submitCode = () => {
    if (this.state.code == '' || this.state.code == undefined) {
      alert("Verification Code required")
    }
    else {
      this.setState({isLoading:true})
      let optionsAxios = ''
      if(Platform.OS == 'web'){
        optionsAxios = {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          url: global.url+'/verifyUserPost/'+this.props.navigation.state.params.data._id,
          data: {code: this.state.code}
        }
      }else{
        optionsAxios = {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
          url: global.url+'/verifyUser/'+this.props.navigation.state.params.data._id+'/'+this.state.code,
        }
      }
      console.log("axios options :: ", optionsAxios);
      axios(optionsAxios)
      .then((response)=> {
        console.log("Response :: ",response.status ,response.data);

        this.setState({isLoading:false})
        console.log("Response :: ",response.status ,response.data);
        if (response.status == 200) {
          this.verifyUsercc(response.data.code)
        }
      })
      .catch((err)=>{
        this.setState({isLoading:false})
        if(err.response){
          console.log("error verifyUser :: ", err.response);
          alert(err.response.data.message)
        }else{
          console.log("no error")
        }

        // alert(err.response.data.message)
      })
    }

    // AsyncStorage.clear()
    // this.props.navigation.navigate("Login")
    // if (this.state.code == '' || this.state.code == undefined || this.state.code == null) {
    //   console.log("do nothing");
    // }
    // else {
    //   this.props.navigation.navigate('MainScreen')
    // }
  }

  verifyUsercc = (e) =>{
    console.log("verification Code :: ", e);
    var options = {
      "code": e,
    }
    var optionsAxios = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      url: global.url+'/getOneTimeToken',
      data:  options
    }
    console.log("axios options :: ", optionsAxios);
    axios(optionsAxios)
    .then((response)=> {
      console.log('response on verify', response);
      if (response.status == 200) {
        console.log('we are in 200 onetimetoken', response);

        if (response.data.user.accountStatus == "pending") {
          if (Platform.OS == 'web') {
            Linking.openURL(global.urlHref);
          }
          console.log('navigating to SignedUp signedup screen');
          this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:response.data.user});
        }else {
          console.log('we are navigating onetimetoken', response);


          if (Platform.OS == 'web') {
            Linking.openURL(global.urlHref);
          }
          global.jsonData = require('../../Data/screens_data_new.json')
          AsyncStorage.setItem("@AppData", JSON.stringify(global.jsonData) )

          if(response.data.user.userType == "patient"){

            if(response.data.user.territory.name === "Northern Territory"){
              console.log('ss');
              AsyncStorage.setItem("@UserData", JSON.stringify(response.data.user))
              AsyncStorage.setItem("@token", JSON.stringify(response.data.token))

              global.userData = require('../../Data/northern_acd.json')
              AsyncStorage.setItem("@SyncedUserAcd", JSON.stringify(global.userData) )
              console.log('navigating to AcdHomeNorthern');
              this.props.navigation.navigate('AcdHomeNorthern', {comingFrom:'login', data:response.data.user})

            }else{
              AsyncStorage.setItem("@UserData", JSON.stringify(response.data.user))
              AsyncStorage.setItem("@token", JSON.stringify(response.data.token))

              global.userData = require('../../Data/user_data.json')
              AsyncStorage.setItem("@SyncedUserAcd", JSON.stringify(global.userData) )
              console.log('navigating to AcdHome');
              this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:response.data.user})
            }
            // this.props.navigation.navigate('AcdHome',{comingFrom:"login", data:response.data.user});

          }else{
            // AsyncStorage.setItem("@UserData", JSON.stringify(response.data.user))
            // AsyncStorage.setItem("@token", JSON.stringify(response.data.token))

            if(response.data.user.approvalStatus === "pending"){
              console.log('in approval pending');
              if (Platform.OS == 'web') {
                AsyncStorage.clear()
                this.props.navigation.navigate("Login")
                alert("Your Sign up request has been sent to admin. You will get an email soon then you can login.")

              }else{
                AsyncStorage.clear()
                this.props.navigation.navigate("Login")
                Alert.alert(
                  'Your Sign up request has been sent to admin.',
                  'You will get an email soon then you can login',
                  [
                    { text: 'Ok', onPress: () => console.log('Cancel Pressed') },
                  ],
                  { cancelable: true }
                );
              }

            }
            // console.log('navigating to mainscreenprovidee', response.data);
            // this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:response.data.user})
          }
        }
      }
    })
    .catch((err)=>{
      if (Platform.OS == 'web') {
        Linking.openURL(global.urlHref);
      }
      this.setState({isLoading:false})
      console.log("error getOneTimeToken :: ", err);
      alert(err.response.data.message)
    })

  }

  render() {
    console.log("props :: ", this.props);
    // var comingFrom = "login"
    // var role = "Provider"
    // var role = "Citizen/patient"

    return (

        <View style={{height:'100%',width:'100%'}} >
          <KeyboardAwareScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'70%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}
            enableOnAndroid
            >

            <UserComponent name={this.props.navigation.state.params.data.name} address={this.props.navigation.state.params.data.address} />

            {(this.props.navigation.state.params.comingFrom === "login")
              ?
              <View style = {Platform.OS == 'web'?styles.congratulationsweb: styles.congratulations}>
                <ImageBackground source={require('../../../assets/CheckboxPurple.png')} style={{width: 100, height: 70, marginTop:80,marginBottom:10}}/>
                <Text style= {{width:'80%',lineHeight: 30, textAlign: 'center',paddingTop:20, fontSize:14, color:'#1D2195'}} > </Text>
                <Text style= {{width:'80%', lineHeight: 30, textAlign: 'center', fontSize:14,marginBottom:10, color:'#1D2195'}} >
                  {this.state.verificationPending}
                </Text>

                  <Text style = {Platform.OS == 'web'?{width:'90%',fontWeight:"bold" ,lineHeight:0, textAlign: 'left', fontSize:14,marginTop:40, color:'#1D2195'}: {width:'90%',fontWeight:"bold" ,lineHeight:0, textAlign: 'left', fontSize:14,marginTop:30, color:'#1D2195'}}
                    >Enter Verification Code: </Text>

                  <View style = {[styles.inputField,{marginBottom:20}]}>
                    <TextInput
                      editable
                      keyboardType = "phone-pad"
                      style={styles.textInputStyle}
                      onChangeText={code => this.setState({ code })}
                      value={this.state.role}
                      />
                    <Text style= {styles.textstyle}>Code</Text>
                  </View>

              </View>
              :(this.props.navigation.state.params.role === "Provider")
                ?<View style = {Platform.OS == 'web'?styles.congratulationsweb: styles.congratulations}>
                  <ImageBackground source={require('../../../assets/CheckboxPurple.png')} style={{width: 100, height: 70,  marginTop:80,marginBottom:10}}/>
                  <Text style= {{width:'80%',lineHeight: 30, textAlign: 'center',paddingTop:20, fontSize:14, color:'#1D2195'}} >Congratulations </Text>
                  <Text style= {{width:'80%', lineHeight: 30, textAlign: 'center', fontSize:14,marginBottom:10, color:'#1D2195'}} >{this.state.providerText}</Text>

                    <Text style= {{width:'90%',fontWeight:"bold" ,lineHeight:0, textAlign: 'left', fontSize:14,marginTop:30, color:'#1D2195'}}>Enter Verification Code: </Text>
                    <View style = {[styles.inputField,{marginBottom:20}]}>
                      <TextInput
                        editable
                        style={styles.textInputStyle}
                        onChangeText={code => this.setState({ code })}
                        value={this.state.role}
                        />
                      <Text style= {styles.textstyle}>Code</Text>
                    </View>

                </View>
                :<View style = {Platform.OS == 'web'?styles.congratulationsweb: styles.congratulations}>
                  <ImageBackground source={require('../../../assets/CheckboxPurple.png')} style={{width: 100, height: 70, marginTop:80,marginBottom:10}}/>
                  <Text style= {{width:'80%',lineHeight: 30, textAlign: 'center',paddingTop:20, fontSize:14, color:'#1D2195'}} >Congratulations </Text>
                  <Text style= {{width:'80%', lineHeight: 30, textAlign: 'center', fontSize:14,marginBottom:10, color:'#1D2195'}} >{this.state.patientText}</Text>

                    <Text style= {{width:'90%',fontWeight:"bold" ,lineHeight:0, textAlign: 'left', fontSize:14,marginTop:30, color:'#1D2195'}}>Enter Verification Code: </Text>
                    <View style = {[styles.inputField,{marginBottom:20}]}>
                      <TextInput
                        editable
                        style={styles.textInputStyle}
                        onChangeText={code => this.setState({ code })}
                        value={this.state.role}
                        />
                      <Text style= {styles.textstyle}>Code</Text>
                    </View>

                </View>
            }

          </KeyboardAwareScrollView>
          <ScrollView style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'30%', width:'100%', backgroundColor: "yellow"}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}], styles.container} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            {this.state.isLoading == true
              ?<View style={{marginTop:50,
                marginBottom:10,
                width:"100%",
                height:48,
                display:"flex",
                justifyContent:"center",
                alignItems:"center"}}>
                <ActivityIndicator size="large" color="#9126FB" />
              </View>
              :<CustomButton title = "➔" type="createAccount" navigation= {this.props.navigation} onPress = {() => this.submitCode()}/>
          }
          </ScrollView>
      </View>

    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    // alignItems: 'center',
    // justifyContent: 'center',
    // backgroundColor:'#501D95',
  },

  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },

  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  congratulationsweb:{
    width:'75%',
    height:370,
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
  congratulations:{
    width:'75%',
    height:'80%',
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
})
