import React, { Component } from 'react';
import { View,Text,StyleSheet, Image, ImageBackground} from 'react-native';

export default class CustomDropDown extends Component {
        render() {
                return (
                        <View style = {{ display:'flex', flexDirection:'column',width:'100%', alignItems:'center', justifyContent:'center',marginTop:this.props.marginTop}}>
                                <View style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                                <View style={{width:"100%", alignItems: 'center',height:60, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                                <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                                <Text style={styles.descriptionText}>Registered professionals such as teachers, nurses, doctors or pharmacists</Text>
                                </View>
                                </View>
                                </View>



                                <View style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                                <View style={{width:"100%", alignItems: 'center',height:60, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                                <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                                <Text style={styles.descriptionText}>Lawyers or Justices of the Peace (JP)</Text>
                                </View>
                                </View>
                                </View>


                                <View style={{width:'100%',marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                                <View style={{width:"100%", alignItems: 'center',height:80, backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                                <View style={{width:'90%', alignItems:'center', justifyContent:'center'}}>
                                <Text style={styles.descriptionText}>Local, State or Commonwealth Government employees with more than five (5) years continuous service</Text>
                                </View>
                                </View>
                                </View>
                        </View>
                        
                );
        }
}



const styles = StyleSheet.create({

        loginButton:{
          borderRadius:5,
      
        },
        arrow:{
          fontSize: 20,
        },
        inputField:{
          width:'100%',
          flexDirection:'row',
          height:50,
          alignItems:'center',
          paddingLeft:10,
          paddingRight:10,
          marginBottom:8,
          justifyContent:'space-between',
          borderWidth:.5,
          borderColor:'#1D2195'
        },
        textInputStyle:{
          height: 40,
          width:'78%',
          alignSelf:'flex-end',
          // outline:'none',
          borderColor:'transparent',
        },
        textstyle:{
          alignItems:'center',
          justifyContent:'center',
          color:'#1D2195',
          fontSize:12,
          textAlign:'center'
        },
        CheckBoxTitle:{
          color:"#1D2195",
          fontSize:14,
          fontWeight:"bold",
          width:"90%"
        },
        CheckBoxContainerStyle:{
          backgroundColor:"transparent",
          borderWidth:0,
          width:"100%"
        },
         descriptionText:{
          marginBottom:10,
          // fontWeight:'bold',
          width:'90%',
          marginLeft:10,
          marginRight:10,
          textAlign:'left',
          color:'#1D2195',
        },
      
      
      })
      