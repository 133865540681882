import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions,
} from 'react-native';
import {  CheckBox, Button } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import CustomMainScreenItem from '../../Components/CustomMainScreenItem'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import PurpleCircle from '../../Components/PurpleCircle';

export default class InterpreterStatement5 extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,

    name: '',
    email: '',
    address: '',
    birthday:'1999-05-15',

    mainSubstitute:false,
    skipSubstitute:false
  }
  console.log('interpreter', global.userData.userData.acd.interpreter_statment);
  this.jsonUserMain = global.userData.userData.acd.interpreter_statment
  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'What is important to me - A (example) – 42') {
      this.jsonData = i
    }
  })
  this.getLocalKeys()
}





getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}


handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}

handleSubstitutionMaker = (e) =>{
  console.log("handle2Next", this.state.skipSubstitute);
  if(this.state.skipSubstitute){
    global.userData.userData.acd.interpreter_statment.fullName = ''
    global.userData.userData.acd.interpreter_statment.address = ''
    global.userData.userData.acd.interpreter_statment.phone = ''

    global.userData.userData.acd.interpreter_statment.isComplete = false
    AsyncStorage.setItem("@unSyncedUserData", JSON.stringify(global.userData) )

    this.props.navigation.navigate("GivingAdvanceCare")
  }else{
    this.props.navigation.navigate("InterpreterStatement52")
  }
}
handleInterpreterHelp =() =>{
  console.log("InterpreterStatement43");
  this.props.navigation.navigate("InterpreterStatement43")

}
handleInterpreterRead =() =>{
  console.log("InterpreterStatement44");
  this.props.navigation.navigate("InterpreterStatement44")

}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}
render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'65%',width:'100%'}]:[{height:'60%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}

          <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
                <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                  <TouchableOpacity style={{width:'100%'}} >
                    {
                      Platform.OS === 'ios' || Platform.OS ==='android'
                      ?
                      <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                        <CustomMainScreenItem color='#1D2195' disable='true' title = "Interpreter statement" purpleCircle='enable' onPress = {this.handleInterpreterHelp} fontSize={14} />
                      </View>
                      :
                      <View style={{width:'100%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                        <CustomMainScreenItem color='#1D2195' disable='true' onPress = {this.handleInterpreterHelp} title = "Interpreter statement" marginLeft="40%" marginRight="3%" purpleCircle='enable' fontSize={14} />
                      </View>
                    }
                  </TouchableOpacity>

                <View style={{width:"105%",height:120, alignItems: 'center', backgroundColor:'#F9F1FF',marginTop:30,justifyContent: 'center'}}>
                <View style={{width:'100%', alignItems:'center', justifyContent:'center'}}>
                <Text style={styles.descriptionText}>{this.jsonData.instructions[0]}</Text>
                </View>
                </View>

                { /*
                  <View style={{width:"100%",height:50,marginTop:30, alignItems: 'center', backgroundColor:'#F9F1FF',justifyContent: 'center'}}>
                  <TouchableOpacity style={{width:'100%', alignItems:'center', justifyContent:'center'}} onPress = {this.handleInterpreterRead}>
                  <Text style={[styles.descriptionText, {fontWeight:'400', textAlign:'center'}]}>Your interpreter should read the Information for Interpreters.</Text>
                  </TouchableOpacity>
                  </View>
                  */}

                <View style={styles.buttonContainer}>

                  <Button
                    raised
                    title="Your interpreter should read the Information for Interpreters."
                    onPress = {this.handleInterpreterRead}
                    titleStyle = {{fontSize:14, color:'#f9f1ff'}}
                    buttonStyle = {{borderRadius:5, backgroundColor:'#9126FB',  width: '100%' ,alignSelf:'center', justifyContent:'center'}}
                    containerStyle= {{width:'100%'}}/>

                </View>

           </View>

          </View>

          </View>


      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'35%', width:'100%'}]:[{height:'40%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>

        <CheckBox
                    title='I do not need an interpreter'
                    textStyle={[styles.CheckBoxTitle,{marginRight:5}]}
                    size={38}
                    containerStyle={[styles.CheckBoxContainerStyle,{marginLeft:50}]}
                    checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                    uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                    checked={this.state.skipSubstitute}
                    onPress={() => this.setState({skipSubstitute: !this.state.skipSubstitute})}
                    />
        </View>
        <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleSubstitutionMaker}/>
            <ACDfooter  title="5" marginLeft="65%" />

      </ScrollView>
    </View>
  );

}
}

const styles = StyleSheet.create({

  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#1D2195",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
   descriptionText:{
    marginBottom:10,
    fontWeight:'bold',
    marginLeft:15,
    marginRight:15,
    textAlign:'left',
    color:'#1D2195',
  },
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:40,
    marginBottom:10,
    width:'100%',
    // justifyContent:'center',
  },


})
