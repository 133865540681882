import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Linking,
  Dimensions
} from 'react-native';
import {  Button, CheckBox } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import * as FileSystem from "expo-file-system";
import * as Print from 'expo-print';
import * as WebBrowser from 'expo-web-browser';
import CustomButtonTouchable from '../../Components/CustomButtonTouchable'
import axios from 'axios';

export default class InformationStatement extends Component {
  constructor(props) {
    super(props);
    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,
    name: '',
    email: '',
    address: '',
    birthday:'1999-05-15',
    mainSubstitute:false,
    skipSubstitute:false
  }
  // global.jsonData = require('../../Data/screens_data_new.json')

  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'Information Statement') {
      this.jsonData = i
    }
  })
  this.getLocalKeys()
}
getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.getPrintable()
      this.setState({data:data})
      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}

handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}
goBack = (e) =>{
  console.log("goBack");
  this.props.navigation.goBack()
}
handleHelpText =() =>{
  console.log('in handleinf');
}
handleDownload=()=>{
  console.log("downloading ", FileSystem.documentDirectory);
  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync('http://apps.sofit.ltd/palcare/Giving_my_Advanced_Care_Directive_wpjfud%20%281%29.pdf')
    // Linking.openURL('http://apps.sofit.ltd/palcare/Giving_my_Advanced_Care_Directive_wpjfud%20%281%29.pdf')
  }
  else {
    WebBrowser.openBrowserAsync('http://apps.sofit.ltd/palcare/Giving_my_Advanced_Care_Directive_wpjfud%20%281%29.pdf')
    //   FileSystem.downloadAsync(
    //   'http://apps.sofit.ltd/palcare/Giving_my_Advanced_Care_Directive_wpjfud%20%281%29.pdf',
    //   FileSystem.documentDirectory + "InformationStatement.pdf"
    // )
    //   .then(({ uri }) => {
    //     console.log('Finished downloading to ', uri);
    //   })
    //   .catch(error => {
    //     console.error("error occured: ",error);
    //   });
  }
}

getPrintable = async (e) =>{

  console.log('getPrintable');
  if(Platform.OS === 'ios' || Platform.OS ==='android'){
    this.token = await AsyncStorage.getItem('@token');
    this.setState({downloading: true})
    let optionsAxios = ''

    optionsAxios = {
      method: 'POST',
      headers: {'Content-Type': 'application/json' , 'Authorization': JSON.parse(this.token)},
      url: global.url+'/api/getPdfBase64/',
      data:{url: 'http://apps.sofit.ltd/palcare/Giving_my_Advanced_Care_Directive_wpjfud%20%281%29.pdf'}
    }

    console.log(" getPdfBase64 Options axios ", optionsAxios );
    axios(optionsAxios)
    .then((response)=> {
      this.setState({downloading:false})

      console.log("/getPdfBase64 Acd Response recieved ", response);
      if(response.data.success){
        this.base64String = response.data.pdfBase64
        this.setState({base64String:true})
        this.setState({downloading:false})

      }else{
        console.log(response.data.message);
      }
    })
    .catch((error)=>{
      this.setState({downloading:false})
      console.log("error", error);
    })
  }else{
    this.setState({downloading:false})
  }
}

handlePrint =async (e) => {
  console.log("print");
  if(Platform.OS === 'ios' || Platform.OS ==='android'){
    // this.donwloadFile()
    if(this.state.base64String){
      let uri = 'data:application/pdf;base64,' + this.base64String

      if(Platform.OS === 'ios' || Platform.OS ==='android'){
        console.log("print mobile inactive");
        const html = '<html><body><p>test</p></body></html>';
        await Print.printAsync({ uri, width: 595, height: 842 });

      }else{
        console.log("print webactive");
        const html = '<html><body><p>test</p></body></html>';

        await Print.printAsync({ uri, width: 595, height: 842 });
      }
    }else{
      alert('No Pdf To Print')
    }
  }else{
    this.handleDownload()
  }
}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}
componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}
render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
          {this.state.UserComponent}
          <View style={{width:"95%", alignItems: 'center', justifyContent: 'center'}}>
            <View style={{display:"flex", justifyContent:"space-between", flexDirection:"row",backgroundColor:"#F9F1FF", width:"100%", borderWidth:2, borderColor:"#1D2195" , borderRadius:4 , padding:8}}>
              <View>
                <Text style={{fontSize:14, color:"#1D2195"}} onPress={this.handleDownload}>Download</Text>
                <Text style={{fontSize:14, color:"#1D2195"}} onPress={this.handlePrint}>Print</Text>
              </View>
              <View style={{display:"flex", flexDirection:"row"}}>
                <TouchableOpacity onPress={this.handleDownload}>
                  <ImageBackground source={require('../../../assets/download.png')} style={{width: 43, height:35, marginRight:20}}/>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.handlePrint}>
                  <ImageBackground source={require('../../../assets/printer.png')} style={{width: 37, height:35, marginLeft:5 ,marginRight:15}}/>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>
              <Text style={[styles.descriptionText,{fontWeight:'bold',marginTop:10}]}>{this.jsonData.instructions[0]}</Text>
              <Text style={styles.descriptionText}>
                {this.jsonData.description[0]}
              </Text>

              <Text style={[styles.descriptionText,{fontWeight:'bold',marginTop:10}]}>{this.jsonData.instructions[1]}</Text>
              <Text style={styles.descriptionText}>
                {this.jsonData.description[1]}
              </Text>

              <Text style={[styles.descriptionText,{fontWeight:'bold',marginTop:10}]}>{this.jsonData.instructions[2]}</Text>
              <Text style={styles.descriptionText}>
                {this.jsonData.description[2]}
              </Text>

              <Text style={[styles.descriptionText,{fontWeight:'bold',marginTop:10}]}>{this.jsonData.instructions[3]}</Text>
              <Text style={styles.descriptionText}>
                {this.jsonData.description[3]}
              </Text>

              <Text style={[styles.descriptionText,{fontWeight:'bold',marginTop:10}]}>{this.jsonData.instructions[4]}</Text>
              <Text style={styles.descriptionText}>
                {this.jsonData.description[4]}
              </Text>

            </View>
          </View>
        </View>
      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <CustomButtonTouchable imageSource = {require('../../../assets/Symbolback.png')} style={{fontSize:'20'}} navigation= {this.props.navigation} onPress = {this.goBack}/>
          <ACDfooter  title="6" marginLeft="80%" />
      </ScrollView>
    </View>
  );
}
}
const styles = StyleSheet.create({
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',
  },
  loginButton:{
    borderRadius:5,
  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#707070",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
  descriptionText:{
   marginBottom:10,
   // fontWeight:'bold',
   width:'90%',
   marginLeft:10,
   marginRight:10,
   textAlign:'left',
   color:'#1D2195',
 },
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginBottom:30,
    width:'85%',
    // justifyContent:'center',
  },
})
