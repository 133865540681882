import React, { Component } from 'react';
import { View,Text,StyleSheet, Image, ImageBackground, TouchableOpacity, Platform, AsyncStorage
} from 'react-native';
import { Avatar } from 'react-native-elements';
import EventBus from 'eventing-bus'

export default class CustomHeaderNorthern extends Component {

  constructor(){
    super()
    this.state = {

    }

  }

  componentDidMount (){
    this.getprofileImg()
    EventBus.on("changeProfile", this.changeProfile)

  }

  changeProfile = async ()=>{
    // console.log('changing profile');
  }
  getprofileImg = async ()=>{
    // require('../../assets/Profile.png')

    const user = await AsyncStorage.getItem('@UserData');
    const timePeriods = await AsyncStorage.getItem('@timePeriod');

    let userParsed = JSON.parse(user)
    let timeParsed = JSON.parse(timePeriods)
    if(timePeriods){
      console.log('timeParsed.sinceCreated', timeParsed.sinceCreated);
      if(timeParsed.sinceCreated.length > 0){
        if(timeParsed.sinceCreated[0] < 10){
          this.setState({sinceCreated: "0" + timeParsed.sinceCreated[0]})
        }else{
          this.setState({sinceCreated: timeParsed.sinceCreated[0]})
        }
      }else{
        this.setState({sinceCreated: "00"})
      }
      if(timeParsed.sinceUpdated.length > 0){
        if(timeParsed.sinceUpdated[0] < 10){
          this.setState({sinceUpdated: "0" + timeParsed.sinceUpdated[0]})
        }else{
          this.setState({sinceUpdated: timeParsed.sinceUpdated[0]})
        }
      }else{
        this.setState({sinceUpdated: "00"})
      }
    }else{
      this.setState({sinceCreated: "00"})
      this.setState({sinceUpdated: "00"})
    }

    // console.log(userParsed);
    if(userParsed.profileImg){
      this.setState({profileImg: userParsed.profileImg})
    }
  }
  // navigateProfile = () =>{
  //   console.log('in update profile image');
  //   this.props.navigation.navigate("UploadImage")
  //
  // }
        render() {
                return (
                        <View style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{display:'flex', flexDirection:'row',width:'100%',minHeight:80, marginBottom:20,backgroundColor:'#F9F1FF',borderColor:'#BCE0FD', borderWidth:2, justifyContent:'space-between'}]:
                          [{display:'flex', flexDirection:'row',width:'100%',minHeight:80, marginBottom:20,backgroundColor:'#F9F1FF',borderColor:'#BCE0FD', borderWidth:2, justifyContent:'space-between', marginTop:10}]}>
                                <View style ={{width:"80%",display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                                  <TouchableOpacity style = {Platform.OS === 'ios' || Platform.OS ==='android' ? [{paddingLeft:10, width:"30%", maxWidth:100, flexDirection:'row'}]:
                                    [{ width:"30%", maxWidth:100, flexDirection:'row'}]} onPress={this.props.onPress}>
                                        <Avatar
                                          size="large"
                                          rounded
                                          source={{
                                            uri: this.props.profileImg
                                          }}
                                          containerStyle={{marginLeft: 4,height:65, width:65}}
                                          />
                                  </TouchableOpacity>
                                <View style = {{paddingLeft:15, width:"70%", whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', flexDirection:'column'}}>
                                        <Text numberOfLines={1} ellipsizeMode='tail' style={{color:'#1D2195',fontWeight:'bold' ,fontSize:20}}>{this.props.name}</Text>
                                        <Text numberOfLines={4} ellipsizeMode='tail' style={{color:'#1D2195', fontSize:10}} >{this.props.address}</Text>
                                </View>

                               </View>
                                <View style = {{width:"20%", flexDirection:'column',justifyContent:'center', alignItems:'center'}}>
                                  {/*
                                    <Text style={{color:'#1D2195',fontWeight:'bold' ,fontSize:20}}>{this.state.sinceCreated}</Text>
                                    <Text style={{color:'#1D2195',fontWeight:'bold' ,fontSize:20}}>{this.state.sinceUpdated}</Text>
                                  */}

                                </View>
                        </View>
                );
        }
}
