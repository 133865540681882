import React, { Component } from 'react';
import { View,Text,StyleSheet, Platform } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

export default class FooterText extends Component {

  handlePrivacy= async () =>{
    console.log("downloading ");
    if (Platform.OS == 'web' ) {
      WebBrowser.openBrowserAsync('https://res.cloudinary.com/hqepnl8tg/image/upload/v1572589445/Palcare-privacy-policy/disclaimer_and_privacy-_V1_teqlhf.pdf')
    }
    else {
      WebBrowser.openBrowserAsync('https://res.cloudinary.com/hqepnl8tg/image/upload/v1572589445/Palcare-privacy-policy/disclaimer_and_privacy-_V1_teqlhf.pdf')

    }
  }
        render() {
                return (
                        <View style = {{ display:'flex', flexDirection:'row',width:'80%', alignItems:'center', justifyContent:'center'}}>
                          <Text style= {{width:'60%', textAlign:'center', paddingTop:50, fontSize:10, color:"#1D2195"}} onPress={this.handlePrivacy} >By creating an account you agree to our Terms of Service and Privacy Policy</Text>
                        </View>
                );
        }
}
