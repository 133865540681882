import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TextInput, ScrollView, Alert, TouchableOpacity, KeyboardAvoidingView, ActivityIndicator } from 'react-native';
import FooterText from '../../Components/FooterText'
import CustomButton from '../../Components/CustomButton';
import axios from 'axios'
import DatePicker from 'react-native-datepicker'
import { Constants, Calendar } from 'expo';
import { AsyncStorage } from 'react-native';
import ValidationComponent from 'react-native-form-validator';
import global from "../../Styles/global.js"
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import UserDetail from '../../Components/UserDetail'


export default class ForgotPassword extends Component {
  constructor(){
    super()
    this.state= {
      name: '',
      email: '',
      role: '',
      address: '',
      birthday:'',
      password:'',
      confirmPassword:'',
      code:'',
      organisation:'',
      gp:'',
      newPasswordScreen:false,

      isLoading: false
    }

    this.maxDate = new Date()
  }

  componentDidMount(){
    console.log("title", this.props.navigation.state.params);

    this.setState({
      role: this.props.navigation.state.params
    })
  }

  handleVerify = () =>{

    if (this.state.code == "" || this.state.code == undefined) {
      alert("Verification code is required")
    }
    else {
      this.setState({isLoading:true})
      console.log("handleVerify pressed", global.url, this.state.code)
      var options = {
        "code": this.state.code,
        "id" :this.state.userId
      }
      var optionsAxios = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        url: global.url+'/verifyResetCode',
        data:  options
      }
      console.log("axios options :: ", optionsAxios);
      axios(optionsAxios)
      .then((response)=> {
        this.setState({isLoading:false})
        console.log("response axios", response);
        if (response.status == 200) {
          this.setState({verifyScreen : false, newPasswordScreen: true})
        }
      })
      .catch((err)=>{
        this.setState({isLoading:false})
        console.log("error authenticate :: ", err);
        if(err.response.data){
          alert(err.response.data.message)
        }
      })
    }
  }

  handleRecover = () =>{
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (this.state.email == "" || this.state.email == undefined) {
      alert("Email is required")
    }else if(!emailRegex.test(this.state.email )){
      alert("Invalid Email")
    }
    else {
      this.setState({isLoading:true})
      console.log("handleRecover pressed", global.url, this.state.email)
      var options = {
        "email": this.state.email.toLowerCase(),
      }
      var optionsAxios = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        url: global.url+'/forgotPassword',
        data:  options
      }
      console.log("axios options :: ", optionsAxios);
      axios(optionsAxios)
      .then((response)=> {
        this.setState({isLoading:false})
        console.log("response axios", response);
        if (response.status == 200) {
          this.setState({verifyScreen : true, email:''})
          this.setState({userId : response.data.result._id})
          alert("A code has been sent to your email address")
        }
      })
      .catch((err)=>{
        this.setState({isLoading:false})
        console.log("error authenticate :: ", err);
        if(err.response){
          alert(err.response.data.message)
        }
      })
    }
  }

  handleChangePassword = () =>{

    if (this.state.newPassword && this.state.confirmPassword) {
      if(this.state.newPassword !== this.state.confirmPassword){
        alert("Password did not match")
      }else if (this.state.newPassword.length < 6){
        alert("Password must be greater than 5 characters");
        return 0 ;
      }else {
        this.setState({isLoading:true})
        console.log("handleChangePassword pressed", global.url, )
        var options = {
          "newPassword": this.state.newPassword,
          "confirmPassword": this.state.confirmPassword,
          "id" :this.state.userId
        }
        var optionsAxios = {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          url: global.url+'/resetPassword',
          data:  options
        }
        console.log("axios options :: ", optionsAxios);
        axios(optionsAxios)
        .then((response)=> {
          this.setState({isLoading:false})
          console.log("response axios", response);
          if (response.status == 200) {
            this.setState({verifyScreen : false, email:''})
            alert("Password Succesfully Changed")
            this.props.navigation.navigate("Login")

          }
        })
        .catch((err)=>{
          this.setState({isLoading:false})
          console.log("error authenticate :: ", err);
          if(err.response.data){
            alert(err.response.data.message)
          }
        })
      }
    }else{
      alert("Enter New Password & Confirm Password")
    }
  }

  handleDetailPress =(e)=>{
    this.setState({
      editEmail: !this.state.editEmail
    })
    console.log('in state change');
  }
  render() {
    return (
      <View style = {global.cotainer}>
        <KeyboardAwareScrollView
          enableOnAndroid
          >
          <ScrollView style={{width:'100%'}} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
            {
              (this.state.verifyScreen)
              ?
              <View style= {Platform.OS === 'ios' || Platform.OS ==='android'? global.ItemsContainerMobile:global.ItemsContainerWeb}>
                <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
                  <Text style= {{marginTop: 10, color: '#1D2195',width:'100%', textAlign: 'left', paddingLeft:28, fontSize:20,fontWeight: 'bold'}}>Forgot Password</Text>
                  <Text style= {{color: '#1D2195',width:'100%', textAlign: 'left', paddingLeft:28,marginBottom:25,marginTop:60, fontSize:20,fontWeight: 'bold'}}>Verify Code</Text>
                  <View style = {styles.inputField}>
                    <Text style= {styles.textstyle} onPress={() => {this.code.focus()}}>Code</Text>
                    <TextInput
                      editable
                      ref={(ip) => this.code = ip}
                      style={styles.textInputStyle}
                      keyboardType = "phone-pad"
                      onChangeText={code => this.setState({ code })}
                      value={this.state.code}
                      />
                  </View>
                </View>
                <View style={{width:"90%", alignItems: 'center', justifyContent: 'center', marginTop:100}}>
                  {
                    this.state.isLoading == true
                    ?<View style={{marginTop:50,
                      marginBottom:10,
                      width:"100%",
                      height:48,
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center"}}>
                      <ActivityIndicator size="large" color="#9126FB" />
                    </View>
                    :<CustomButton title = "Verify Code" type="inScreen" navigation = {this.props.navigation} onPress= {this.handleVerify}/>
                }
              </View>
            </View>
            :
            (this.state.newPasswordScreen)

            ?
            <View style= {Platform.OS === 'ios' || Platform.OS ==='android'? global.ItemsContainerMobile:global.ItemsContainerWeb}>
              <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
                <Text style= {{marginTop: 10, color: '#1D2195',width:'100%', textAlign: 'left', paddingLeft:28, fontSize:20,fontWeight: 'bold'}}>Forgot Password</Text>
                <Text style= {{color: '#1D2195',width:'100%', textAlign: 'left', paddingLeft:28,marginBottom:25,marginTop:60, fontSize:20,fontWeight: 'bold'}}>Reset Password</Text>
                <View style = {styles.inputField}>
                  <Text style= {styles.textstyle} onPress={() => {this.email.focus()}}>New Password</Text>
                  <TextInput
                    editable
                    ref={(ip) => this.newPassword = ip}
                    style={styles.textInputStyle}
                    secureTextEntry={true}
                    onChangeText={newPassword => this.setState({ newPassword })}
                    value={this.state.newPassword}
                    />
                </View>
                <View style = {styles.inputField}>
                  <Text style= {styles.textstyle} onPress={() => {this.email.focus()}}>Confirm Password</Text>
                  <TextInput
                    editable
                    ref={(ip) => this.confirmPassword = ip}
                    style={styles.textInputStyle}
                    secureTextEntry={true}
                    onChangeText={confirmPassword => this.setState({ confirmPassword })}
                    value={this.state.confirmPassword}
                    />
                </View>
              </View>
              <View style={{width:"90%", alignItems: 'center', justifyContent: 'center', marginTop:100}}>
                {
                  this.state.isLoading == true
                  ?<View style={{marginTop:50,
                    marginBottom:10,
                    width:"100%",
                    height:48,
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"}}>
                    <ActivityIndicator size="large" color="#9126FB" />
                  </View>
                  :<CustomButton title = "Change Password" type="inScreen" navigation = {this.props.navigation} onPress= {this.handleChangePassword}/>
              }
            </View>
          </View>
          :
          <View style= {Platform.OS === 'ios' || Platform.OS ==='android'? global.ItemsContainerMobile:global.ItemsContainerWeb}>
            <View style={{width:"90%", alignItems: 'center', justifyContent: 'center'}}>
              <Text style= {{marginTop: 10, color: '#1D2195',width:'100%', textAlign: 'left', paddingLeft:28, fontSize:20,fontWeight: 'bold'}}>Forgot Password</Text>
              <Text style= {{color: '#1D2195',width:'100%', textAlign: 'left', paddingLeft:28,marginBottom:25,marginTop:60, fontSize:20,fontWeight: 'bold'}}>Email</Text>
              <View style = {styles.inputField}>
                <Text style= {styles.textstyle} onPress={() => {this.email.focus()}}>Email</Text>
                <TextInput
                  editable
                  ref={(ip) => this.email = ip}
                  style={styles.textInputStyle}
                  keyboardType = "email-address"
                  onChangeText={email => this.setState({ email })}
                  value={this.state.email}
                  />
              </View>
            </View>
            <View style={{width:"90%", alignItems: 'center', justifyContent: 'center', marginTop:100}}>
              {
                this.state.isLoading == true
                ?<View style={{marginTop:50,
                  marginBottom:10,
                  width:"100%",
                  height:48,
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center"}}>
                  <ActivityIndicator size="large" color="#9126FB" />
                </View>
                :<CustomButton title = "Reset Password" type="inScreen" navigation = {this.props.navigation} onPress= {this.handleRecover}/>
            }
          </View>
        </View>
      }
    </ScrollView>
  </KeyboardAwareScrollView>
</View>
);
}
}

const styles = StyleSheet.create({

  ItemsContainerMobile:{
    width: '100%',
    height:'100%',
    alignItems:'center',
    // justifyContent:'center',
  },
  ItemsContainerWeb:{
    flex:1,
    width: '35%',
    height:'100%',
    alignItems:'center',
    justifyContent:'center',
  },
  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 50,
    width:'95%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
    paddingLeft:10
  },
  textInputStyleConfirmPass:{
    height: 40,
    width:'65%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },

})
