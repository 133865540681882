import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Alert,
  Modal,
  TouchableHighlight
} from 'react-native';
import {  Button, CheckBox, Avatar } from 'react-native-elements'
// import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import UserComponentProvider from '../../Components/UserComponentProvider'

export default class SevenPdf1Provider extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    image:"https://res.cloudinary.com/hqepnl8tg/image/upload/v1571642768/cam_pnlffd.png",
    modalVisible: false,
  }

  // global.jsonData.forEach((i,idx,x)=>{
  //   if (i.title == 'ACD Overview - 4') {
  //     this.jsonData = i
  //   }
  // })

  // if(global.userData.userData.sevenStepPlan){
  //   if(global.userData.userData.sevenStepPlan.ssp_images.length > 0){
  //     this.toNext()
  //   }
  // }

  this.getLocalKeys()
}

componentDidMount() {
  this.getPermissionAsync();
}

toNext = async () =>{
  this.props.navigation.navigate('SevenPdf3Provider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData, image:result.uri, image:global.userData.userData.sevenStepPlan.ssp_images[0]})

  // this.props.navigation.navigate("SevenPdf3", {image:global.userData.userData.sevenStepPlan.ssp_images[0]})
  AsyncStorage.setItem("@SevenStepImages", JSON.stringify(global.userData.userData.sevenStepPlan.ssp_images) )
}

getPermissionAsync = async () => {
  if (Constants.platform.ios) {

    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL, Permissions.CAMERA);
    if (status !== 'granted') {
      alert('Sorry, we need camera roll permissions to make this work!');
    }
  }
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponentProvider name={this.props.navigation.state.params.allData.name} address={this.props.navigation.state.params.allData.address} profileImg = {this.props.navigation.state.params.allData.profileImg}/>})

      // this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}

ACDfilledout = () =>{
  console.log("ACDfilledout");
}

handleNext = () => {
  // this.props.navigation.navigate("ACDoverview3")
}

pickImage = async () => {
  console.log("Alert");
  if (Platform.OS !== 'web') {
    Alert.alert(
      'Alert',
      'Select option to upload file',
      [
        { text: 'Cancel', onPress: () => console.log('Cancel Pressed') },
        {
          text: 'Upload from Gallery',
          onPress: () => this.uploadFromGallery(),
          style: 'cancel',
        },
        { text: 'Open Camera', onPress: () => this.uploadFromCamera() },
      ],
      { cancelable: true }
    );
  }
  else {
    this.uploadFromGallery()
  }
}

uploadFromGallery = async () => {
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    // aspect: [4, 3],
    base64: false

  });

  console.log(result);

  if (!result.cancelled) {
    this.setState({ image:result.uri });
    this.props.navigation.navigate('SevenPdf2Provider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData, image:result.uri})

    // this.props.navigation.navigate("SevenPdf2", {image:result.uri})
  }
}

uploadFromCamera = async () => {
  let result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    // aspect: [4, 3],
    base64: false

  });

  console.log(result);
  this.formArray(result.uri);

  if (!result.cancelled) {
    this.setState({ image:result.uri });
    this.props.navigation.navigate('SevenPdf2Provider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData, image:result.uri})

    // this.props.navigation.navigate("SevenPdf2", {image:result.uri})

  }
}

formArray = async (uri) =>{
  console.log('imag uri', uri);
}

render() {
  return (
    <View style={global.container}>
      <ScrollView style={{width:'100%'}} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center', marginBottom:80}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:80}]:[global.ItemsContainerWeb,{minHeight:675}]}>

          {this.state.UserComponent}

          <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontSize:14, color:"#1D2195", width:"90%", marginBottom:100}}>
              When your GP has filled out the 7 step path ways.
              {'\n'}
              {'\n'}
              Take a picture of each page and click done when you have finished
            </Text>

            <TouchableOpacity onPress={() => this.pickImage()}>
              <ImageBackground
                source={require('../../../assets/cam.png')}
                style={{height:160, width:200}}/>
            </TouchableOpacity>

          </View>

        </View>

      </ScrollView>
    </View>
  );

}
}

const styles = StyleSheet.create({
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#707070",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  }

})
