import React, { Component } from 'react';
import { View,Text,StyleSheet, Image, ImageBackground, TouchableOpacity} from 'react-native';

export default class HeaderRight extends Component {

  toLogBook = () => {
    console.log('inlogbook click');
    this.props.navigation.navigate("LogBook")

  }
        render() {

                return (
                  <TouchableOpacity onPress={this.toLogBook}>
                    <View style = {{ display:'flex', flexDirection:'row',width:'100%', alignItems:'center', justifyContent:'center'}}>
                      <ImageBackground source={require('../../assets/burgericon.png')} style={{width: 30, height: 30, marginLeft:10, marginRight:10}}/>
                    </View>
                    <View style={{display:'flex', flexDirection:'row',width:'100%', alignItems:'center', justifyContent:'center'}}>
                      <Text style={{fontSize: 8, color:'#1D2195'}}>Logbook</Text>

                    </View>

                  </TouchableOpacity>

                );
        }
}
