import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  AsyncStorage,
  Linking,
  Dimensions,
  PixelRatio,
  NetInfo,
  AppState
} from 'react-native';
import {  Button } from 'react-native-elements'
import Icon from '@expo/vector-icons/FontAwesome'
import CustomButton from '../../Components/CustomButton'
import axios from 'axios'
import global from "../../Styles/global.js"
import EventBus from 'eventing-bus'


const { height } = Dimensions.get('window');


export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isLoading:false,
      screenHeight: 0,
      loadingScreen: true,
      appState: AppState.currentState,
    };

    NetInfo.getConnectionInfo().then(connectionInfo => {
      console.log(
        'Initial, type: ' + connectionInfo.type + ', effectiveType: ' + connectionInfo.effectiveType
      );

      if (connectionInfo.type == "none") {
        alert("Your device is working in offline mode")
        global.url = 'https://palcare-backend-dev.herokuapp.com'
        // global.url = 'https://palcare-backend-internal.herokuapp.com'

        // this.getLocalData()
        global.jsonData = require('../../Data/screens_data_new.json')

        global.jsonDatatest = require('../../Data/other_screens.json')
        this.getLocalKeys()
        console.log("OS :: ", Platform.OS);
        if (Platform.OS !== 'ios' && Platform.OS !== 'android') {
          this.getUrl()
        }

      }
      else {
        global.url = 'https://palcare-backend-dev.herokuapp.com'
        // global.url = 'https://palcare-backend-internal.herokuapp.com'

        this.getLocalData()
        this.getLocalKeys()
        this.getEnums()

        console.log("OS :: ", Platform.OS);
        if (Platform.OS !== 'ios' && Platform.OS !== 'android') {
          this.getUrl()
        }
      }
    });

  }

  getLocalData=()=>
  {
    console.log("In getLocalData loginscreen");
    var optionsAxios = {
      method: 'POST',
      headers: {'Content-Type': 'application/json' },
      url: global.url+'/getAppData',
    }
    console.log(" getScreenData Options axios ", optionsAxios );
    axios(optionsAxios)
    .then((response)=> {
      console.log("local Data Response recieved loginscreen ", response);
      if(response.data){
        global.jsonData = response.data.result

        global.jsonDatatest = response.data.others

        // global.jsonData = response.data.result
      }else{
        global.jsonData = require('../../Data/screens_data_new.json')

        global.jsonDatatest = require('../../Data/other_screens.json')

      }
      // AsyncStorage.setItem("@AppData", JSON.stringify(global.jsonData) )

      // global.jsonData = require('../../Data/screens_data_new.json')
      // global.userData = require('../../Data/user_data.json')

    })
    .catch((error)=>{
      console.log("error getAppData :: ", error);
    })
  }

  getUserAcdonLogin=(id, token, data, comingFrom, territoryId)=>
  {
    console.log("In getUserAcd on login", id, token, data);
    var optionsAxios = {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(token)},
      url: global.url+'/api/getUserAcd',
      data: {user_id: id, territory:territoryId}
    }
    console.log(" getUserAcd Options axios ", optionsAxios );
    axios(optionsAxios)
    .then((response)=> {
      console.log("/user Acd Response recieved on login ", response);
      if(response.data.result){
        if(response.data.result.length !== 0){
          this.setState({loadingScreen: false})
          // AsyncStorage.setItem("@AppData", JSON.stringify(response.data.result) )
          global.userData = response.data.result[0]
          AsyncStorage.setItem("@SyncedUserAcd", JSON.stringify(response.data.result[0]) )
          AsyncStorage.setItem("@timePeriod", JSON.stringify(response.data.result[0].timePeriod));

          // AsyncStorage.setItem("@AcdImages", JSON.stringify(response.data.result[0].acd_images) )

          console.log('response.data', response.data);
          console.log('comingFrom', comingFrom, data, data.user.userType);
          // global.jsonData = require('../../Data/screens_data_new.json')
          // global.userData = require('../../Data/user_data.json')
          if(data.user.userType == "patient"){
            if(data.user.territory.name === "Northern Territory"){
              console.log('ss');
              this.props.navigation.navigate('AcdHomeNorthern', {comingFrom:'login', data:data.user})

            }else{
              this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:data.user})
            }
          }else{
            this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:data.user});
          }
          // data.user.userType == "patient"?
          // this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:data.user})
          // :this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:data.user});


            // data.user.userType === "patient"
            // ?this.props.navigation.navigate("AcdHome")
            // :this.props.navigation.navigate("MainScreenProvider")
        }else{
          this.setState({loadingScreen: false})
          if(data.user.territory.name === "Northern Territory"){
            global.userData = require('../../Data/northern_acd.json')
          }else{
            global.userData = require('../../Data/user_data.json')
          }

          // global.userData = require('../../Data/user_data.json')
          AsyncStorage.setItem("@SyncedUserAcd", JSON.stringify(global.userData) )

            console.log('in if onLoginPress else');
            console.log('comingFrom', comingFrom, data, data.user.userType);

            if(data.user.userType == "patient"){
              if(data.user.territory.name === "Northern Territory"){
                console.log('ss');
                this.props.navigation.navigate('AcdHomeNorthern', {comingFrom:'login', data:data.user})

              }else{
                this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:data.user})
              }
            }else{
              this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:data.user});
            }

            // data.user.userType == "patient"?
            // this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:data.user})
            // :this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:data.user});

          // data.user.userType === "patient"
          // ?this.props.navigation.navigate("AcdHome")
          // :this.props.navigation.navigate("MainScreenProvider")
          console.log(response.data.message);
        }
      }else{
        this.setState({loadingScreen: false})

        if(data.user.territory.name === "Northern Territory"){
          global.userData = require('../../Data/northern_acd.json')
        }else{
          global.userData = require('../../Data/user_data.json')
        }

        AsyncStorage.setItem("@SyncedUserAcd", JSON.stringify(global.userData) )

        if (comingFrom == "onLoginPress") {
          console.log('data is', data);

          if(data.user.userType == "patient"){
            if(data.user.territory.name === "Northern Territory"){
              console.log('ss');
              this.props.navigation.navigate('AcdHomeNorthern', {comingFrom:'login', data:data.user})

            }else{
              this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:data.user})
            }
          }else{
            this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:data.user});
          }

          // data.user.userType == "patient"
          // ?this.props.navigation.navigate("AcdHome")
          // :this.props.navigation.navigate("MainScreenProvider")
        }
      }

      // AsyncStorage.setItem("@AppData", JSON.stringify(response.data.result) )
      // global.jsonData = response.data.result
    })
    .catch((error)=>{
      this.setState({loadingScreen: false})

      console.log("error getUserDataAcD :: ", error);
    })
  }

  getUserAcd=(id, token, data, comingFrom, territoryId)=>
  {
    console.log("In getUserAcd", id, token, data, territoryId);
    var optionsAxios = {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(token)},
      url: global.url+'/api/getUserAcd',
      data: {user_id: id, territory:territoryId}
    }
    console.log(" getUserAcd Options axios ", optionsAxios );
    axios(optionsAxios)
    .then((response)=> {
      console.log("/user Acd Response recieved ", response);
      if(response.data.result){
        if(response.data.result.length !== 0){
          this.setState({loadingScreen: false})
          // AsyncStorage.setItem("@AppData", JSON.stringify(response.data.result) )
          global.userData = response.data.result[0]
          AsyncStorage.setItem("@SyncedUserAcd", JSON.stringify(response.data.result[0]) )
          AsyncStorage.setItem("@timePeriod", JSON.stringify(response.data.result[0].timePeriod));


          // AsyncStorage.setItem("@AcdImages", JSON.stringify(response.data.result[0].acd_images) )

          console.log('response.data', response.data);
          // global.jsonData = require('../../Data/screens_data_new.json')
          // global.userData = require('../../Data/user_data.json')
          if (comingFrom != "onLoginPress") {
            if(data.userType == "patient"){
              if(data.territory.name === "Northern Territory"){
                console.log('ss');
                this.props.navigation.navigate('AcdHomeNorthern', {comingFrom:'login', data:data.user})

              }else{
                this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:data.user})
              }
            }else{
              this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:data.user});
            }

            // data.userType == "patient"
            // ?this.props.navigation.navigate("AcdHome")
            // :this.props.navigation.navigate("MainScreenProvider")
          }
        }else{
          console.log('in second else');

          this.setState({loadingScreen: false})

          if(data.territory.name === "Northern Territory"){
            global.userData = require('../../Data/northern_acd.json')
          }else{
            global.userData = require('../../Data/user_data.json')
          }
          AsyncStorage.setItem("@SyncedUserAcd", JSON.stringify(global.userData) )

          if (comingFrom != "onLoginPress") {

            if(data.userType == "patient"){
              if(data.territory.name === "Northern Territory"){
                console.log('ss');
                this.props.navigation.navigate('AcdHomeNorthern', {comingFrom:'login', data:data.user})

              }else{
                this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:data.user})
              }
            }else{
              this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:data.user});
            }


          // data.userType == "patient"
          // ?this.props.navigation.navigate("AcdHome")
          // :this.props.navigation.navigate("MainScreenProvider")
        }
          console.log(response.data.message);
        }
      }else{
        console.log('in main else', data);
        this.setState({loadingScreen: false})

        if(data.territory.name === "Northern Territory"){
          global.userData = require('../../Data/northern_acd.json')
        }else{
          global.userData = require('../../Data/user_data.json')
        }
        // global.userData = require('../../Data/user_data.json')
        console.log('global.userData', global.userData);
        AsyncStorage.setItem("@SyncedUserAcd", JSON.stringify(global.userData) )

        if (comingFrom != "onLoginPress") {
          console.log('data is', data);

          if(data.userType == "patient"){
            if(data.territory.name === "Northern Territory"){
              console.log('ss');
              this.props.navigation.navigate('AcdHomeNorthern', {comingFrom:'login', data:data.user})

            }else{
              this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:data.user})
            }
          }else{
            this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:data.user});
          }

          // data.userType == "patient"
          // ?this.props.navigation.navigate("AcdHome")
          // :this.props.navigation.navigate("MainScreenProvider")
        }
      }

      // AsyncStorage.setItem("@AppData", JSON.stringify(response.data.result) )
      // global.jsonData = response.data.result
    })
    .catch((error)=>{
      this.setState({loadingScreen: false})

      console.log("error getUserData :: ", error);
    })
  }

  componentDidMount(){
    AppState.addEventListener('change', this._handleAppStateChange);
    // EventBus.on("verificationCode", this.verifyUser)
  }

  getEnums = async () =>{
    console.log('in getEnums');
    let array = []
    var optionsAxios = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      url: global.url + '/getAllTerritories'
    }
    console.log(" getUser Options axios ", optionsAxios );
    axios(optionsAxios)
    .then((response)=> {
      console.log("/user Response recieved ", response);

      if(response.data){
        AsyncStorage.setItem("@allTerritories", JSON.stringify(response.data.territories))

      }else{
          console.log(response.data.message);
      }
    })
    .catch((error)=>{

      console.log("error getUserData :: ", error);
    })
  }

  _handleAppStateChange = (nextAppState) => {
    console.log("App State :: ", nextAppState);
    if (nextAppState == "background") {
      AsyncStorage.setItem("@AppData", JSON.stringify(global.jsonData))
      console.log("** Data saved **");
    }
  };

  getUrl = () => {
    if (Platform.OS === 'web') {
      var url = require('url')
      // var fetch = require('node-fetch')
      const urlObj = url.parse(document.location.href, true);
      global.urlHref = urlObj.protocol+"//"+ urlObj.host
      if (urlObj.path !== "/") {
        var code = urlObj.path.replace("/",'')
        // this.verifyUser(code)
      }

      console.log("url :: ", urlObj);
    }
  }

  // verifyUser = (e) =>{
  //   console.log("verification Code :: ", e);
  //   var options = {
  //     "code": e,
  //   }
  //   var optionsAxios = {
  //     method: 'POST',
  //     headers: {'Content-Type': 'application/json'},
  //     url: global.url+'/getOneTimeToken',
  //     data:  options
  //   }
  //   console.log("axios options :: ", optionsAxios);
  //   axios(optionsAxios)
  //   .then((response)=> {
  //     console.log('response on verify', response);
  //     if (response.status == 200) {
  //       console.log('we are in 200 onetimetoken', response);
  //
  //       if (response.data.user.accountStatus == "pending") {
  //         if (Platform.OS == 'web') {
  //           Linking.openURL(global.urlHref);
  //         }
  //         this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:response.data.user});
  //       }else {
  //         console.log('we are navigating onetimetoken', response);
  //
  //         AsyncStorage.setItem("@UserData", JSON.stringify(response.data.user))
  //         AsyncStorage.setItem("@token", JSON.stringify(response.data.token))
  //         if (Platform.OS == 'web') {
  //           Linking.openURL(global.urlHref);
  //         }
  //         if(response.data.user.userType == "patient"){
  //           if(response.data.user.territory.name === "Northern Territory"){
  //             console.log('ss');
  //             this.props.navigation.navigate('AcdHomeNorthern', {comingFrom:'login', data:data.user})
  //
  //           }else{
  //             this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:data.user})
  //           }
  //         }
  //         // this.props.navigation.navigate('AcdHome',{comingFrom:"login", data:response.data.user});
  //       }
  //     }
  //   })
  //   .catch((err)=>{
  //     if (Platform.OS == 'web') {
  //       Linking.openURL(global.urlHref);
  //     }
  //     this.setState({isLoading:false})
  //     console.log("error getOneTimeToken :: ", err);
  //     alert(err.response.data.message)
  //   })
  // }

  getLocalKeys = async () =>{
    console.log('getLocalKeys');

    const token = await AsyncStorage.getItem('@token');
    const value = await AsyncStorage.getItem('@UserData');

    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);
      if(data.userType === 'patient'){
        this.getUserAcd(data._id, token, data, "getLocalKeys", data.territory._id);
        // this.getNorthernStatic(data._id, token, data, "getLocalKeys");
      }else if(data.userType === 'care-provider'){
        this.setState({loadingScreen: false})
        console.log('is to care provider');

        this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:data});

      }
    }else{
      this.setState({loadingScreen:false})

    }

  }
  getNorthernStatic = (id, token, data, comingFrom) =>{

    global.userData = require('../../Data/northern_acd.json')
    AsyncStorage.setItem("@SyncedUserAcd", JSON.stringify(global.userData) )

      console.log('data is', data);
      data.userType == "patient"
      ?this.props.navigation.navigate("AcdHomeNorthern")
      :this.props.navigation.navigate("MainScreenProvider")

  }

  onLoginPress = () =>{
    if (this.state.email == "" || this.state.email == undefined) {
      alert("Email is required")
    }
    else if (this.state.password == "" || this.state.password == undefined) {
      alert("Password is required")
    }
    else {
      this.setState({isLoading:true})
      console.log("login pressed", global.url, this.state.email, this.state.password)
      var options = {
        "email": this.state.email.toLowerCase(),
        "password": this.state.password,

      }
      var optionsAxios = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        url: global.url+'/authenticate',
        data:  options
      }
      console.log("axios options :: ", optionsAxios);
      axios(optionsAxios)
      .then((response)=> {
        this.setState({isLoading:false})
        console.log("response axios", response);
        if (response.status == 200) {
          if (response.data.user.accountStatus == "pending") {
            this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:response.data.user});
          }
          else {
            AsyncStorage.setItem("@UserData", JSON.stringify(response.data.user))
          AsyncStorage.setItem("@token", JSON.stringify(response.data.token))
          if(response.data.user.userType == "patient"){
            this.getUserAcdonLogin(response.data.user._id, JSON.stringify(response.data.token), response.data, "onLoginPress", response.data.user.territory._id);
          }else{
            this.setState({loadingScreen: false})

            this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:response.data.user});
          }
          // response.data.user.userType == "patient"?
          // this.props.navigation.navigate('AcdHome', {comingFrom:'login', data:response.data.user})
          // :this.props.navigation.navigate('MainScreenProvider',{comingFrom:"login", data:response.data.user});
          }
        }
      })
      .catch((err)=>{
        this.setState({isLoading:false})
        console.log("error authenticate :: ", err);
        if(err.response.data){
          alert(err.response.data.message)
        }
      })
    }
  }

  onSignUpPress=()=>{
    this.props.navigation.navigate('CreateAccount');
  }

  onForgotPress=()=>{
    console.log('on forgot press');
    this.props.navigation.navigate('ForgotPassword');
  }


  onContentSizeChange = (contentWidth, contentHeight) => {
    // Save the content height in state
    this.setState({ screenHeight: contentHeight });
  };

  render() {
    let { width, height } = Dimensions.get('window');
    console.log('width::', width);
    console.log('height::', height);
    const scrollEnabled = this.state.screenHeight > height;

    if (Platform.OS == "web") {
      return(
        <View style = {[global.container,{backgroundColor:'#501D95', paddingTop:50}]}>
          {
            (this.state.loadingScreen)
            ?
            <View style={{width:"100%", height:"100%",
    position: 'absolute',
    top: 0, left: 0,
    right: 0, bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'}}
    >
              <ActivityIndicator size="large" color="#ffff" />
            </View>
            :
            <ScrollView
           style={ {display:'flex', width:'100%'} }
           contentContainerStyle={[global.mainInsideContainer,{height:800}]}
           scrollEnabled={scrollEnabled}
           onContentSizeChange={this.onContentSizeChange}
         >
        <View style= {Platform.OS === 'ios' || Platform.OS ==='android'
          ?global.ItemsContainerMobile
          :[global.ItemsContainerWeb,{minHeight:675}]
        }>

        <View style = {stylesWeb.headerContainer}>
          <ImageBackground source={require('../../../assets/Image10.png')} style={{width: 255, height: 63,marginBottom:10}}/>
          <ImageBackground source={require('../../../assets/Hands_front_page.png')}style={{width: 115, height: 101, marginTop:25,marginBottom:10}} />
          <ImageBackground source={require('../../../assets/PALCARE.png')} style={{width: 90, height: 23, marginTop:20,marginBottom:10}}/>
        </View>
        <View style = {stylesWeb.formContainer}>
          <View style={stylesWeb.InputContainer}>
            <Icon
              name='user'
              size={18}
              color='#fff' />
            <TextInput
              editable
              style={{ height: 40, width:'90%', color:'white', alignSelf:'flex-end'  }}
              placeholder="Email"
              keyboardType = "email-address"
              placeholderTextColor="#FFFFFF"
              onChangeText={email => this.setState({ email })}
              value={this.state.email}
              />
          </View>
          <View style ={stylesWeb.seperator}></View>
          <View style={stylesWeb.InputContainer}>
            <Image
              source={require('../../../assets/icons8-lock-32.png')}
              style={{width: 20, height: 20}}
              />
            <TextInput
              editable
              style={{ height: 40, width:'90%', color:"white", alignSelf:'flex-end'  }}
              secureTextEntry={true}
              placeholder="Password"
              placeholderTextColor="#FFFFFF"
              onChangeText={password => this.setState({ password })}
              value={this.state.password}
              />
          </View>
          <View style ={stylesWeb.seperator}></View>
          {this.state.isLoading == true
            ?<View style={{marginTop:50,
              marginBottom:10,
              width:"100%",
              height:48,
              display:"flex",
              justifyContent:"center",
              alignItems:"center"}}>
              <ActivityIndicator size="large" color="#ffff" />
            </View>
            :<View style={{marginTop:40,width:'100%',alignItems:'center', justifyContent:'center'}}><CustomButton title= "LOG IN" type = "inScreen" navigation= {this.props.navigation} onPress={() => this.onLoginPress()}/></View>
        }
        <Text onPress={this.onForgotPress} style = {{color: '#A2A2A2', marginTop: 40, marginBottom:20}}>Forget Password?</Text>
        <Text style = {{color: '#A2A2A2'}}>Don't have an account? <Text onPress={this.onSignUpPress} style = {{color: '#fff', fontWeight:'600'}}>SIGN UP</Text></Text>


      </View>

      <View style = {stylesWeb.footerContainer}>

        <View style={{justifyContent:'flex-end', alignItems:'center' , width: '100%' }}>
          <View style ={stylesWeb.seperator}></View>

          <Text style = {{color: '#A2A2A2', marginTop: 20, width: '80%', textAlign:'center'}}>Developed in cooperation</Text>
            <Text style = {{color: '#A2A2A2' , width: '80%', textAlign:'center'}}>with the Australian Digital Health Agency by</Text>
          <Text style = {{color: '#A2A2A2' , width: '80%', textAlign:'center'}}>Sammetric Pty Ltd</Text>
        </View>
      </View>
    </View>
  </ScrollView>

  }
  </View>
      )
    }

    return (
      <View style = {[global.container,{backgroundColor:'#501D95', paddingTop:50}]}>
        {
          (this.state.loadingScreen)
          ?
          <View style={{width:"100%", height:"100%",
  position: 'absolute',
  top: 0, left: 0,
  right: 0, bottom: 0,
  justifyContent: 'center',
  alignItems: 'center'}}
  >
            <ActivityIndicator size="large" color="#ffff" />
          </View>
          :
          <View style= {Platform.OS === 'ios' || Platform.OS ==='android'
            ?global.ItemsContainerMobile
            :[global.ItemsContainerWeb,{minHeight:675}]
          }>
             <ScrollView
            style={ {display:'flex', width:'100%'} }
            contentContainerStyle={[global.mainInsideContainer,{height:"100%"}]}
            scrollEnabled={false}
            onContentSizeChange={this.onContentSizeChange}
          >
          <View style = {styles.headerContainer}>
            <View style={{width: "100%", height: "20%", display:"flex", justifyContent:"center"}}>
              <ImageBackground resizeMode="contain" source={require('../../../assets/Image10.png')} style={{ width:"100%", height:"100%"}}/>
            </View>

            <View style={{width: "100%", height: "60%", padding:30}}>
              <ImageBackground resizeMode="contain" source={require('../../../assets/Hands_front_page.png')} style={{ width:"100%", height:"100%"}}/>
            </View>

            <View style={{width: "100%", height: "20%", padding:10}}>
              <ImageBackground resizeMode="contain" source={require('../../../assets/PALCARE.png')} style={{ width:"100%", height:"100%"}}/>
            </View>
          </View>
          <View style = {styles.formContainer}>
            <View style={styles.InputContainer}>
              <Icon
                name='user'
                size={18}
                color='#fff' />
              <TextInput
                editable
                style={{  width:'90%', color:'white', alignSelf:'flex-end'  }}
                placeholder="Email"
                keyboardType = "email-address"
                placeholderTextColor="#FFFFFF"
                onChangeText={email => this.setState({ email })}
                value={this.state.email}
                />

            </View>
            <View style ={styles.seperator}></View>

            <View style={styles.InputContainer}>
              <Image
                source={require('../../../assets/icons8-lock-32.png')}
                style={{width: 20, height: 20}}
                />

              <TextInput
                editable
                style={{  width:'90%', color:"white", alignSelf:'flex-end'  }}
                secureTextEntry={true}
                placeholder="Password"
                placeholderTextColor="#FFFFFF"
                onChangeText={password => this.setState({ password })}
                value={this.state.password}
                />

            </View>
            <View style ={styles.seperator}></View>

            {this.state.isLoading == true
              ?<View style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                height:"25%"
              }}>
                <ActivityIndicator size="large" color="#ffff" />
              </View>
              :<View style={{width:'90%',height:"25%", alignItems:'center', justifyContent:'center'}}>
                <Button
                  raised
                  title="LOG IN"
                  onPress = {() => this.onLoginPress()}
                  titleStyle = {{color:"white", fontSize:14, fontWeight:'bold'}}
                  buttonStyle = {{borderRadius:5, backgroundColor:"#9126FB", width: '100%', height:48 ,alignSelf:'center', justifyContent:'center'}}
                  containerStyle= {{width:'100%'}}
                  />
                </View>
          }
          <Text onPress={this.onForgotPress} style = {{color: '#A2A2A2', marginBottom:5, marginTop:10}} >Forget Password?</Text>

          <Text style = {{color: '#A2A2A2'}}>Don't have an Account? <Text onPress={this.onSignUpPress} style = {{color: '#fff', fontWeight:'600'}}>SIGN UP</Text></Text>




        </View>

        <View style = {styles.footerContainer}>

          <View style={{justifyContent:'flex-end', alignItems:'center' , width: '100%' }}>
            <View style ={styles.seperatorNoMargin}></View>


            <Text style = {{color: '#A2A2A2', marginTop: 5, width: '80%', textAlign:'center'}}>Developed in cooperation</Text>

            <Text style = {{color: '#A2A2A2' , width: '80%', textAlign:'center'}}>with the Australian Digital Health Agency</Text>
              <Text style = {{color: '#A2A2A2' , width: '80%', textAlign:'center'}}>Sammetric Pty Ltd</Text>

          </View>
        </View>
        </ScrollView>
      </View>
        }

  </View>
);
}
}


const styles = StyleSheet.create({

  headerContainer:{
    flex:3,
    height:'100%',
    width: '100%',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center',
  },
  formContainer:{
    flex:4,
    width: '100%',
    alignItems:'center',
    justifyContent:'center',
  },
  footerContainer:{
    flex:1,
    width: '100%',
    alignItems:'center',
    marginBottom:30,
    justifyContent:'center',
  },
  InputContainer:{
    flexDirection:'row',
    alignItems:'center',
    paddingLeft:20,
    justifyContent:'space-between',
    marginTop:10,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  seperatorNoMargin:{
    backgroundColor:'#ffffff66',
    width:'90%',
    height:2,
  },
  seperator:{
    backgroundColor:'#ffffff66',
    width:'90%',
    height:2,
    marginBottom:10
  },
  seperator1:{
    backgroundColor:'#ffffff33',
    width:'90%',
    height:1,
    marginTop:20,
    marginBottom:30
  }
})

const stylesWeb = StyleSheet.create({
  headerContainer:{
    flex:2,
    height:'100%',
    marginTop:30,
    width: '100%',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center',
  },
  formContainer:{
    flex:4,
    width: '100%',
    marginTop:30,
    alignItems:'center',
    justifyContent:'center',
  },
  footerContainer:{
    flex:1,
    width: '100%',
    alignItems:'center',
    marginBottom:30,
    justifyContent:'center',
  },
  InputContainer:{
    flexDirection:'row',
    alignItems:'center',
    paddingLeft:20,
    justifyContent:'space-between',
    marginTop:10,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',
  },
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',
  },
  loginButton:{
    borderRadius:5,
  },
  seperator:{
    backgroundColor:'#ffffff66',
    width:'90%',
    height:2,
    marginTop: -10
  },
  seperator1:{
    backgroundColor:'#ffffff33',
    width:'90%',
    height:1,
    marginTop: -7
  }
})
