import React, { Component } from 'react';
import { View,Text,StyleSheet, Image, ImageBackground, TouchableOpacity} from 'react-native';

export default class ResourceLogComponent extends Component {
  render() {
    return (
      <TouchableOpacity onPress = {this.props.onPress} style = {{ display:'flex', flexDirection:'column',width:'100%', alignItems:'flex-start', justifyContent:'center', marginBottom:10}}>
        <View style={{backgroundColor:'#F9F1FF', width:'100%',height:80, alignItems:'center', flexDirection:'row',}}>
                <View style={{width:'30%', alignItems:'center', justifyContent:'center'}}>
                        <ImageBackground source={require('../../../assets/resourcePdf11.png')} style={{width: 40, height: 50}}/>
                </View>
                <View style={{width:'70%', display:'flex', flexDirection:'column', }}>
                <View style={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'flex-end'}}>
                  <Text style={{color:'#7F7F7F', fontSize:12, marginHorizontal:10, fontWeight:'bold', justifyContent:'flex-end', alignItems:'flex-end'}}>{this.props.date}</Text>
                </View>
                <View style={{width:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                  <Text style={{color:'#7F7F7F', alignItems:'flex-start', fontSize:25, fontWeight:'700', marginBottom:4}}>{this.props.stepName}</Text>
                </View>
                </View>
        </View>
      </TouchableOpacity>
    );
  }
}
