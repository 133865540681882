import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TextInput,TouchableOpacity, ImageBackground, ScrollView , ActivityIndicator, Dimensions, NetInfo} from 'react-native';
import ACDfooter from '../../Components/ACDfooter'
import CustomButton from '../../Components/CustomButton';
import UserComponent from '../../Components/UserComponent'
import { AsyncStorage } from 'react-native';
import global from "../../Styles/global"
import axios from 'axios'
import ResourceLogComponent from './ResourceLogComponent'
import EventBus from 'eventing-bus'
import * as FileSystem from "expo-file-system";
import * as Print from 'expo-print';
import * as WebBrowser from 'expo-web-browser';
import { NavigationEvents } from 'react-navigation';

var moment = require('moment');

export default class ResourceLog extends Component {
  constructor(){
    super()
    this.state={
      providerText :'You have now applied for a PALCARE account. When your account has been approved you will be able to login.',
      patientText : 'You have now signed up for PALCARE You will receive an email to confirm your email and sign-up.',
      verificationPending: 'Email verification is pending, Confirm your email then you will be able to login.',
      code:'',
      winHeight:Dimensions.get("window").height,

      isLoading:false,
      AcdGenerated:false,
      AcdWitnessedprocess:false,
      ActivityProcessing:true,
      PdfResource:true,
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  this.getLocalKeys()
  this.jsonUserMain = global.userData
}


// getLocalKeys = async () =>{
//   const value = await AsyncStorage.getItem('@UserData');
//   this.token = await AsyncStorage.getItem('@token');
//   this.user = await AsyncStorage.getItem('@UserData');
//   let userSingle = JSON.parse(this.user)
//   if (value !== null) {
//     var data = JSON.parse(value)
//     console.log("UserData :: ", data);
//
//     this.setState({userName:data.name})
//     this.setState({userAddress:data.address})
//
//     this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
//     // this.getLogBook()
//     // this.getVerifiedLogBook()
//
//   }
// }

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  let arrayLog = []
  this.token = await AsyncStorage.getItem('@token');
  this.user = await AsyncStorage.getItem('@UserData');


  let userSingle = JSON.parse(this.user)
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);

    this.setState({userName:data.name})
    this.setState({userAddress:data.address})
    this.setState({gp:data.gp})

    this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    // this.getLogBook()
    // this.getVerifiedLogBook()
    this.getLogResource()

//extra
    // const allPdfs = await AsyncStorage.getItem('@resourcePdfs');
    // const arrayLog = []
    //
    // this.pdfs = JSON.parse(allPdfs)
    // console.log('this.pdfs', this.pdfs);
    // if(this.pdfs){
    //   console.log('in map');
    //   this.pdfs.map(x =>{
    //     arrayLog.push(<SevenLogComponent stepName= {"By " + data.gp} onPress= {() => this.handleDownload('SevenPdf4', x)} date = 'Any date'/>)
    //   })
    //
    //   this.setState({resourcePdf: arrayLog})
    //
    // }
    //extra
  }
}
onWillFocus = ()=>{
  this.getLocalKeys()
  // this.getLogResource()

}

getLogResource = (e) =>{
  console.log('in get getLogResource');
  let arrayCreated = []
  NetInfo.getConnectionInfo().then(connectionInfo => {
    console.log(
      'Initial, type: ' + connectionInfo.type + ', effectiveType: ' + connectionInfo.effectiveType
    );

    if (connectionInfo.type == "none") {
      alert("Your need internet access to get updated LogBook")
      // global.url = 'https://palcare-backend-dev.herokuapp.com'
      // this.getLocalData()
      console.log("OS :: ", Platform.OS);
      this.setState({downloading:false})
    }
    else {

      this.setState({downloading: true})
      let optionsAxios = ''
        optionsAxios = {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
          url: global.url+'/api/getAllResources',
        }

      console.log(" getLogResource Options axios ", optionsAxios );
      axios(optionsAxios)
      .then((response)=> {
        this.setState({downloading:false})

        console.log("/getLogResource Acd Response recieved ", response);
        this.setState({ActivityProcessing: false})

        if(response.data.success){
          // if(response.data.acdCreated.length !== 0 ){
          //   this.setState({PdfResource:true})
          // }

          if(response.data.result[0].resourcePdfSubmitted.length > 0){
            let reversedCreated =  response.data.result[0].resourcePdfSubmitted.reverse();
            reversedCreated.map((x,idx,z) =>{
                this.setState({pdf:x.url})
                console.log("pdf is ", x);
                console.log("date", moment(x.submittedOn).format('DD,MM,YYYY'));
                  let lastDate = moment(x.submittedOn).format('D');
                  // var j = i % 10,
                  // k = i % 100;
                  if (lastDate == 1 && lastDate != 11) {
                    lastDate = lastDate + "st";
                  }
                  if (lastDate == 2 && lastDate != 12) {
                    lastDate = lastDate + "nd";
                  }
                  if (lastDate == 3 && lastDate != 13) {
                    lastDate = lastDate + "rd";
                  }else{
                    lastDate = lastDate + "th";
                  }
                  this.setState({lastDate:lastDate})
                  // let fullDate = lastDate + '-' + moment(x.submittedOn).format('MM-YYYY')
                  let fullDate = moment(x.submittedOn).format('DD-MM-YYYY')

                  this.setState({date:fullDate})
                  arrayCreated.push(<ResourceLogComponent stepName= {x.resourceName} onPress= {() => this.handleDownload('ResourcePdf', x.url)} date = {fullDate}/>)
            })
            this.setState({resourcePdf:arrayCreated})
            this.setState({PdfResource:false})

          }else{
            this.setState({resourcePdf: <ResourceLogComponent stepName= 'No Record Found' />})
            this.setState({PdfResource:false})

          }

        }else{
          console.log(response.data.message);
        }
      })
      .catch((error)=>{
        this.setState({PdfResource:false})

        console.log("error", error);
      })

    }
  });
}


getLogBook =(e) =>{
  let arrayCreated = []
  let arrayWitnessed = []
  console.log('in get logbook');
  NetInfo.getConnectionInfo().then(connectionInfo => {
    console.log(
      'Initial, type: ' + connectionInfo.type + ', effectiveType: ' + connectionInfo.effectiveType
    );

    if (connectionInfo.type == "none") {
      alert("Your need internet access to get updated LogBook")
      // global.url = 'https://palcare-backend-dev.herokuapp.com'
      // this.getLocalData()
      console.log("OS :: ", Platform.OS);
      this.setState({downloading:false})
    }
    else {

      this.setState({downloading: true})
      let optionsAxios = ''
      let user = JSON.parse(this.user)
      if(this.jsonUserMain._id !== undefined && this.jsonUserMain._id !== '' && this.jsonUserMain._id !== null){
        optionsAxios = {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
          url: global.url+'/api/getLogBook',
          data: {acdId: this.jsonUserMain._id}
        }

        console.log(" logbook Options axios ", optionsAxios );
        axios(optionsAxios)
        .then((response)=> {
          this.setState({downloading:false})

          console.log("/logbook Acd Response recieved ", response);
          this.setState({ActivityProcessing: false})

          if(response.data.success){
            if(response.data.acdCreated.length !== 0 ){
              this.setState({AcdGenerated:true})
            }

            if(response.data.acdWitnessed.length !== 0 ){
              this.setState({AcdWitnessedprocess:true})
            }


            if(response.data.acdCreated.length > 0){
              let reversedCreated =  response.data.acdCreated.reverse();
              reversedCreated.map((x,idx,z) =>{
                this.setState({pdf:x.url})
                console.log("pdf is ", x);
                console.log("date", moment(x.createdOn).format('DD,MM,YYYY'));
                let lastDate = moment(x.createdOn).format('D');
                // var j = i % 10,
                // k = i % 100;
                if (lastDate == 1 && lastDate != 11) {
                  lastDate = lastDate + "st";
                }
                if (lastDate == 2 && lastDate != 12) {
                  lastDate = lastDate + "nd";
                }
                if (lastDate == 3 && lastDate != 13) {
                  lastDate = lastDate + "rd";
                }else{
                  lastDate = lastDate + "th";
                }
                this.setState({lastDate:lastDate})
                let fullDate = lastDate + ' of ' + moment(x.createdOn).format('MMMM YYYY')
                this.setState({date:fullDate})

                arrayCreated.push(<LogBookComponent stepName= 'ACD Created' onPress= {() => this.handleDownload(x.url)} doctorName = {"By " + this.state.userName} date = "any date"/>)
              })
              this.setState({createdAcdLogBook:arrayCreated})

            }else{
              this.setState({createdAcdLogBook: <LogBookComponent stepName= 'ACD Created'  doctorName = {"No Record Found"}/>})

              //
              // this.setState({pdf:response.data.acdCreated.url})
              //
              // let lastDate = moment(response.data.acdCreated.createdOn).format('D');
              // // var j = i % 10,
              // // k = i % 100;
              // if (lastDate == 1 && lastDate != 11) {
              //   lastDate = lastDate + "st";
              // }
              // if (lastDate == 2 && lastDate != 12) {
              //   lastDate = lastDate + "nd";
              // }
              // if (lastDate == 3 && lastDate != 13) {
              //   lastDate = lastDate + "rd";
              // }else{
              //   lastDate = lastDate + "th";
              // }
              // this.setState({lastDate:lastDate})
              // let fullDate = lastDate + ' of ' + moment(response.data.acdCreated.createdOn).format('MMMM YYYY')
              // this.setState({date:fullDate})
              // this.setState({AcdGenerated:true})

            }

            if(response.data.acdWitnessed.length > 0){
              let reversedWitnessed =  response.data.acdWitnessed.reverse();

              reversedWitnessed.map((q,qdx,p) =>{
                this.setState({pdfWitnessed:q.url})
                console.log("pdf is fullDateWitnessed", q);
                console.log("date", moment(q.submittedOn).format('DD,MM,YYYY'));
                let lastDateWitnessed = moment(q.submittedOn).format('D');
                // var j = i % 10,
                // k = i % 100;
                if (lastDateWitnessed == 1 && lastDateWitnessed != 11) {
                  lastDateWitnessed = lastDateWitnessed + "st";
                }
                if (lastDateWitnessed == 2 && lastDateWitnessed != 12) {
                  lastDateWitnessed = lastDateWitnessed + "nd";
                }
                if (lastDateWitnessed == 3 && lastDateWitnessed != 13) {
                  lastDateWitnessed = lastDateWitnessed + "rd";
                }else{
                  lastDateWitnessed = lastDateWitnessed + "th";
                }
                this.setState({lastDateWitnessed:lastDateWitnessed})
                let fullDateWitnessed = lastDateWitnessed + ' of ' + moment(q.submittedOn).format('MMMM YYYY')
                this.setState({dateWitnessed:fullDateWitnessed})
                console.log('fullDateWitnessed', fullDateWitnessed);
                arrayWitnessed.push(<LogBookComponent stepName= 'ACD Witnessed' onPress= {() => this.handleDownloadWitnessed(q.url)} doctorName = {"By " + this.state.userName} date = {fullDateWitnessed}/>)


              })
              this.setState({witnessedAcdLogBook:arrayWitnessed})

            }else{
              this.setState({witnessedAcdLogBook: <LogBookComponent stepName= 'ACD Witnessed'  doctorName = {"No Record Found"}/>})

              // this.setState({pdfWitnessed:response.data.acdWitnessed.url})
              // let lastDateWitnessed = moment(response.data.acdWitnessed.submittedOn).format('D');
              // // var j = i % 10,
              // // k = i % 100;
              // if (lastDateWitnessed == 1 && lastDateWitnessed != 11) {
              //   lastDateWitnessed = lastDateWitnessed + "st";
              // }
              // if (lastDateWitnessed == 2 && lastDateWitnessed != 12) {
              //   lastDateWitnessed = lastDateWitnessed + "nd";
              // }
              // if (lastDateWitnessed == 3 && lastDateWitnessed != 13) {
              //   lastDateWitnessed = lastDateWitnessed + "rd";
              // }else{
              //   lastDateWitnessed = lastDateWitnessed + "th";
              // }
              // this.setState({lastDateWitnessed:lastDateWitnessed})
              // let fullDateWitnessed = lastDateWitnessed + ' of ' + moment(response.data.acdWitnessed.submittedOn).format('MMMM YYYY')
              // this.setState({dateWitnessed:fullDateWitnessed})
              // this.setState({AcdGenerated:true})

            }

            // global.userData.userData.acd.isComplete = true

            // WebBrowser.openBrowserAsync(response.data.pdf)


          }else{
            console.log(response.data.message);
          }
        })
        .catch((error)=>{
          console.log("error", error);
        })
      }else{
        this.setState({downloading:false})
        this.setState({ActivityProcessing: false})
        this.setState({createdAcdLogBook:<Text style={{marginLeft:20,color:'#7F7F7F', fontSize:25, fontWeight:'700', marginBottom:10}}>No Record Found</Text>})


      }

    }
  });

}

getVerifiedLogBook = async (e) =>{
  this.token = await AsyncStorage.getItem('@token');

  console.log('getVerifiedLogBook');
  NetInfo.getConnectionInfo().then(connectionInfo => {
    console.log(
      'Initial, type: ' + connectionInfo.type + ', effectiveType: ' + connectionInfo.effectiveType
    );

    if (connectionInfo.type == "none") {
      alert("Your need internet access to get updated LogBook")
      // global.url = 'https://palcare-backend-dev.herokuapp.com'
      // this.getLocalData()
      console.log("OS :: ", Platform.OS);
      this.setState({downloading:false})
    }
    else {

      this.setState({downloading: true})
      let optionsAxios = ''
      let user = JSON.parse(this.user)

      optionsAxios = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' , 'Authorization': JSON.parse(this.token)},
        url: global.url+'/getVerifiedAcdPdf/' + this.jsonUserMain._id,
      }

      console.log(" getVerifiedLogBook Options axios ", optionsAxios );
      axios(optionsAxios)
      .then((response)=> {
        this.setState({downloading:false})

        console.log("/getVerifiedLogBook Acd Response recieved ", response);
        if(response.data.success){

          // if(response.data.acdPdfWitnessed.length > 0 ){
          //   this.setState({AcdGenerated:true})
          // }
          this.setState({ActivityProcessing: false})
          response.data.result.acdPdfWitnessed.map((x,idx,z) =>{
            if(idx == z.length-1){
              this.setState({pdfWitnessed:x.url})
              console.log("pdf is ", x);
              console.log("date", moment(x.submittedOn).format('DD,MM,YYYY'));
              let lastDate = moment(x.submittedOn).format('D');
              // var j = i % 10,
              // k = i % 100;
              if (lastDate == 1 && lastDate != 11) {
                lastDate = lastDate + "st";
              }
              if (lastDate == 2 && lastDate != 12) {
                lastDate = lastDate + "nd";
              }
              if (lastDate == 3 && lastDate != 13) {
                lastDate = lastDate + "rd";
              }else{
                lastDate = lastDate + "th";
              }
              this.setState({lastDate:lastDate})
              let fullDate = lastDate + ' of ' + moment(x.createdOn).format('MMMM YYYY')
              this.setState({dateWitnessed:fullDate})

            }

          })

        }else{
          console.log(response.data.message);
        }
      })
      .catch((error)=>{
        console.log("error", error);
      })
    }
  });

}



handleHome = (e) =>{
  // this.props.navigation.navigate('AcdHome')
  this.props.navigation.popToTop()

}
handleAcdOverview = (e) =>{
  console.log("overview clicked ");
  this.props.navigation.navigate('ACDOverview18')
}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

handleDownloadWitnessed = (pdf)=>{
  console.log("downloading ", FileSystem.documentDirectory);

  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync(pdf)

  }
  else {

    console.log('in else download');
    WebBrowser.openBrowserAsync(pdf)

  }

}

// handleDownload=(e, pdf)=>{
//   console.log('in handledownload', e);
//   this.props.navigation.navigate(e, {pdf:pdf})
//
// }
handleDownload=(e, pdf)=>{
  console.log("downloading ", FileSystem.documentDirectory);

  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync(pdf)

  }
  else {
    console.log('in else download');
    WebBrowser.openBrowserAsync(pdf)

  }

}

routeTo = (e) =>{
  console.log('ResourcesStart');
  this.props.navigation.navigate('ResourcesStart')
}

render() {
  console.log("props :: ", this.props);
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'70%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
          <NavigationEvents
            onDidFocus={() => this.onWillFocus()}
            onWillBlur={payload => console.log('will blur', payload)}
            onDidBlur={payload => console.log('did blur', payload)}
            />

          <TouchableOpacity style={{width:'100%'}} >
            {this.state.UserComponent}
          </TouchableOpacity>

          {
            this.state.PdfResource
            ?
            <ActivityIndicator size="small" color="#9126fb" />
            :
            <TouchableOpacity style={{width:'100%'}} >
              {this.state.resourcePdf}
            </TouchableOpacity>

          }

        </View>
      </ScrollView>

      <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[{width:"100%", alignItems:'center'}]:[{maxWidth:800, minWidth:800, alignItems:'center'}]}>
        <CustomButton
          title = "Add new resource"
          color = "white"
          fontWeight = "bold"
          navigation= {this.props.navigation}
          onPress = {this.routeTo}
          type="multiples" />
      </View>

      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'30%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={[global.bottom1, {width:'100%', alignItems:'center', justifyContent:'center', marginBottom: 25}]}>
        <CustomButton title="Home" fontWeight = "bold" type="multiples" color='#F9F1FF' navigation= {this.props.navigation} onPress = {this.handleHome}/>

      </ScrollView>

    </View>
  );
}
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
  },

  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },

  textstyle:{
    // alignItems:'center',
    // justifyContent:'center',
    color:'#1D2195',
    fontSize:15,
    textAlign:'center'
  },
  congratulationsweb:{
    width:'75%',
    height:500,
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
  congratulations:{
    width:'75%',
    height:'77%',
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
})
