import React, { Component } from 'react';
import { View,Text,StyleSheet, Image,AsyncStorage, ImageBackground} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

export default class BackComponent extends Component {

  handleBackProvider=()=>{
    if (this.props.comingFrom === "providerHome") {
      this.props.navigation.navigate("MainScreenProvider")
    }else{
      this.props.navigation.goBack()
    }
    // if (this.props.comingFrom) {
    //   if (this.props.comingFrom == "SignedUpScreen") {
    //     AsyncStorage.clear()
    //     this.props.navigation.navigate("Login")
    //   }else{
    //     console.log('in go back');
    //     this.props.navigation.goBack()
    //   }
    // }
    // else {
    //   this.props.navigation.goBack()
    // }
  }
  render() {
    return (
      <View style={{display:'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'center'}}>

        <TouchableOpacity style={{width:'120%', height:'100%', alignItems:'center', justifyContent:'center', paddingLeft:10}} onPress={()=>this.handleBackProvider()}>
          <ImageBackground source={require('../../assets/Backwardarrow.png')} style={{width: 25, height: 15}}/>
        </TouchableOpacity>
        {this.props.circleValue !=null?
          <View style= {{ height: 40, marginLeft:5, justifyContent:"center", alignItems:'center'}}>
            <View style= {{width:30, height:30,alignItems:'center', justifyContent:'center' ,marginRight:5,borderRadius:100,backgroundColor:'#9126FB'}}>
              <Text style= {{textAlign:'center', color:'white', fontWeight:'700',fontSize:18}}>{this.props.circleValue}</Text>
            </View>
          </View>
          :null
        }
      </View>
    );
  }
}
