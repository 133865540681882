import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity, Dimensions } from 'react-native';
import global from "../../Styles/global";
import UserComponent from '../../Components/UserComponent'
import {Button, Icon} from 'react-native-elements'
import CustomButton from '../../Components/CustomButton';
import { NavigationEvents } from 'react-navigation';
import UserComponentProvider from '../../Components/UserComponentProvider'


export default class AnticipatoryStartProvider extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,

      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
  }
  // global.userData = require('../../../Data/northern_acd.json')
  global.jsonDatatest.forEach((i,idx,x)=>{
    if (i.title == 'Anticipatory prescribing tool') {
      this.jsonData = i
    }
  })
  this.getLocalKeys()
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);


    this.setState({userName:data.name})
    this.setState({userAddress:data.address})
    console.log('====================================');
    console.log("Data::::::", data);
    console.log('====================================');
    // this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    this.setState({UserComponent:<UserComponentProvider createdOn={this.props.navigation.state.params.allData.patientsWithAcds[0].acdPdfCreated} isComplete={this.props.navigation.state.params.allData.patientsWithAcds[0].userData.acd.isComplete} name={this.props.navigation.state.params.allData.name} address={this.props.navigation.state.params.allData.address} profileImg = {this.props.navigation.state.params.allData.profileImg}/>})

    console.log('====================================');
    console.log("Data:::::: after setstate", data);
    console.log('====================================');

  }
}
onWillFocus = ()=>{
  this.getLocalKeys()
}


handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}

handleacd= () =>{
}

handleNext=()=>{
  this.props.navigation.navigate('AnticipatoryPdf1Provider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})
}

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

render() {
  return (

    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'80%',width:'100%'}]:[{height:'80%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
          <NavigationEvents
            onWillFocus={() => this.onWillFocus()}
            onDidFocus={payload => console.log('did focus', payload)}
            onWillBlur={payload => console.log('will blur', payload)}
            onDidBlur={payload => console.log('did blur', payload)}
            />

          <View style = {global.mainInsideContainer}>
            <TouchableOpacity style={{width:'100%'}}>
              {this.state.UserComponent}
            </TouchableOpacity>

            <View style={{width:'90%', paddingTop:5,paddingBottom:10, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>

              <Text style={styles.descriptionText}>
                {this.jsonData.description[0]}
              </Text>
              <Text style={[styles.descriptionText,{fontWeight:'bold',marginTop:10}]}>
                {this.jsonData.instructions[0]}
              </Text>

              <Text style={styles.descriptionText}>
                {this.jsonData.description[1]}
              </Text>
              <Text style={[styles.descriptionText,{fontWeight:'bold',marginTop:10}]}>
                {this.jsonData.instructions[1]}
              </Text>

              <Text style={styles.descriptionText}>
                {this.jsonData.description[2]}
              </Text>


            </View>
          </View>
        </View>
      </ScrollView>

      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'20%', width:'100%'}]:[{height:'20%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={[global.bottom, {width:'100%', alignItems:'center', justifyContent:'center'}]}>

        <CustomButton title = "➔" navigation = {this.props.navigation} onPress= {this.handleNext}/>
      </ScrollView>

    </View>
  );
}
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
    //     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    //     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },
  textSmall:{
    width:'100%',
    alignItems:'flex-start',
    marginTop:50,
    color:'#1D2195',
  },
  textBold:{
    width:'100%',
    alignItems:'flex-start',
    marginTop:50,
    color:'#1D2195',
    fontWeight:'bold'
  },
  descriptionText:{
   marginBottom:10,
   // fontWeight:'bold',
   width:'90%',
   marginLeft:10,
   marginRight:10,
   textAlign:'left',
   color:'#1D2195',
   lineHeight: 20,

 },

})
