import {StyleSheet} from 'react-native';
const global = StyleSheet.create({
  container:{
      display:"flex",
      width:'100%',
      alignItems: 'center',
      justifyContent: 'center',
      // paddingTop:50,
      height:'100%'

  },

  ItemsContainerMobileSignedUp:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  },
  ItemsContainerWebSignedUp:{
    display:'flex',
    width: '35%',
    alignItems:'center',
    // justifyContent:'center',
    minWidth:800,
    maxWidth:800,
    // minHeight:450,
    // maxHeight:800,
  },

  scrollContainer: {
    width:'100%',
    height: '100%'
  },

  ItemsContainerMobile:{
    display:'flex',
    width: '100%',
    height:'100%',
    alignItems:'center',
    justifyContent:'center',

  },
  ItemsContainerWeb:{
    display:'flex',
    width: '35%',
    height:'100%',
    alignItems:'center',
    // justifyContent:'center',
    minWidth:800,
    maxWidth:800,
    minHeight:450,
    // maxHeight:800,
  },
  mainInsideContainer:{
    display:'flex',
    width:'100%',
    // height:800,
    alignItems:'center',
    justifyContent:'flex-start'
},
textBold:{
  width:'100%',
  alignItems:'flex-start',
  marginTop:50,
  color:'#1D2195',
  fontWeight:'bold'
},
bottom: {
  flex: 1,
  justifyContent: 'flex-end',
  marginBottom: 25
},
bottom1: {
  flex: 1,
  justifyContent: 'flex-end',
  marginBottom: 80
}
})

export default global
