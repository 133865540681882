import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TextInput, ScrollView, Alert, TouchableOpacity, KeyboardAvoidingView, ActivityIndicator, Picker } from 'react-native';
import FooterText from '../../Components/FooterText'
import CustomButton from '../../Components/CustomButton';
import axios from 'axios'
import DatePicker from 'react-native-datepicker'
import { Constants, Calendar } from 'expo';
import { AsyncStorage } from 'react-native';
import ValidationComponent from 'react-native-form-validator';
import global from "../../Styles/global.js"
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Dropdown } from 'react-native-material-dropdown';
import { Appearance, useColorScheme } from 'react-native-appearance';

Appearance.getColorScheme();

const subscription = Appearance.addChangeListener(({ colorScheme }) => {
  // do something with color scheme
  console.log('colorScheme', colorScheme);
});

export default class SignUpScreen extends Component {
  constructor(){
    super()
    this.state= {
      name: '',
      email: '',
      role: '',
      address: '',
      birthday:'',
      password:'',
      confirmPassword:'',
      organisation:'',
      gp:'',
      dropDownState :false,
      isLoading: false,
      territory: '',
      allTerritories: [
        {
            "_id": "5dbbd498dd07ce301d623bfc",
            "name": "New South Wales",
            "abbreviation": "NSW"
        },
        {
            "_id": "5dbbd498dd07ce301d623bfd",
            "name": "Victoria",
            "abbreviation": "VIC"
        },
        {
            "_id": "5dbbd498dd07ce301d623bfe",
            "name": "Queensland",
            "abbreviation": "QLD"
        },
        {
            "_id": "5dbbd498dd07ce301d623bff",
            "name": "Tasmania",
            "abbreviation": "TAS"
        },
        {
            "_id": "5dbbd498dd07ce301d623c00",
            "name": "South Australia",
            "abbreviation": "SA"
        },
        {
            "_id": "5dbbd498dd07ce301d623c01",
            "name": "Western Australia",
            "abbreviation": "WA"
        },
        {
            "_id": "5dbbd498dd07ce301d623c02",
            "name": "Northern Territory",
            "abbreviation": "NT"
        },
        {
            "_id": "5dbbd498dd07ce301d623c03",
            "name": "Australian Capital Territory",
            "abbreviation": "ACT"
        }
]
    }

     this.maxDate = new Date()
  }

  componentDidMount(){
    console.log("title", this.props.navigation.state.params);

    this.setState({
      role: this.props.navigation.state.params
    })
    this.getEnums()
    const subscription23 = Appearance.addChangeListener(({ colorScheme }) => {
      // do something with color scheme
      console.log('colorScheme', colorScheme);
    });

    console.log('subscription23', subscription23)
  }

  handleDropDown =()=>{
    this.setState({
      dropDownState: !this.state.dropDownState
    })
  }
  handleDropDownValue = (e) =>{

    this.setState({territory: e.name, territoryMain: e._id, dropDownState: !this.state.dropDownState})


  }

  getEnums = async ()=>{
    const value = await AsyncStorage.getItem('@allTerritories');
    let parsedTerrit = ''
    if(value !== '' && value !== undefined && value !== null){
      parsedTerrit = JSON.parse(value)
    }else{
      parsedTerrit = this.state.allTerritories
      AsyncStorage.setItem('@allTerritories', this.state.allTerritories);
    }
    let dropdowns = []
    parsedTerrit.map(x =>{
      if(x.abbreviation === 'SA' || x.abbreviation === 'NT'){

      let array =

      <TouchableOpacity onPress={() => this.handleDropDownValue(x)} style={{width:'100%', marginLeft: 10,  alignItems: 'center', justifyContent: 'center'}}>
      <View style={{width:"100%", alignItems: 'center',height:30, backgroundColor:'#fff',justifyContent: 'center'}}>
      <View style={{width:'90%'}}>
      <Text style={styles.descriptionText}>{x.name}</Text>
      </View>
      </View>
      </TouchableOpacity>

      dropdowns.push(array)
    }
    })

    this.setState({allTerritoriesDropdown: dropdowns})

  }


  handleSignUp = () => {
    console.log("signup data :: ", this.state);
    console.log("signup data :: ", this.state.territory);

    console.log(this.state.name);

      const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g

      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (this.props.navigation.state.params == "Provider") {
        if(this.state.organisation == undefined || this.state.organisation == ''){
          alert("Organisation is required")
          return 0 ;

        }
      }
      else {
        if (this.state.gp == undefined || this.state.gp == '') {
          if( this.props.navigation.state.params !== "Provider"){
            alert("GP is required")
            return 0 ;
          }

        }
      }

      if(this.state.birthday && this.props.navigation.state.params !== "Provider"){
        console.log('this.state.birthday', this.state.birthday);

        const broken= this.state.birthday.split('/');
        if(broken[0] && broken[1] && broken[2]){
          if(broken[2].length == 4 && broken[0].length == 2 && broken[1].length == 2 && broken[0] < 32 && broken[1] < 13){
            console.log('Birthday ok DD/MM/YYYY');
          }else{
            alert("Birthday should be in format DD/MM/YYYY")
            return 0 ;
          }
        }else{
          console.log('Birthday should be in format DD/MM/YYYY');
          alert("Birthday should be in format DD/MM/YYYY")
          return 0 ;

        }
        console.log('broken', broken);
      }else if(this.props.navigation.state.params !== "Provider"){
        alert("Birthday Is Missing")
        return 0 ;
      }

    if (!nameRegex.test(this.state.name.trimEnd())) {
      if(this.state.name == undefined || this.state.name == ''){
        alert("Name is required")
        return 0 ;

      }
      else{
        alert("Invalid Name")
        return 0 ;

      }
      // alert("Name is required");
    }
    else  if (!emailRegex.test(this.state.email )) {
    if (this.state.email == undefined || this.state.email == '') {
      alert("Email is required");
      return 0 ;

    }
    else{
      alert("Invalid Email");
      return 0 ;

    }
  }
  else if (this.state.territoryMain == undefined || this.state.territoryMain == '') {
    alert("territory is required");
    return 0 ;

  }
    else if (this.state.address == undefined || this.state.address == '') {
      alert("Address is required");
      return 0 ;

    } else if (this.state.phone == undefined || this.state.phone == '') {
          alert("Phone is required");
          return 0 ;

        }
    else if (this.state.password.length < 6){
    if (this.state.password == undefined || this.state.password == '') {

      alert("Password is required");
      return 0 ;

    }
    else{
      alert("Password must be greater than 5 characters");
      return 0 ;

    }
  }
    else if (this.state.password !== this.state.confirmPassword) {
      alert("Password did not match");
      return 0 ;

    }
    else {
      this.setState({isLoading:true})
      if ( this.props.navigation.state.params == "Citizen/patient") {
        var options = {
          "email": this.state.email.toLowerCase(),
          "password": this.state.password,
          "name":this.state.name.trimEnd(),
          "address":this.state.address,
          "dateOfBirth":this.state.birthday,
          "gp":this.state.gp,
          "phone":this.state.phone,
          'territory':this.state.territoryMain,
          "userType":"patient",
        }
      }
      else if ( this.props.navigation.state.params == "Provider"){
        var options = {
          "email": this.state.email.toLowerCase(),
          "password": this.state.password,
          "name":this.state.name.trimEnd(),
          "address":this.state.address,
          "organization":this.state.organisation,
          "phone":this.state.phone,
          'territory':this.state.territoryMain,
          "userType":"care-provider",
        }

      }

      var optionsAxios = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        url: global.url+'/register',
        data:  options
      }
      console.log("axios options :: ", optionsAxios);
      axios(optionsAxios)
      .then((response)=> {
        this.setState({isLoading:false})
        console.log("response axios", response);
        if (response.status == 200) {
          console.log('in 200');
          AsyncStorage.setItem("@UserData", JSON.stringify(response.data.result))
          if (response.data.result.accountStatus === "pending") {
            console.log('navigating to SignedUp from SignedUpScreen');
            this.props.navigation.navigate('SignedUp', {comingFrom:"signup", role:this.props.navigation.state.params, data:response.data.result});
          }
          else {
            console.log('navigating to MainScreenProvider from SignedUpScreen');
            this.props.navigation.navigate('MainScreenProvider', {comingFrom:"signup", role:this.props.navigation.state.params, data:response.data.result});
          }
        }
      })
      .catch((err)=>{
        this.setState({isLoading:false})
        console.log("error register :: ", err.response);
        alert(err.response.data.message)
      })
    }
  }


  // uploadAcdImages = async ()=>{
  //
  //
  //     console.log("Parsed images :: ", uri);
  //     console.log("Fetch Options :: ", global.url+ '/api/uploadAcdImg/' + acdId);
  //
  //     fetch(global.url+ '/api/uploadAcdImg/' + acdId,
  //     {
  //       method: 'POST',
  //       headers: {'cache-control': 'no-cache', 'Content-type': 'multipart/form-data' } ,
  //       body: bodyData,
  //     }
  //   )
  //     .then((response)=> {
  //
  //     console.log('###responce picture post',response);
  //     if (response.status == 200) {
  //       if(this.count < this.uri.length){
  //         this.ActionSheet.show()
  //       }else{
  //         this.getNewUserAcd()
  //       }
  //     }
  //   })
  //   .catch(err => console.log('###error api', err))
  //
  // }


  editName =()=>{
    console.log('in edit name');
    // document.getElementById(this.fullName).autofocus = true;
  }

  render() {
    console.log('terrr', this.state.allTerritories);
    return (
      <View style = {global.cotainer}>
        <KeyboardAwareScrollView
          enableOnAndroid
          >
        <ScrollView style={{width:'100%'}} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>

          <View style= {Platform.OS === 'ios' || Platform.OS ==='android'? global.ItemsContainerMobile:global.ItemsContainerWeb}>

          {this.props.navigation.state.params == "Provider"
            ?<Text style= {{color: '#1D2195',width:'100%', textAlign: 'left', paddingLeft:22,marginBottom:25,marginTop:25, fontSize:20,fontWeight: 'bold'}}>Create a Provider account</Text>
            :<Text style= {{color: '#1D2195',width:'100%', textAlign: 'left', paddingLeft:22,marginBottom:25,marginTop:25, fontSize:20,fontWeight: 'bold'}}>Create an Account</Text>
          }
            <View style = {styles.inputField}>

              <TextInput
                ref={(ip) => this.fullName = ip}
                editable
                style={styles.textInputStyle}
                onChangeText={name => this.setState({ name })}
                value={this.state.name}
                name="name"
                />
              <Text style= {styles.textstyle} onPress={() => {this.fullName.focus()}} >Full Name</Text>
            </View>
            <View style = {styles.inputField}>

              <TextInput
                editable
                ref={(ip) => this.email = ip}
                style={styles.textInputStyle}
                onChangeText={email => this.setState({ email })}
                value={this.state.email}
                keyboardType = "email-address"
                />
              <Text style= {styles.textstyle} onPress={() => {this.email.focus()}}>Email</Text>
            </View>
            <View style = {styles.inputField}>

              <TextInput
                editable = {false}
                ref={(ip) => this.role = ip}
                style={[styles.textInputStyle, {color:"gray"}]}
                onChangeText={role => this.setState({ role })}
                value={this.state.role}
                />
              <Text style= {styles.textstyle}>Role</Text>
            </View>


            <View style = {[styles.inputField,{ alignItems:'flex-start'}]} >
              {
                (this.state.territory)
                ?
                <Text
                  style= {this.state.territory ?[styles.textInputStyle2, {width:'95%', textAlignVertical: "top", marginTop:15}]:[styles.textInputStyle,{width:'70%', textAlignVertical: "top", marginTop:15}]}
                  onPress={this.handleDropDown}
                  >
                  {this.state.territory}
                </Text>
                :
                <Text
                  editable = "false"
                  style= {this.state.territory ?[styles.textInputStyle2, {width:'95%', textAlignVertical: "top", marginTop:20}]:[styles.textInputStyle2,{width:'70%', textAlignVertical: "top", marginTop:15, color:'grey'}]}
                  onPress={this.handleDropDown}
                  >
                  Territory
                </Text>
              }

                  <Text onPress={this.handleDropDown} style= {[styles.textstyle,{width:'5%', marginTop:15}]}>▼</Text>
              </View>

            {this.state.dropDownState == false ? null
            :
            <View style = {{ display:'flex', flexDirection:'column',width:'100%', }}>

              {this.state.allTerritoriesDropdown}

            </View>
            }

            <View style = {styles.inputField}>

              <TextInput
                ref={(ip) => this.address = ip}

                style={styles.textInputStyle}
                onChangeText={address => this.setState({ address })}
                value={this.state.address}
                />
              <Text style= {styles.textstyle} onPress={() => {this.address.focus()}}>Address</Text>
            </View>


            {
              this.props.navigation.state.params == "Provider"
              ?null
              :<TouchableOpacity style = {styles.inputField}>
              {Platform.OS !== 'web'
              ?<DatePicker
              placeholder=' '

                maxDate = {this.maxDate}
                date={this.state.birthday}
                placeholder= " "
                showIcon={false}
                style={styles.inputFieldBirthday}
                customStyles={{
                  dateInput: {
                    position: 'absolute',
                    left: 0,
                    marginLeft: 0,
                    borderWidth:0,
                    borderColor:"transparent",
                  }
                }}
                mode="date"
                format="DD/MM/YYYY"
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                onDateChange={(birthday) => {this.setState({birthday: birthday})}}
                ref={(ip) => this.birthday = ip}
                />

              :<TextInput
                editable
                ref={(ip) => this.birthday = ip}
                style={styles.textInputStyle}
                placeholder="DD/MM/YYYY"
                onChangeText={birthday => this.setState({ birthday })}
                value={this.state.birthday}
                />}
                {Platform.OS !== 'web'
                  ?
                  <Text style= {styles.textstyle}>Birthday</Text>

                  :
                  <Text style= {styles.textstyle} onPress={() => {this.birthday.focus()}}>Birthday</Text>

                }
              </TouchableOpacity>
            }


            {
              this.props.navigation.state.params == "Provider"
              ?<View style = {styles.inputField}>

                <TextInput
                  editable
                  ref={(ip) => this.organisation = ip}
                  style={styles.textInputStyle}
                  onChangeText={organisation => this.setState({ organisation })}
                  value={this.state.organisation}
                  />
                <Text style= {styles.textstyle} onPress={() => {this.organisation.focus()}}>Organisation</Text>
              </View>
              :<View style = {styles.inputField}>

                <TextInput
                  editable
                  ref={(ip) => this.general = ip}
                  style={styles.textInputStyle}
                  onChangeText={gp => this.setState({ gp })}
                  value={this.state.gp}
                  />
                <Text style= {styles.textstyle} onPress={() => {this.general.focus()}}>GP</Text>
              </View>
            }
            <View style = {styles.inputField}>

              <TextInput
                editable
                style={styles.textInputStyle}
                ref={(ip) => this.phone = ip}
                keyboardType = "phone-pad"
                onChangeText={phone => this.setState({ phone })}
                value={this.state.phone}
                />
              <Text style= {styles.textstyle} onPress={() => {this.phone.focus()}}>Phone</Text>

            </View>


              <View style = {styles.inputField}>

                <TextInput
                  editable
                  ref={(ip) => this.password = ip}
                  style={styles.textInputStyle}
                  secureTextEntry={true}
                  onChangeText={password => this.setState({ password })}
                  value={this.state.password}
                  />
                <Text style= {styles.textstyle} onPress={() => {this.password.focus()}}>Password</Text>
              </View>
              <View style = {styles.inputField}>

                <TextInput
                  editable
                  ref={(ip) => this.renamePass = ip}
                  style={styles.textInputStyleConfirmPass}
                  secureTextEntry={true}
                  onChangeText={confirmPassword => this.setState({ confirmPassword })}
                  value={this.state.confirmPassword}
                  />
                <Text style= {styles.textstyle} onPress={() => {this.renamePass.focus()}}>Repeat Password</Text>
              </View>

              <FooterText/>

                <View style={{
                marginBottom:30,
                width:"100%",
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
                }}>

              </View>
              {
                this.state.isLoading == true
                ?<View style={{marginTop:50,
                  marginBottom:10,
                  width:"100%",
                  height:48,
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center"}}>
                    <ActivityIndicator size="large" color="#9126FB" />
                  </View>
                :<CustomButton title = "➔" type="createAccount" navigation = {this.props.navigation} onPress= {this.handleSignUp}/>
              }
            </View>
          </ScrollView>
        </KeyboardAwareScrollView>
        </View>


      );
    }
  }


  const styles = StyleSheet.create({

    ItemsContainerMobile:{
      width: '100%',
      height:'100%',
      alignItems:'center',
      // justifyContent:'center',
    },
    ItemsContainerWeb:{
      flex:1,
      width: '35%',
      height:'100%',
      alignItems:'center',
      justifyContent:'center',
    },
    inputField:{
      width:'90%',
      flexDirection:'row',
      height:50,
      marginTop:16,
      alignItems:'center',
      paddingLeft:10,
      paddingRight:10,
      justifyContent:'space-between',
      borderWidth:.5,
      borderColor:'#1D2195'

    },

    inputFieldPicker:{
      width:'90%',
      flexDirection:'row',
      height:50,
      marginTop:16,
      alignItems:'center',
      paddingLeft:10,
      paddingRight:10,
      justifyContent:'space-between',
      borderWidth:.5,
      borderColor:'#1D2195'

    },

    textInputStyle:{
      height: 40,
      width:'78%',
      alignSelf:'flex-end',
      // outline:'none',
      borderColor:'transparent',
    },
    textInputStyleConfirmPass:{
      height: 40,
      width:'65%',
      alignSelf:'flex-end',
      // outline:'none',
      borderColor:'transparent',
    },

    textstyle:{
      alignItems:'center',
      justifyContent:'center',
      color:'#1D2195',
      fontSize:12,
      textAlign:'center'
    },
    dropIcon:{
      color:'#1D2195',
      fontSize:12,
      textAlign:'left'
    },
    inputFieldBirthday:{
      width:'85%',
      flexDirection:'row',
      height:50,
      alignItems:'center',
      marginBottom:8,
      justifyContent:'space-between',
    },

  })
