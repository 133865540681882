import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Dimensions,
  Alert
} from 'react-native';
import {  Button, CheckBox, Avatar } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
export default class ACDoverview5 extends Component {
  constructor(props) {
    super(props);

    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,
    capturedImage:this.props.navigation.state.params.image,
    image:"https://res.cloudinary.com/hqepnl8tg/image/upload/v1571642768/cam_pnlffd.png"
  }

  this.getLocalKeys()
}

componentDidMount() {
  this.getPermissionAsync();
}

getPermissionAsync = async () => {
  if (Constants.platform.ios) {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    if (status !== 'granted') {
      alert('Sorry, we need camera roll permissions to make this work!');
    }
  }
}

getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})

      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}


ACDfilledout = () =>{
  console.log("ACDfilledout");
}

handleNext = () => {
  this.props.navigation.navigate("ACDoverview17", {image:this.state.capturedImage, resource:this.props.navigation.state.params.resource})
}

pickImage = async () => {
  console.log("Alert");
  if (Platform.OS !== 'web') {
    Alert.alert(
      'Alert',
      'Select option to upload file',
      [
        { text: 'Cancel', onPress: () => console.log('Cancel Pressed') },

        {
          text: 'Upload from Gallery',
          onPress: () => this.uploadFromGallery(),
          style: 'cancel',
        },
        { text: 'Open Camera', onPress: () => this.uploadFromCamera() },
      ],
      { cancelable: true }
    );
  }
  else {
    this.uploadFromGallery()
  }
}

uploadFromGallery = async () => {
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    // aspect: [4, 3],
    base64: false

  });


  console.log(result);


  if (!result.cancelled) {
    this.setState({ capturedImage:result.uri });
  }
}

uploadFromCamera = async () => {
  let result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    // aspect: [4, 3],
    base64: false

  });


  console.log(result);


  if (!result.cancelled) {
    this.setState({ capturedImage:result.uri });

  }
}


componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}

render() {

  // console.log("Image uri : ", this.state.capturedImage);

  var imageUri = 'file:///Users/sofit/Library/Developer/CoreSimulator/Devices/348CA465-0FBC-4A72-A6BA-6D2543F01034/data/Containers/Data/Application/47EC2CFD-BE22-487D-8E91-083F7A889BDC/Library/Caches/ExponentExperienceData/%2540haseemulhaq%252FPalCare/ImagePicker/AE5A8622-5FF6-452C-8D07-87F0A37DEE2A.jpg'

  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          {this.state.UserComponent}

          <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontSize:14, color:"#1D2195", width:"90%", marginBottom:30, textAlign:"center"}}>
              Take a picture of each page
            </Text>

            <View style={{width:"80%", marginBottom:10}}>
            <ImageBackground source={{uri : this.state.capturedImage}} style={{width:"100%", height:300, backgroundColor:"gray"}}/>
              <Text style={{fontSize:12, color:"#1D2195", width:"100%", textAlign:"center"}}>
                Retake
              </Text>
            </View>

            <TouchableOpacity onPress={() => this.pickImage()}>
              <ImageBackground
                source={{uri: this.state.image}}
                style={{height:40, width:50}}/>
            </TouchableOpacity>

          </View>
        </View>

      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={[global.bottom, {width:'100%', alignItems:'center', justifyContent:'center'}]}>

        <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleNext}/>
      </ScrollView>
    </View>
  );

}
}

const styles = StyleSheet.create({
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',

  },
  loginButton:{
    borderRadius:5,

  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    color:"#707070",
    fontSize:14,
    fontWeight:"bold",
    width:"90%"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  }

})
