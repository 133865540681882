import React, {Component} from 'react';
import LoginScreen from '../UserManagement/Login/LoginScreen';
import ForgotPassword from '../UserManagement/Login/ForgotPassword';
import SignUpScreen from '../UserManagement/SignUp/SignUpScreen';
import UploadImage from '../Home/AcdScreens//UploadImage';
import CreateAccountScreen from '../UserManagement/SignUp/CreateAccountScreen';
import CustomHeader from '../Components/CustomHeader'
import SignedUpScreen from '../UserManagement/SignUp/SignedUpScreen';
import MainScreen from '../MainScreen/MainScreen';
import MainScreenProvider from '../MainScreenProvider/MainScreenProvider'

import AcdHome from '../Home/AcdScreens/AcdHome'

import AcdHomeProvider from '../Home/AcdScreens/AcdHomeProvider'
import AcdSignUp from '../Home/AcdScreens/AcdSignUp'
import AcdOverview from '../Home/AcdScreens/AcdOverview'
import HeaderRight from '../Components/HeaderRight';
import ACDHeader from '../Components/ACDHeader'
import BackComponent from '../Components/BackComponent'
import BackComponentProvider from '../Components/BackComponentProvider'

import PersonalDetail from '../Home/AcdScreens/PersonalDetail'
import SubstituteDecisionMaker1 from '../Home/AcdScreens/SubstituteDecisionMaker1'
import SubstituteDecisionMaker2 from '../Home/AcdScreens/SubstituteDecisionMaker2'
import SubstituteDecisionMaker3 from '../Home/AcdScreens/SubstituteDecisionMaker3'
import SubstituteDecisionMaker4 from '../Home/AcdScreens/SubstituteDecisionMaker4'
import SubstituteDecisionMaker5 from '../Home/AcdScreens/SubstituteDecisionMaker5'
import AppointingSubstituteDecisionMaker from '../Home/AcdScreens/AppointingSubstituteDecisionMaker'
import ConditionsForAppointment from '../Home/AcdScreens/ConditionsForAppointment'
import ConditionsForAppointment1 from '../Home/AcdScreens/ConditionsForAppointment1'
import ValuesAndWishesScreen from '../Home/AcdScreens/ValuesAndWishesScreen'
import WhatIsImportantScreen from '../Home/AcdScreens/WhatIsImportantScreen'
import QuestionA from '../Home/AcdScreens/Questions/QuestionA'
import QuestionB from '../Home/AcdScreens/Questions/QuestionB'
import QuestionC from '../Home/AcdScreens/Questions/QuestionC'
import QuestionD from '../Home/AcdScreens/Questions/QuestionD'
import QuestionE from '../Home/AcdScreens/Questions/QuestionE'
import QuestionF from '../Home/AcdScreens/Questions/QuestionF'
import QuestionG from '../Home/AcdScreens/Questions/QuestionG'
import QuestionG1 from '../Home/AcdScreens/Questions/QuestionG1'
import QuestionRefusal from '../Home/AcdScreens/Questions/QuestionRefusal'
import QuestionG2 from '../Home/AcdScreens/Questions/QuestionG2'
import InterpreterStatement5 from '../Home/AcdScreens/InterpreterStatement5'
import InterpreterStatement43 from '../Home/AcdScreens/InterpreterStatement43'
import InterpreterStatement44 from '../Home/AcdScreens/InterpreterStatement44'
import InterpreterStatement52 from '../Home/AcdScreens/InterpreterStatement52'
import GivingAdvanceCare from '../Home/AcdScreens/GivingAdvanceCare'
import GivingAdvanceCare1 from '../Home/AcdScreens/GivingAdvanceCare1'
import WitnessScreen from '../Home/AcdScreens/WitnessScreen'
import WitnessScreen53 from '../Home/AcdScreens/WitnessScreen53'
import WitnessScreen54 from '../Home/AcdScreens/WitnessScreen54'

import AcdFinalReview from '../Home/AcdScreens/AcdFinalReview'
import PopOver5 from '../Home/AcdScreens/ACDOverview/PopOver5'
import PopOver7 from '../Home/AcdScreens/ACDOverview/PopOver7'
import PopOver8 from '../Home/AcdScreens/ACDOverview/PopOver8'
import ACDOverview8 from '../Home/AcdScreens/ACDOverview/ACDOverview8'
import ACDOverview9 from '../Home/AcdScreens/ACDOverview/ACDOverview9'
import ACDOverview10 from '../Home/AcdScreens/ACDOverview/ACDOverview10'
import ACDOverview11 from '../Home/AcdScreens/ACDOverview/ACDOverview11'
import ACDOverview18 from '../Home/AcdScreens/ACDOverview/ACDOverview18'
import LogBook from '../Home/AcdScreens/ACDOverview/LogBook'
import LogBookProvider from '../Home/AcdScreens/ACDOverview/LogBookProvider'
import HelpText from '../Home/AcdScreens/HelpText'

import InformationStatement from '../Home/AcdScreens/InformationStatement'
import ACDoverview2 from '../Home/AcdScreens/ACDOverview/ACDoverview2'
import ACDoverview3 from '../Home/AcdScreens/ACDOverview/ACDoverview3'
import ACDoverview4 from '../Home/AcdScreens/ACDOverview/ACDoverview4'
import ACDoverview5 from '../Home/AcdScreens/ACDOverview/ACDoverview5'
import ACDoverview6 from '../Home/AcdScreens/ACDOverview/ACDoverview6'
import ACDoverview7 from '../Home/AcdScreens/ACDOverview/ACDoverview7'

import FullListOfWitness from '../Home/AcdScreens/FullListOfWitness'
import CertifiedCopies from '../Home/AcdScreens/CertifiedCopies'

import SubstituteDecisionMakerPriority from '../Home/AcdScreens/SubstituteDecisionMakerPriority'

import AcdHomeNorthern from '../Home/AcdNorthern/AcdOverviewNorthern/AcdHomeNorthern'
import AcdSignUpNorthern from '../Home/AcdNorthern/AcdOverviewNorthern/AcdSignUp'
import AcdOverviewNorthern from '../Home/AcdNorthern/AcdOverviewNorthern/AcdOverview'
import AcdOverviewNorthernLast from '../Home/AcdNorthern/AcdOverviewNorthern/AcdOverviewLast'
import PersonalDetailNorthern from '../Home/AcdNorthern/AcdOverviewNorthern/PersonalDetail'
import AdvanceCareNoteNorthern from '../Home/AcdNorthern/AcdOverviewNorthern/AdvanceCareNote'
import AdvanceCareHomeNorthern from '../Home/AcdNorthern/AcdOverviewNorthern/AdvanceCareHome'
import AdvanceCare1 from '../Home/AcdNorthern/AcdOverviewNorthern/Question1'
import AdvanceCare2a from '../Home/AcdNorthern/AcdOverviewNorthern/Question2A'
import AdvanceCare2b from '../Home/AcdNorthern/AcdOverviewNorthern/Question2B'
import AdvanceCare2c from '../Home/AcdNorthern/AcdOverviewNorthern/Question2C'
import AdvanceCare3 from '../Home/AcdNorthern/AcdOverviewNorthern/Question3'
import AdvanceCare4 from '../Home/AcdNorthern/AcdOverviewNorthern/Question4'
import AdvanceCare5 from '../Home/AcdNorthern/AcdOverviewNorthern/Question5'
import AdvanceCare6 from '../Home/AcdNorthern/AcdOverviewNorthern/Question6'
import AdvanceConsentHomeNorthern from '../Home/AcdNorthern/AcdOverviewNorthern/AdvanceConsentHome'
import AdvanceConsentLastNorthern from '../Home/AcdNorthern/AcdOverviewNorthern/AdvanceConsentLast'
import AdvanceConsent1 from '../Home/AcdNorthern/AcdOverviewNorthern/AdvanceConsent1'
import AdvanceConsent2 from '../Home/AcdNorthern/AcdOverviewNorthern/AdvanceConsent2'
import AdvanceConsent3 from '../Home/AcdNorthern/AcdOverviewNorthern/AdvanceConsent3'
import AppointingSubstituteHomeNorthern from '../Home/AcdNorthern/AcdOverviewNorthern/AppointingSubstituteHome'
import AppointingSubstitute1 from '../Home/AcdNorthern/AcdOverviewNorthern/AppointingSubstitute1'
import AppointingSubstitute2 from '../Home/AcdNorthern/AcdOverviewNorthern/AppointingSubstitute2'
import AppointingSubstitute3 from '../Home/AcdNorthern/AcdOverviewNorthern/AppointingSubstitute3'
import AppointingSubstitutePreference from '../Home/AcdNorthern/AcdOverviewNorthern/AppointingSubstitutePreference'
import Witnessing from '../Home/AcdNorthern/AcdOverviewNorthern/Witnessing'
import WitnessingNoteNorthern from '../Home/AcdNorthern/AcdOverviewNorthern/WitnessingNote'
import ACDoverview2Northern from '../Home/AcdNorthern/AcdOverviewNorthern/ACDoverview2'
import ACDoverview3Northern from '../Home/AcdNorthern/AcdOverviewNorthern/ACDoverview3'
import ACDoverview4Northern from '../Home/AcdNorthern/AcdOverviewNorthern/ACDoverview4'
import ACDoverview5Northern from '../Home/AcdNorthern/AcdOverviewNorthern/ACDoverview5'
import ACDoverview6Northern from '../Home/AcdNorthern/AcdOverviewNorthern/ACDoverview6'
import PopOver5Northern from '../Home/AcdNorthern/AcdOverviewNorthern/PopOver5'
import ACDOverview18Northern from '../Home/AcdNorthern/AcdOverviewNorthern/ACDOverview18'
import ACDOverview8Northern from '../Home/AcdNorthern/AcdOverviewNorthern/ACDOverview8'

// React navigation imports
import { createAppContainer } from 'react-navigation'
import { createStackNavigator } from 'react-navigation-stack';
import {createSwitchNavigator } from 'react-navigation';

import SevenStart from '../Home/SevenStepPlan/SevenStart'
import SevenStart2 from '../Home/SevenStepPlan/SevenStart2'
import SevenPdf from '../Home/SevenStepPlan/SevenPdf'
import SevenPdf1 from '../Home/SevenStepPlan/SevenPdf1'
import SevenPdf2 from '../Home/SevenStepPlan/SevenPdf2'
import SevenPdf3 from '../Home/SevenStepPlan/SevenPdf3'
import SevenPdf4 from '../Home/SevenStepPlan/SevenPdf4'
import SevenPopOver from '../Home/SevenStepPlan/SevenPopOver'
import SevenLog from '../Home/SevenStepPlan/SevenLog'

import HealthStart from '../Home/HealthProvider/HealthStart'

import ResourcesProvider from '../Home/ResourceProvider/ResourcesProvider'
import ResourcesEmptyProvider from '../Home/ResourceProvider/ResourcesEmptyProvider'

import ResourcesLogProvider from '../Home/ResourceProvider/ResourceLog'

import SevenStartProvider from '../Home/SevenStepPlanProvider/SevenStart'
import SevenStart2Provider from '../Home/SevenStepPlanProvider/SevenStart2'
import SevenPdfProvider from '../Home/SevenStepPlanProvider/SevenPdf'
import SevenPdf1Provider from '../Home/SevenStepPlanProvider/SevenPdf1'
import SevenPdf2Provider from '../Home/SevenStepPlanProvider/SevenPdf2'
import SevenPdf3Provider from '../Home/SevenStepPlanProvider/SevenPdf3'
import SevenPdf4Provider from '../Home/SevenStepPlanProvider/SevenPdf4'
import SevenPopOverProvider from '../Home/SevenStepPlanProvider/SevenPopOver'
import SevenLogProvider from '../Home/SevenStepPlanProvider/SevenLog'

import ResourcesStart from '../Home/Resources/ACDoverview15'
import ACDoverview16 from '../Home/Resources/ACDoverview16'
import ACDoverview17 from '../Home/Resources/ACDoverview17'
import PopOver9 from '../Home/Resources/PopOver9'
import ResourceLog from '../Home/Resources/ResourceLog'
import ResourcePdf from '../Home/Resources/ResourcePdf'

import AnticipatoryStart from '../Home/Anticipatory/AnticipatoryStart'
import AnticipatoryPdf1 from '../Home/Anticipatory/AnticipatoryPdf1'
import AnticipatoryPdf2 from '../Home/Anticipatory/AnticipatoryPdf2'
import Anticipatory1 from '../Home/Anticipatory/Anticipatory1'
import Anticipatory2 from '../Home/Anticipatory/Anticipatory2'
import Anticipatory3 from '../Home/Anticipatory/Anticipatory3'
import Anticipatory4 from '../Home/Anticipatory/Anticipatory4'

import ContactsMainProvider from '../Home/Contacts/ContactsMainProvider'
import ContactsMain from '../Home/Contacts/ContactsMain'
import ContactsSingleProvider from '../Home/Contacts/ContactsSingleProvider'
import ContactsSingle from '../Home/Contacts/ContactsSingle'
import ContactsSingleEditable from '../Home/Contacts/ContactsSingleEditable'
import UploadImageContact from '../Home/Contacts/UploadImageContact'

import AnticipatoryStartProvider from '../Home/AnticipatoryProvider/AnticipatoryStart'
import AnticipatoryPdf1Provider from '../Home/AnticipatoryProvider/AnticipatoryPdf1'
import AnticipatoryPdf2Provider from '../Home/AnticipatoryProvider/AnticipatoryPdf2'
import Anticipatory1Provider from '../Home/AnticipatoryProvider/Anticipatory1'
import Anticipatory2Provider from '../Home/AnticipatoryProvider/Anticipatory2'
import Anticipatory3Provider from '../Home/AnticipatoryProvider/Anticipatory3'
import Anticipatory4Provider from '../Home/AnticipatoryProvider/Anticipatory4'

import {
  Platform,
} from 'react-native';

const AuthStack = createStackNavigator({
  Login:{
    screen : LoginScreen,
    navigationOptions: ({ navigation }) => ({
      header: null,
    }),
  },
  ForgotPassword:{
    screen: ForgotPassword,

    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title='showImage'/>,
  }),
},
  CreateAccount:{
    screen: CreateAccountScreen,

    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title='showImage'/>,
  }),
},
SignUp:{
  screen: SignUpScreen,

  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title='showImage'/>,
}),
},


},{headerLayoutPreset: 'center'})

const AuthSuccess = createStackNavigator({
  // Login:{
  //   screen : LoginScreen,
  //   navigationOptions: ({ navigation }) => ({
  //     header: null,
  //   }),
  // },
  SignedUp:{
    screen: SignedUpScreen,

    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='SignedUpScreen' screenTitle='To Care'/> : '' ,

    // headerLeft:<BackComponent navigation={navigation} comingFrom='SignedUpScreen'/>,
    headerTitle: <CustomHeader title="PALCARE" />,

}),
},

},{headerLayoutPreset: 'center'})

const ACDStack = createStackNavigator({

  AcdHome:{
    screen : AcdHome,
    navigationOptions: ({ navigation }) => ({
      headerTitle: <CustomHeader title='showImage'/>,
      headerTitleStyle: { alignSelf: 'center' },
    headerRight:<HeaderRight navigation={navigation}/>,
}),
},
AcdHomeProvider:{
  screen : AcdHomeProvider,
  navigationOptions: ({ navigation }) => ({
    headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,
    headerTitle: <CustomHeader title='showImage'/>,
    headerTitleStyle: { alignSelf: 'center' },
    // headerTitle:<CustomHeader/>,
}),
},

AcdSignUp:{
  screen : AcdSignUp,


  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Advanced Care Directive' fontSize ={14}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
AcdOverview:{
  screen : AcdOverview,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Advanced Care Directive' fontSize ={14} />,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
PersonalDetail:{
  screen : PersonalDetail,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '1' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "PERSONAL DETAILS" fontSize={14} />:
    <CustomHeader circleValue= '1' title = "PERSONAL DETAILS" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>

}),
},
UploadImage:{
  screen: UploadImage,

  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },

    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '1' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "PERSONAL DETAILS" fontSize={14} />:
    <CustomHeader circleValue= '1' title = "PERSONAL DETAILS" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>

}),
},

AppointingSubstituteDecisionMaker:{
  screen : AppointingSubstituteDecisionMaker,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '2a' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Appointing Substitute Decision-Makers" fontSize={14} />:
    <CustomHeader circleValue= '2a' title = "Appointing Substitute Decision-Makers" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>

}),
},

HelpText:{
  screen : HelpText,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '2a' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Appointing Substitute Decision-Makers" fontSize={14} />:
    <CustomHeader circleValue= '2a' title = "Appointing Substitute Decision-Makers" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

SubstituteDecisionMaker1:{
  screen : SubstituteDecisionMaker1,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '2a' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Appointing Substitute Decision-Makers" fontSize={14} />:
    <CustomHeader circleValue= '2a' title = "Appointing Substitute Decision-Makers" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>
}),
},

SubstituteDecisionMaker2:{
  screen : SubstituteDecisionMaker2,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '2a' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Appointing Substitute Decision-Makers" fontSize={14} />:
    <CustomHeader circleValue= '2a' title = "Appointing Substitute Decision-Makers" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

SubstituteDecisionMaker3:{
  screen : SubstituteDecisionMaker3,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '2a' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Appointing Substitute Decision-Makers" fontSize={14} />:
    <CustomHeader circleValue= '2a' title = "Appointing Substitute Decision-Makers" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},
SubstituteDecisionMaker4:{
  screen : SubstituteDecisionMaker4,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '2a' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Appointing Substitute Decision-Makers" fontSize={14} />:
    <CustomHeader circleValue= '2a' title = "Appointing Substitute Decision-Makers" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},
SubstituteDecisionMaker5:{
  screen : SubstituteDecisionMaker5,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '2a' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Appointing Substitute Decision-Makers" fontSize={14} />:
    <CustomHeader circleValue= '2a' title = "Appointing Substitute Decision-Makers" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},
QuestionG2 :{
  screen : QuestionG2,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '4' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Binding refusal of health care" fontSize={14} />:
    <CustomHeader circleValue= '4' title = "Binding refusal of health care" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},
QuestionRefusal :{
  screen : QuestionRefusal,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '4' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Binding refusal of health care" fontSize={14} />:
    <CustomHeader circleValue= '4' title = "Binding refusal of health care" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},
QuestionG1 :{
  screen : QuestionG1,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '4' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Binding refusal of health care" fontSize={14} />:
    <CustomHeader circleValue= '4' title = "Binding refusal of health care" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},


QuestionG :{
  screen : QuestionG,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '3' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "What is important to me - My values and wishes" fontSize={14} />:
    <CustomHeader circleValue= '3' title = "What is important to me - My values and wishes" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

QuestionF :{
  screen : QuestionF,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '3' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "What is important to me - My values and wishes" fontSize={14} />:
    <CustomHeader circleValue= '3' title = "What is important to me - My values and wishes" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

QuestionE :{
  screen : QuestionE,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '3' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "What is important to me - My values and wishes" fontSize={14} />:
    <CustomHeader circleValue= '3' title = "What is important to me - My values and wishes" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

QuestionD :{
  screen : QuestionD,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '3' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "What is important to me - My values and wishes" fontSize={14} />:
    <CustomHeader circleValue= '3' title = "What is important to me - My values and wishes" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

QuestionC :{
  screen : QuestionC,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '3' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "What is important to me - My values and wishes" fontSize={14} />:
    <CustomHeader circleValue= '3' title = "What is important to me - My values and wishes" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

QuestionB :{
  screen : QuestionB,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '3' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "What is important to me - My values and wishes" fontSize={14} />:
    <CustomHeader circleValue= '3' title = "What is important to me - My values and wishes" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},


QuestionA :{
  screen : QuestionA,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '3' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "What is important to me - My values and wishes" fontSize={14} />:
    <CustomHeader circleValue= '3' title = "What is important to me - My values and wishes" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},



WhatIsImportantScreen:{
  screen : WhatIsImportantScreen,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '3' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "What is important to me - My values and wishes" fontSize={14} />:
    <CustomHeader circleValue= '3' title = "What is important to me - My values and wishes" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

ValuesAndWishesScreen:{
  screen : ValuesAndWishesScreen,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '3' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "What is important to me - My values and wishes" fontSize={14} />:
    <CustomHeader circleValue= '3' title = "What is important to me - My values and wishes" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

ConditionsForAppointment1:{
  screen : ConditionsForAppointment1,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '2b' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Conditions for Appointment" fontSize={14} />:
    <CustomHeader circleValue= '2b' title = "Conditions for Appointment" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},


ConditionsForAppointment:{
  screen : ConditionsForAppointment,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '2b' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle:
    Platform.OS !== 'web' ?
    <CustomHeader title = "Conditions for Appointment" fontSize={14} />:
    <CustomHeader circleValue= '2b' title = "Conditions for Appointment" fontSize={14} />,
    headerRight:<HeaderRight navigation={navigation}/>}),
},


  LogBook :{
    screen : LogBook,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle: <CustomHeader title = "LOGBOOK" fontSize={14}/>,
  }),
  },
  LogBookProvider :{
    screen : LogBookProvider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "LOGBOOK" fontSize={14}/>,
  }),
  },

  SubstituteDecisionMakerPriority:{
    screen : SubstituteDecisionMakerPriority,
    navigationOptions: ({ navigation }) => ({
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '2a' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Appointing Substitute Decision-Makers" fontSize={14} />:
      <CustomHeader circleValue= '2a' title = "Appointing Substitute Decision-Makers" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },

  ACDoverview2:{
    screen : ACDoverview2,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '7' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Final Review" fontSize={14} />:
      <CustomHeader circleValue= '7' title = "Final Review" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },

  ACDoverview3:{
    screen : ACDoverview3,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '7' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Final Review" fontSize={14} />:
      <CustomHeader circleValue= '7' title = "Final Review" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },

  ACDoverview4:{
    screen : ACDoverview4,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "Upload" fontSize={14} logo = "AcdHome"/>,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },

  ACDoverview5:{
  screen : ACDoverview5,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Upload" fontSize={14} logo = "AcdHome"/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },

  ACDoverview6:{
  screen : ACDoverview6,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "Upload" fontSize={14} logo = "AcdHome"/>,
      headerRight:<HeaderRight navigation={navigation}/>

    }),
  },

  ACDoverview7:{
    screen : ACDoverview7,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "Status" fontSize={14} logo = "AcdHome"/>,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },

  ACDOverview11 :{
    screen : ACDOverview11,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerTitle: <CustomHeader title = "MyGov" fontSize={14}/>,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  ACDOverview10 :{
    screen : ACDOverview10,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "MyGov" fontSize={14}/>,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  ACDOverview9 :{
    screen : ACDOverview9,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "MyGov" fontSize={14}/>,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  ACDOverview8 :{
    screen : ACDOverview8,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "MyGov" fontSize={14}/>,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  PopOver8 :{
    screen : PopOver8,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "MyGov" fontSize={14}/>,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  PopOver7 :{
    screen : PopOver7,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "MyGov" fontSize={14}/>,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },


  PopOver5 :{
    screen : PopOver5,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "MyGov" fontSize={14}/>,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },


  ACDOverview18 :{
    screen : ACDOverview18,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "Status" fontSize={14}/>,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },



  AcdFinalReview :{
    screen : AcdFinalReview,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '7' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Final Review" fontSize={14} />:
      <CustomHeader circleValue= '7' title = "Final Review" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  WitnessScreen :{
    screen : WitnessScreen,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '6' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Witness" fontSize={14} />:
      <CustomHeader circleValue= '6' title = "Witness" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },
  WitnessScreen53 :{
    screen : WitnessScreen53,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '6' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Witness" fontSize={14} />:
      <CustomHeader circleValue= '6' title = "Witness" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },
  WitnessScreen54 :{
    screen : WitnessScreen54,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '6' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Process for correct witnessing" fontSize={14} />:
      <CustomHeader circleValue= '6' title = "Process for correct witnessing" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },
  PopOver5 :{
    screen : PopOver5,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      <CustomHeader title = "MyGov" fontSize={14}/>,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  GivingAdvanceCare1 :{
    screen : GivingAdvanceCare1,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '6' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Giving my Advanced Care Directive" fontSize={14} />:
      <CustomHeader circleValue= '6' title = "Giving my Advanced Care Directive" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  GivingAdvanceCare :{
    screen : GivingAdvanceCare,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '6' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Giving my Advanced Care Directive" fontSize={14} />:
      <CustomHeader circleValue= '6' title = "Giving my Advanced Care Directive" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  InterpreterStatement52 :{
    screen : InterpreterStatement52,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '5' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Interpreter statement" fontSize={14} />:
      <CustomHeader circleValue= '5' title = "Interpreter statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  InterpreterStatement43 :{
    screen : InterpreterStatement43,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '5' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Interpreter statement" fontSize={14} />:
      <CustomHeader circleValue= '5' title = "Interpreter statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },
  InterpreterStatement44 :{
    screen : InterpreterStatement44,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '5' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Interpreter statement" fontSize={14} />:
      <CustomHeader circleValue= '5' title = "Interpreter statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  InterpreterStatement5 :{
    screen : InterpreterStatement5,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '5' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Interpreter statement" fontSize={14} />:
      <CustomHeader circleValue= '5' title = "Interpreter statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  InformationStatement :{
    screen : InformationStatement,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '6' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Giving My Advance Care Directive" fontSize={14} />:
      <CustomHeader circleValue= '6' title = "Giving My Advance Care Directive" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },
  FullListOfWitness :{
    screen : FullListOfWitness,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '6' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Giving My Advance Care Directive" fontSize={14} />:
      <CustomHeader circleValue= '6' title = "Giving My Advance Care Directive" fontSize={14} />,

      headerRight:<HeaderRight navigation={navigation}/>}),
  },
  CertifiedCopies :{
    screen : CertifiedCopies,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= '6' navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Giving My Advance Care Directive" fontSize={14} />:
      <CustomHeader circleValue= '6' title = "Giving My Advance Care Directive" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>}),
  },

  ContactsMain:{
    screen : ContactsMain,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  ContactsSingle:{
    screen : ContactsSingle,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  ContactsSingleEditable:{
    screen : ContactsSingleEditable,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },

  UploadImageContact:{
    screen : UploadImageContact,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },




},{headerLayoutPreset: 'center'})


const AcdNorthernStack = createStackNavigator({
  AcdHomeNorthern:{
    screen : AcdHomeNorthern,
    navigationOptions: ({ navigation }) => ({
      headerTitle: <CustomHeader title='showImage'/>,
      headerTitleStyle: { alignSelf: 'center' },
    headerRight:<HeaderRight navigation={navigation}/>,
}),
},
LogBook :{
  screen : LogBook,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle: <CustomHeader title = "LOGBOOK" fontSize={14}/>,
}),
},
  AcdSignUpNorthern:{
    screen : AcdSignUpNorthern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Advance Personal Plan' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AcdOverviewNorthern:{
    screen : AcdOverviewNorthern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Advance Personal Plan' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AcdOverviewNorthernLast:{
    screen : AcdOverviewNorthernLast,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Advanced Care Statement' subTitle="FINAL REVIEW" fontSub={10} fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  PersonalDetailNorthern:{
    screen : PersonalDetailNorthern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'A' navigation={navigation} comingFrom='None' screenTitle='PERSONAL DETAILS'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "PERSONAL DETAILS" fontSize={14} />:
      <CustomHeader circleValue= 'A' title = "PERSONAL DETAILS" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceCareNoteNorthern:{
    screen : AdvanceCareNoteNorthern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'B' navigation={navigation} comingFrom='None' screenTitle='PERSONAL DETAILS'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Care Statement" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'B' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Care Statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceCareHomeNorthern:{
    screen : AdvanceCareHomeNorthern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'B' navigation={navigation} comingFrom='None' screenTitle='PERSONAL DETAILS'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Care Statement" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'B' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Care Statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceCare1:{
    screen : AdvanceCare1,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'B' navigation={navigation} comingFrom='None' screenTitle='Advanced Care Statement'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Care Statement" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'B' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Care Statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceCare2a:{
    screen : AdvanceCare2a,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'B' navigation={navigation} comingFrom='None' screenTitle='Advanced Care Statement'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Care Statement" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'B' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Care Statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceCare2b:{
    screen : AdvanceCare2b,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'B' navigation={navigation} comingFrom='None' screenTitle='Advanced Care Statement'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Care Statement" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'B' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Care Statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceCare2c:{
    screen : AdvanceCare2c,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'B' navigation={navigation} comingFrom='None' screenTitle='Advanced Care Statement'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Care Statement" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'B' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Care Statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceCare3:{
    screen : AdvanceCare3,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'B' navigation={navigation} comingFrom='None' screenTitle='Advanced Care Statement'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Care Statement" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'B' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Care Statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceCare4:{
    screen : AdvanceCare4,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'B' navigation={navigation} comingFrom='None' screenTitle='Advanced Care Statement'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Care Statement" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'B' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Care Statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceCare5:{
    screen : AdvanceCare5,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'B' navigation={navigation} comingFrom='None' screenTitle='Advanced Care Statement'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Care Statement" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'B' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Care Statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceCare6:{
    screen : AdvanceCare6,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'B' navigation={navigation} comingFrom='None' screenTitle='Advanced Care Statement'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Care Statement" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'B' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Care Statement" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceConsentHomeNorthern:{
    screen : AdvanceConsentHomeNorthern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'C' navigation={navigation} comingFrom='None' screenTitle='Advanced Consent Decision'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Consent Decision" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'C' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Consent Decision" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  AdvanceConsent1:{
    screen : AdvanceConsent1,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'C' navigation={navigation} comingFrom='None' screenTitle='Advanced Consent Decision'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Consent Decision" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'C' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Consent Decision" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },

  AdvanceConsent2:{
    screen : AdvanceConsent2,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'C' navigation={navigation} comingFrom='None' screenTitle='Advanced Consent Decision'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Consent Decision" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'C' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Consent Decision" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },

  AdvanceConsent3:{
    screen : AdvanceConsent3,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'C' navigation={navigation} comingFrom='None' screenTitle='Advanced Consent Decision'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Consent Decision" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'C' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Consent Decision" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  AdvanceConsentLastNorthern:{
    screen : AdvanceConsentLastNorthern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'C' navigation={navigation} comingFrom='None' screenTitle='Advanced Consent Decision'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Advanced Consent Decision" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'C' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Advanced Consent Decision" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  AppointingSubstituteHomeNorthern:{
    screen : AppointingSubstituteHomeNorthern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'D' navigation={navigation} comingFrom='None' screenTitle='Appointing Substitute Decision-Makers'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Appointing Substitute Decision-Makers" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'D' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Appointing Substitute Decision-Makers" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  AppointingSubstitute1:{
    screen : AppointingSubstitute1,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'D' navigation={navigation} comingFrom='None' screenTitle='Appointing Substitute Decision-Makers'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Appointing Substitute Decision-Makers" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'D' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Appointing Substitute Decision-Makers" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  AppointingSubstitute2:{
    screen : AppointingSubstitute2,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'D' navigation={navigation} comingFrom='None' screenTitle='Appointing Substitute Decision-Makers'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Appointing Substitute Decision-Makers" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'D' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Appointing Substitute Decision-Makers" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  AppointingSubstitute3:{
    screen : AppointingSubstitute3,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'D' navigation={navigation} comingFrom='None' screenTitle='Appointing Substitute Decision-Makers'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Appointing Substitute Decision-Makers" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'D' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Appointing Substitute Decision-Makers" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  AppointingSubstitutePreference:{
    screen : AppointingSubstitutePreference,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'D' navigation={navigation} comingFrom='None' screenTitle='Appointing Substitute Decision-Makers'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Appointing Substitute Decision-Makers" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'D' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Appointing Substitute Decision-Makers" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  Witnessing:{
    screen : Witnessing,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'E' navigation={navigation} comingFrom='None' screenTitle='Signing and witnessing'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Signing and witnessing" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'E' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Signing and witnessing" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  WitnessingNoteNorthern:{
    screen : WitnessingNoteNorthern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent circleValue= 'E' navigation={navigation} comingFrom='None' screenTitle='Signing and witnessing'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Signing and witnessing" subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} fontSize={14} />:
      <CustomHeader circleValue= 'E' subTitle="THIS IS NOT A COMPULSORY SECTION" fontSub={10} title = "Signing and witnessing" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  ACDoverview2Northern:{
    screen : ACDoverview2Northern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Signing and witnessing'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Final review" fontSize={14} />:
      <CustomHeader title = "Final review" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  ACDoverview3Northern:{
    screen : ACDoverview3Northern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Signing and witnessing'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Final review" fontSize={14} />:
      <CustomHeader title = "Final review" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  ACDoverview4Northern:{
    screen : ACDoverview4Northern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Signing and witnessing'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Upload" fontSize={14} />:
      <CustomHeader title = "Upload" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  ACDoverview5Northern:{
    screen : ACDoverview5Northern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Signing and witnessing'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Upload" fontSize={14} />:
      <CustomHeader title = "Upload" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  ACDoverview6Northern:{
    screen : ACDoverview6Northern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Signing and witnessing'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Upload" fontSize={14} />:
      <CustomHeader title = "Upload" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  PopOver5Northern:{
    screen : PopOver5Northern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Signing and witnessing'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Status" fontSize={14} />:
      <CustomHeader title = "Status" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  ACDOverview18Northern:{
    screen : ACDOverview18Northern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Signing and witnessing'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "Status" fontSize={14} />:
      <CustomHeader title = "Status" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },
  SevenStart:{
    screen : SevenStart,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenStart2:{
    screen : SevenStart2,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPdf:{
    screen : SevenPdf,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPdf1:{
    screen : SevenPdf1,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPdf2:{
    screen : SevenPdf2,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPdf3:{
    screen : SevenPdf3,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPopOver:{
    screen : SevenPopOver,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPdf4:{
    screen : SevenPdf4,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenLog:{
    screen : SevenLog,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='7 Step Pathways' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  ResourcesStart:{
    screen : ResourcesStart,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Resources'/> : '' ,
      headerTitle:<CustomHeader title='Resources' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
},
ResourceLog :{
  screen : ResourceLog,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Resources" fontSize={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

ACDoverview16:{
  screen : ACDoverview16,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Upload" fontSize={14} logo = "AcdHome"/>,
    headerRight:<HeaderRight navigation={navigation}/>

}),
},

ACDoverview17:{
screen : ACDoverview17,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Upload" fontSize={14} logo = "AcdHome"/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
},
PopOver9 :{
  screen : PopOver9,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Resources" fontSize={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>}),
},
ResourceLog :{
  screen : ResourceLog,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Resources" fontSize={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>}),
},
ResourcePdf :{
  screen : ResourcePdf,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Resources" fontSize={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

ContactsMain:{
  screen : ContactsMain,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
ContactsSingle:{
  screen : ContactsSingle,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
ContactsSingleEditable:{
  screen : ContactsSingleEditable,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
UploadImageContact:{
  screen : UploadImageContact,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
AnticipatoryStart:{
  screen : AnticipatoryStart,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
AnticipatoryPdf1:{
  screen : AnticipatoryPdf1,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
AnticipatoryPdf2:{
  screen : AnticipatoryPdf2,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
Anticipatory1:{
  screen : Anticipatory1,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
Anticipatory2:{
  screen : Anticipatory2,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
Anticipatory3:{
  screen : Anticipatory3,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
Anticipatory4:{
  screen : Anticipatory4,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
  ACDOverview8Northern:{
    screen : ACDOverview8Northern,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Signing and witnessing'/> : '' ,
      headerTitle:
      Platform.OS !== 'web' ?
      <CustomHeader title = "MyGov" fontSize={14} />:
      <CustomHeader title = "MyGov" fontSize={14} />,
      headerRight:<HeaderRight navigation={navigation}/>
  }),
  },


},{headerLayoutPreset: 'center'})


const ResourcesStack = createStackNavigator({
  AcdHome:{
    screen : AcdHome,
    navigationOptions: ({ navigation }) => ({
      headerTitle: <CustomHeader title='showImage'/>,
      headerTitleStyle: { alignSelf: 'center' },
    headerRight:<HeaderRight navigation={navigation}/>,
}),
},
LogBook :{
  screen : LogBook,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle: <CustomHeader title = "LOGBOOK" fontSize={14}/>,
}),
},
  ResourcesStart:{
    screen : ResourcesStart,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Resources'/> : '' ,
      headerTitle:<CustomHeader title='Resources' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
},
ResourceLog :{
  screen : ResourceLog,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Resources" fontSize={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>}),
},

ACDoverview16:{
  screen : ACDoverview16,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Upload" fontSize={14} logo = "AcdHome"/>,
    headerRight:<HeaderRight navigation={navigation}/>

}),
},

ACDoverview17:{
screen : ACDoverview17,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Upload" fontSize={14} logo = "AcdHome"/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
},
PopOver9 :{
  screen : PopOver9,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Resources" fontSize={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>}),
},
ResourceLog :{
  screen : ResourceLog,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Resources" fontSize={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>}),
},
ResourcePdf :{
  screen : ResourcePdf,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Resources" fontSize={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>}),
},


},{headerLayoutPreset: 'center'})

const SevenStackProvider = createStackNavigator({
    AcdHomeProvider:{
      screen : AcdHomeProvider,
      navigationOptions: ({ navigation }) => ({
        headerTitle: <CustomHeader title='showImage'/>,
        headerTitleStyle: { alignSelf: 'center' },
        headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      // headerRight:<HeaderRight navigation={navigation}/>,
  }),
  },
  LogBookProvider :{
    screen : LogBookProvider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      headerTitle: <CustomHeader title = "LOGBOOK" fontSize={14}/>,
  }),
  },
  SevenStartProvider:{
    screen : SevenStartProvider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,

  }),
  },
  SevenStart2Provider:{
    screen : SevenStart2Provider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,

  }),
  },
  SevenPdfProvider:{
    screen : SevenPdfProvider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,

  }),
  },
  SevenPdf1Provider:{
    screen : SevenPdf1Provider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,

  }),
  },
  SevenPdf2Provider:{
    screen : SevenPdf2Provider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,

  }),
  },
  SevenPdf3Provider:{
    screen : SevenPdf3Provider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,

  }),
  },
  SevenPopOverProvider:{
    screen : SevenPopOverProvider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,

  }),
  },
  SevenPdf4Provider:{
    screen : SevenPdf4Provider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,

  }),
  },
  SevenLogProvider:{
    screen : SevenLogProvider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,

      headerTitle:<CustomHeader title='7 Step Pathways' fontSize ={14}/>,

  }),
  },

},{headerLayoutPreset: 'center'})

const SevenStack = createStackNavigator({
    AcdHome:{
      screen : AcdHome,
      navigationOptions: ({ navigation }) => ({
        headerTitle: <CustomHeader title='showImage'/>,
        headerTitleStyle: { alignSelf: 'center' },
      headerRight:<HeaderRight navigation={navigation}/>,
  }),
  },
  LogBook :{
    screen : LogBook,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle: <CustomHeader title = "LOGBOOK" fontSize={14}/>,
  }),
  },
  SevenStart:{
    screen : SevenStart,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenStart2:{
    screen : SevenStart2,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPdf:{
    screen : SevenPdf,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPdf1:{
    screen : SevenPdf1,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPdf2:{
    screen : SevenPdf2,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPdf3:{
    screen : SevenPdf3,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPopOver:{
    screen : SevenPopOver,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenPdf4:{
    screen : SevenPdf4,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  SevenLog:{
    screen : SevenLog,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
      headerTitle:<CustomHeader title='7 Step Pathways' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },

},{headerLayoutPreset: 'center'})

const AnticipatoryStack = createStackNavigator({
  AcdHome:{
    screen : AcdHome,
    navigationOptions: ({ navigation }) => ({
      headerTitle: <CustomHeader title='showImage'/>,
      headerTitleStyle: { alignSelf: 'center' },
    headerRight:<HeaderRight navigation={navigation}/>,
}),
},
LogBook :{
  screen : LogBook,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle: <CustomHeader title = "LOGBOOK" fontSize={14}/>,
}),
},
AnticipatoryStart:{
  screen : AnticipatoryStart,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
AnticipatoryPdf1:{
  screen : AnticipatoryPdf1,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
AnticipatoryPdf2:{
  screen : AnticipatoryPdf2,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
Anticipatory1:{
  screen : Anticipatory1,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
Anticipatory2:{
  screen : Anticipatory2,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
Anticipatory3:{
  screen : Anticipatory3,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},
Anticipatory4:{
  screen : Anticipatory4,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,
  headerRight:<HeaderRight navigation={navigation}/>

}),
},

},{headerLayoutPreset: 'center'})



const ContactsStack = createStackNavigator({

  ContactsMain:{
    screen : ContactsMain,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  ContactsSingle:{
    screen : ContactsSingle,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  ContactsSingleEditable:{
    screen : ContactsSingleEditable,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },
  UploadImageContact:{
    screen : UploadImageContact,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
      headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,
    headerRight:<HeaderRight navigation={navigation}/>

  }),
  },

},{headerLayoutPreset: 'center'})


const PatientStackNorthern = createStackNavigator({

AcdNorthernStack:{
  screen : AcdNorthernStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null,
  }),
},

SevenStack:{
  screen : SevenStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null,
  }),
},
ResourcesStack:{
  screen : ResourcesStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null,
  }),
},
ContactsStack:{
  screen : ContactsStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null,
  }),
},
AnticipatoryStack:{
  screen : AnticipatoryStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null,
  }),
}
},{headerLayoutPreset: 'center'})

const PatientStack = createStackNavigator({

//   MainScreen:{
//     screen : MainScreen,
//     navigationOptions: ({ navigation }) => ({
//       headerTitleStyle: { alignSelf: 'center' },
//       headerLeft:<BackComponent navigation={navigation} screenTitle='To Care'/>,
//       headerTitle: <CustomHeader title="TO CARE" />,
//     headerRight:<HeaderRight navigation={navigation}/>
//
// }),
// },

ACDStack:{
  screen : ACDStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null,
  }),
},

SevenStack:{
  screen : SevenStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null,
  }),
},
ResourcesStack:{
  screen : ResourcesStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null,
  }),
},
ContactsStack:{
  screen : ContactsStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null,
  }),
},
AnticipatoryStack:{
  screen : AnticipatoryStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null,
  }),
}
},{headerLayoutPreset: 'center'})


const ProviderStack = createStackNavigator({

  MainScreenProvider:{
    screen: MainScreenProvider,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerTitle: <CustomHeader title="TO CARE PROVIDER" />,

}),
},

ACDStack:{
  screen : ACDStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    header:null          }),
  },
  AcdHomeProvider:{
    screen : AcdHomeProvider,
    navigationOptions: ({ navigation }) => ({
      headerTitle: <CustomHeader title='showImage'/>,
      headerTitleStyle: { alignSelf: 'center' },
      headerLeft: Platform.OS !== 'web' ? <BackComponentProvider navigation={navigation} comingFrom='providerHome' screenTitle='To Care'/> : '' ,


    // headerRight:<HeaderRight navigation={navigation}/>,
}),
},
ResourcesProvider :{
  screen : ResourcesProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Resource" fontSize={14}/>,
}),
},
HealthStart :{
  screen : HealthStart,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "MyGov" fontSize={14}/>,
}),
},

ResourcesEmptyProvider :{
  screen : ResourcesEmptyProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle: <CustomHeader title = "Resources" fontSize={14}/>,
}),
},

ResourcesLogProvider :{
  screen : ResourcesLogProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,

    headerTitle: <CustomHeader title = "Resource" fontSize={14}/>,
}),
},

LogBookProvider :{
  screen : LogBookProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle: <CustomHeader title = "LOGBOOK" fontSize={14}/>,
}),
},
SevenStartProvider:{
  screen : SevenStartProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,

}),
},
SevenStart2Provider:{
  screen : SevenStart2Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
    headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,

}),
},
SevenPdfProvider:{
  screen : SevenPdfProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
    headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,

}),
},
SevenPdf1Provider:{
  screen : SevenPdf1Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
    headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,

}),
},
SevenPdf2Provider:{
  screen : SevenPdf2Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
    headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,

}),
},
SevenPdf3Provider:{
  screen : SevenPdf3Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
    headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,

}),
},
SevenPopOverProvider:{
  screen : SevenPopOverProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
    headerTitle:<CustomHeader title='Upload' fontSize ={14}/>,

}),
},
SevenPdf4Provider:{
  screen : SevenPdf4Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
    headerTitle:<CustomHeader title='7 Step Pathway' fontSize ={14}/>,

}),
},
SevenLogProvider:{
  screen : SevenLogProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='7 Step Pathway'/> : '' ,
    headerTitle:<CustomHeader title='7 Step Pathways' fontSize ={14}/>,

}),
},


AnticipatoryStartProvider:{
  screen : AnticipatoryStartProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,

}),
},
AnticipatoryPdf1Provider:{
  screen : AnticipatoryPdf1Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,

}),
},
AnticipatoryPdf2Provider:{
  screen : AnticipatoryPdf2Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,

}),
},
Anticipatory1Provider:{
  screen : Anticipatory1Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,

}),
},
Anticipatory2Provider:{
  screen : Anticipatory2Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,

}),
},
Anticipatory3Provider:{
  screen : Anticipatory3Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,

}),
},
ContactsMainProvider:{
  screen : ContactsMainProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,

}),
},
ContactsSingleProvider:{
  screen : ContactsSingleProvider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='To Care'/> : '' ,
    headerTitle:<CustomHeader title='Contacts' fontSize ={14}/>,

}),
},
Anticipatory4Provider:{
  screen : Anticipatory4Provider,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerLeft: Platform.OS !== 'web' ? <BackComponent navigation={navigation} comingFrom='None' screenTitle='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life'/> : '' ,
    headerTitle:<CustomHeader title='Prescribing Guidelines for the
    Pharmacological Management of Symptoms
    for Adults in the Last Days of Life' fontSize ={10}/>,

}),
},
},{headerLayoutPreset: 'center'})

const AppStack = createSwitchNavigator({
  PatientStack: {
    screen: PatientStack,
    navigationOptions: ({ navigation }) => ({
      headerTitleStyle: { alignSelf: 'center' },
      headerTitle: <CustomHeader/>,
  }),
},
PatientStackNorthern: {
  screen: PatientStackNorthern,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerTitle: <CustomHeader/>,
}),
},

ProviderStack: {
  screen: ProviderStack,
  navigationOptions: ({ navigation }) => ({
    headerTitleStyle: { alignSelf: 'center' },
    headerTitle: <CustomHeader/>,
}),
},
},{headerLayoutPreset: 'center'})


const AppNavigator = createSwitchNavigator({
  AuthStack : {
    screen: AuthStack,
  },
  Home: {
    screen: AppStack,
  },

  AuthSuccess : {
    screen: AuthSuccess,
  },


})
//






const AppContainer = createAppContainer(AppNavigator)
export default AppContainer
