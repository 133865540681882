import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, ActivityIndicator, TouchableOpacity } from 'react-native';
import global from "../../../Styles/global";
import UserComponent from '../../../Components/UserComponent'
// import {Button, Icon} from 'react-native-elements'
import CustomMainScreenItem from '../../../Components/CustomMainScreenItem';
import PurpleCircle from '../../../Components/PurpleCircle';


export default class PopOver8 extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
    }

    this.getLocalKeys()
  }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);
     
    
        this.setState({userName:data.name})
        this.setState({userAddress:data.address})

        this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
      }
    }
  


  handlelogout = () =>{
    AsyncStorage.clear()
    this.props.navigation.navigate("Login")
  }
  handleacd= () =>{
    this.props.navigation.navigate("AcdHome")
  }
  handleItemPress= ()=>{
    this.props.navigation.navigate('AcdSignUp')
}

  render() {
    return (
      <View style= {global.container}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?global.ItemsContainerMobile:global.ItemsContainerWeb}>
        <ScrollView style={{width:'100%'}} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>

          <View style = {global.mainInsideContainer}>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
            {this.state.UserComponent}
            </TouchableOpacity>

            <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress()}>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "Advanced Care Directive" fontSize= {14} />
            </View>
            </TouchableOpacity>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "7 step plan" fontSize={14}/>
            </View>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "Medical History" fontSize={14}/>
            </View>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "Medication Summary" fontSize={14}/>
            </View>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "Anticipatory Prescribing Protocol" fontSize={14}/>
            </View>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "Resources" fontSize={14}/>
            </View>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "Contacts" fontSize={14}/>
            </View>
            <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
            <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "My Health Record" fontSize={14}/>
            </View>
            <Text onPress={()=>{this.handlelogout()}}> logout</Text>

             

          </View>
          </ScrollView>

          <View style={{width:'100%', height: 120, backgroundColor:'#F9F1FF',position:'absolute',bottom:0, left:0, right:0 ,display:'flex', paddingBottom:15,flexDirection:'column'}}>
                        <Text style={{fontSize:18, fontWeight:'600', color:'#1D2195', paddingTop:10, paddingLeft:25}}>Contacts</Text>
                        <View style={{display:'flex', flexDirection:'row',width:'75%', justifyContent:'space-between', alignItems:'center',paddingTop:10, alignSelf:'center'}}>
                                <PurpleCircle/>
                                <PurpleCircle/>
                                <PurpleCircle/>
                                <PurpleCircle/>
                                <PurpleCircle/>
                        </View>

                </View>

        </View>
      
      </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
//     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
//     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },

})
