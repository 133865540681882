import React, { Component } from 'react';
import { View,Text,StyleSheet, AsyncStorage,ScrollView, TextInput, Platform, NetInfo, ActivityIndicator, TouchableOpacity, Dimensions } from 'react-native';
import global from "../../Styles/global";
import UserComponentProvider from '../../Components/UserComponentProvider'
import {Button, Icon} from 'react-native-elements'
import CustomMainScreenItem from '../../Components/CustomMainScreenItem';
import PurpleCircle from '../../Components/PurpleCircle';
import { NavigationEvents } from 'react-navigation';
import CustomButton from '../../Components/CustomButton'
import axios from 'axios';

export default class AcdHomeProvider extends Component {
  constructor(){
    super()
    this.state = {
      search:'',
      winHeight:Dimensions.get("window").height,
      sevenStepPlanComplete: false,
      ActivityProcessing:true,
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
    }
    global.jsonData = require('../../Data/screens_data_new.json')
    // require('../../assets/Profile.png')

    this.getLocalKeys()
  }

  getLocalKeys = async () =>{
    const value = await AsyncStorage.getItem('@UserData');
    console.log('value', value);
    if (value !== null) {
      var data = JSON.parse(value)
      console.log("UserData :: ", data);
        if(data.accountStatus === "pending"){
          this.props.navigation.navigate("SignedUpScreen")
        }
        if(data.profileImg){
          global.profileImg = data.profileImg
          console.log('global.profileImg in acdhome', global.profileImg);

        }else{
          // global.profileImg =
          // require('../../../assets/Profile.png')
          // console.log('global.profileImg',global.profileImg);

        }
        this.setState({userName:data.name})
        this.setState({userAddress:data.address})
        this.setState({UserComponent:
          <TouchableOpacity >

          <UserComponentProvider name={this.props.navigation.state.params.allData.name} address={this.props.navigation.state.params.allData.address} profileImg = {this.props.navigation.state.params.allData.profileImg}/>
          </TouchableOpacity>
        })
      }
    }
  componentDidMount(){
    console.log('patientsWithAcds', this.props.navigation.state.params.allData.patientsWithAcds);
    console.log('alldata', this.props.navigation.state.params.allData);
    // this.setLocalToGlobal();
  }
  navigateProfile = () =>{
    // console.log('in UploadImage');
    // this.props.navigation.navigate("UploadImage")
  }
  onWillFocus = ()=>{
    console.log('in onWillFocus');
    this.getLocalKeys()
    this.getLogResource()
  }

  getLogResource = async (e) =>{

    this.token = await AsyncStorage.getItem('@token');
    console.log('in get resourcesComplete');
    let arrayCreated = []

        this.setState({downloading: true})
        let optionsAxios = ''
          optionsAxios = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
            url: global.url+'/api/getAllResources',
            data:{user_id:this.props.navigation.state.params.patientId}
          }

        console.log(" resourcesComplete Options axios ", optionsAxios );
        axios(optionsAxios)
        .then((response)=> {
          this.setState({downloading:false,isLoading:false})

          console.log("/resourcesComplete Acd Response recieved ", response);

          this.getSevenLog()
          if(response.data.success){
            // if(response.data.acdCreated.length !== 0 ){
            //   this.setState({PdfSeven:true})
            // }
            if(response.data.result.length > 0){
              this.setState({resourcesComplete: true})
            }

          }else{
            console.log(response.data.message);
          }
        })
        .catch((error)=>{

          this.getSevenLog()

          this.setState({PdfSeven:false, isLoading:false})

          console.log("error", error);
        })
  }


    getSevenLog = async (e) =>{
      // this.token = await AsyncStorage.getItem('@token');

      console.log('in get sevenLog');
      let arrayCreated = []

          this.setState({downloading: true})
          let optionsAxios = ''
            optionsAxios = {
              method: 'POST',
              headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
              url: global.url+'/api/getSevenStepPlan',
              data: {user_id:this.props.navigation.state.params.patientId}
            }

          console.log(" sevenLog Options axios ", optionsAxios );
          axios(optionsAxios)
          .then((response)=> {
            this.setState({downloading:false,isLoading:false})

            console.log("/sevenLog Acd Response recieved ", response);
            if(response.data.success){
              this.getLogBook()
              // if(response.data.acdCreated.length !== 0 ){
              //   this.setState({PdfSeven:true})
              // }

              if(response.data.result !== null && response.data.result !== '' && response.data.result !== undefined){
                if(response.data.result.sevenStepPdfSubmitted.length > 0){
                  this.setState({sevenStepPlanComplete: true})
                }
              }


            }else{
              console.log(response.data.message);

            }
          })
          .catch((error)=>{
            this.getLogBook()
            this.setState({PdfSeven:false, isLoading:false})

            console.log("error", error);
          })
    }


  setLocalToGlobal = async ()=>{
    console.log('in setLocalToGlobal');
    let SyncedUserData = await AsyncStorage.getItem('@SyncedUserAcd');
    let unSyncedUserData = await AsyncStorage.getItem('@unSyncedUserData');
    let acdImages = await AsyncStorage.getItem('@AcdImages');

    if (SyncedUserData !== null) {
      var forCheck = JSON.parse(SyncedUserData)
      console.log('forCheck', forCheck);

      if(forCheck.userData.acd.isComplete){
        this.setState({acdComplete: true})
      }
      }
      if(unSyncedUserData !== null){
        var data = JSON.parse(unSyncedUserData)
        console.log('in unSyncedUserData if', data);
        global.userData = data
      }else{
          var data = JSON.parse(SyncedUserData)
          console.log('in SyncedUserAcd else', data);
          unSyncedUserData = data
          global.userData = data
      }
      // this.getLogBookDetails()
  }

  getLogBookDetails = async ()=>{

    let acdPdfCreated = await AsyncStorage.getItem('@acdPdfCreated');
    let acdPdfWitnessed = await AsyncStorage.getItem('@acdPdfWitnessed');
    let parsedAcdPdfWitnessed = JSON.parse(acdPdfWitnessed);
    let parsedAcdPdfCreated = JSON.parse(acdPdfCreated);
    let createdDate = 0;
    let updatedDate = 0;
    console.log('acdPdfCreated' , JSON.parse(acdPdfCreated));
    console.log('acdPdfWitnessed', JSON.parse(acdPdfWitnessed));

    if(parsedAcdPdfCreated.length > 0){
      createdDate = parsedAcdPdfCreated[0].createdOn
      if(parsedAcdPdfCreated.length > 1){
        parsedAcdPdfCreated.map((x,idx,z) =>{
          if(idx == z.length-1){
            updatedDate = x.createdOn
          }

        })
      }
    }
    console.log('xxxx', createdDate, updatedDate);

    this.setState({createdDate: createdDate});
    this.setState({updatedDate: updatedDate});
    this.setState({UserComponent:
      <TouchableOpacity onPress={() => this.navigateProfile()} >

      <UserComponent createdDate ='00' updatedDate="00"/>
      </TouchableOpacity>
    })
  }

  handlelogout = () =>{
    AsyncStorage.clear()
    this.props.navigation.navigate("Login")
  }
  handleacd= () =>{
    this.props.navigation.navigate("AcdHome")
  }
  handleItemPress= (e)=>{
    console.log('pattt', this.props.navigation.state.params);
    if(e === 'Advanced Care Directive'){
      this.props.navigation.navigate('LogBookProvider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})
    }else if(e === '7 step plan'){
      this.props.navigation.navigate('SevenStartProvider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})
    }else if(e === '7 step plan complete'){
      this.props.navigation.navigate('SevenStart2Provider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})
    }else if(e === 'Resources complete'){
      this.props.navigation.navigate('ResourcesLogProvider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})
    }else if(e === 'Resources'){
      this.props.navigation.navigate('ResourcesEmptyProvider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})
    }else if(e === 'AnticipatoryStart'){
      this.props.navigation.navigate('AnticipatoryStartProvider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})
    }else if(e === 'My Healthrecord'){
      this.props.navigation.navigate('HealthStart', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})
    }else if(e === 'Contacts'){
      this.props.navigation.navigate('ContactsMainProvider', {patientId: this.props.navigation.state.params.patientId, territoryId: this.props.navigation.state.params.territoryId,  allData:this.props.navigation.state.params.allData})

    }
  }

componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}

componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}


getLogBook =(e) =>{
  console.log('patientId', this.props.navigation.state.params.patientId);
  NetInfo.getConnectionInfo().then(connectionInfo => {
    console.log(
      'Initial, type: ' + connectionInfo.type + ', effectiveType: ' + connectionInfo.effectiveType
    );

    if (connectionInfo.type == "none") {
      alert("Your need internet access to get updated LogBook")
      // global.url = 'https://palcare-backend-dev.herokuapp.com'
      // this.getLocalData()
      console.log("OS :: ", Platform.OS);
      this.setState({downloading:false})
    }
    else {
      console.log('in else getlogbook', this.token, this.user);

      this.setState({downloading: true})
      let optionsAxios = ''
      // let user = JSON.parse(this.user)

      optionsAxios = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
        url: global.url+'/api/getLogBook',
        data: {user_id: this.props.navigation.state.params.patientId}
      }

      console.log(" logbook Options axios ", optionsAxios );
      axios(optionsAxios)
      .then((response)=> {
        this.setState({downloading:false})

        console.log("/logbook Acd Response recieved ", response);
        this.setState({ActivityProcessing: false})

        if(response.data.success){
          // if(response.data.acdCreated.length !== 0 ){
          //   this.setState({AcdGenerated:true})
          // }
          //
          // if(response.data.result[0].acdPdfWitnessed.length !== 0 ){
          //   this.setState({AcdWitnessedprocess:true})
          // }
          this.setState({ActivityProcessing: false})

          if(response.data.acdCreated.length > 0){
            let reversedArray = response.data.acdCreated.reverse()
            this.setState({AcdGenerated:true})

            // reversedArray.map((x,idx,z) =>{
            //   if(idx == z.length-1){
            //     console.log('x is', x);
            //     let splitted = x.url.split("//");
            //     console.log('splitted', splitted);
            //     let joinedString = splitted[0] + '//www.' + splitted[1]
            //     console.log('splitted', joinedString);
            //     this.setState({pdf: joinedString})
            //   }
            //
            // })
          }

        }else{
          console.log(response.data.message);
        }
      })
      .catch((error)=>{
        this.setState({ActivityProcessing: false})

        console.log("error", error);
      })
    }
  });

}


handler =(e) =>{
console.log('eee', e);
this.setState({winHeight: e.window.height})
}
UploadImage = () =>{
  this.props.navigation.navigate("UploadImage")

}

  render() {
    return (
        <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'100%',width:'100%'}]:[{height:'100%',width:'100%'}]}  contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>

        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?global.ItemsContainerMobile:global.ItemsContainerWeb}>
          <NavigationEvents
            onWillFocus={() => this.onWillFocus()}
            onDidFocus={payload => console.log('did focus', payload)}
            onWillBlur={payload => console.log('will blur', payload)}
            onDidBlur={payload => console.log('did blur', payload)}
            />
          {
            this.state.ActivityProcessing
            ?
            <ActivityIndicator size="large" color="#9126fb" />
            :
            <View style = {global.mainInsideContainer}>

              <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
              {this.state.UserComponent}
              </TouchableOpacity>

              {
                (this.state.AcdGenerated === true)
                ?
                <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress('Advanced Care Directive')}>
                <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "Advanced Care Directive" fontSize= {14} />
                </View>
                </TouchableOpacity>
                :
                <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleItemPress('Advanced Care Directive')}>
                <View style={{width:'90%', paddingTop:5,paddingBottom:5,alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                <CustomMainScreenItem color='#1D2195' checkBox = 'empty' title = "Advanced Care Directive" fontSize= {14} />
                </View>
                </TouchableOpacity>
              }
              {
                !this.state.sevenStepPlanComplete
                ?
                <TouchableOpacity onPress={()=>this.handleItemPress('7 step plan')} style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                <CustomMainScreenItem color='#1D2195' checkBox = 'empty' title = "7 step plan" fontSize={14}/>
                </TouchableOpacity>
                :
                <TouchableOpacity onPress={()=>this.handleItemPress('7 step plan complete')} style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "7 step plan" fontSize={14}/>
                </TouchableOpacity>
              }
              {
                !this.state.resourcesComplete
                ?
                <TouchableOpacity onPress={()=>this.handleItemPress('Resources')} style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                <CustomMainScreenItem color='#1D2195' checkBox = 'empty' title = "Resources" fontSize={14}/>
                </TouchableOpacity>
                :
                <TouchableOpacity onPress={()=>this.handleItemPress('Resources complete')} style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
                <CustomMainScreenItem color='#1D2195' checkBox = 'show' title = "Resources" fontSize={14}/>
                </TouchableOpacity>
              }

              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem color='#1D2195' checkBox = 'empty' title = "Medication Summary" fontSize={14}/>
              </View>

              <View style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem color='#1D2195' checkBox = 'empty' title = "Medical History" fontSize={14}/>
              </View>

              <TouchableOpacity onPress={()=>this.handleItemPress('AnticipatoryStart')} style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem color='#1D2195' title = "Anticipatory Prescribing Protocol" fontSize={14}/>
              </TouchableOpacity>


              <TouchableOpacity onPress={()=>this.handleItemPress('My Healthrecord')} style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>
              <CustomMainScreenItem color='#1D2195' title = "My Healthrecord" fontSize={14}/>
              </TouchableOpacity>

                <TouchableOpacity onPress={()=>this.handleItemPress('Contacts')} style={{width:'90%', paddingTop:5,paddingBottom:5, alignItems:'center', justifyContent:'center', alignSelf:'center'}}>

              <CustomMainScreenItem color='#1D2195' title = "Contacts" fontSize={14}/>
            </TouchableOpacity>



            </View>
          }

        </View>
      </ScrollView>

    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
//     paddingTop:50,
  },


  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
//     marginTop:16,
    marginBottom:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    marginBottom:5,
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',

  },

})
