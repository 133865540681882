import React, { Component } from 'react';
import { View, Text, StyleSheet, ImageBackground , TouchableOpacity, Platform} from 'react-native';

export default class UserDetail extends Component {
  render() {
    var icon = require('../../assets/icons/address.png')
    if (this.props.icon == 'name') {
      icon = require('../../assets/icons/Full_name.png')
    }
    if (this.props.icon == 'email') {
      icon = require('../../assets/icons/Email2.png')
    }
    if (this.props.icon == 'phone') {
      icon = require('../../assets/icons/Phone.png')
    }
    if (this.props.icon == 'gp') {
      icon = require('../../assets/icons/GP_icon_details.png')
    }
    if (this.props.icon == 'address') {
      icon = require('../../assets/icons/Adress.png')
    }
    if (this.props.icon == 'territory') {
      icon = require('../../assets/icons/Territory.png')
    }
    if (this.props.icon == 'birthday') {
      icon = require('../../assets/icons/Birthday.png')
    }
    if (this.props.icon == 'note1') {
      icon = require('../../assets/icons/note.png')
    }

    return (
      <TouchableOpacity onPress={this.props.onPress} style = {{display:'flex',flexDirection:'row',alignItems:'center', width:'100%',paddingTop:10,paddingBottom:10 ,backgroundColor:'#F9F1FF', marginBottom:8}}>
        <View style={{width:'25%', alignItems:'center', justifyContent:'center'}}>
          {
            (Platform.OS === 'ios' || Platform.OS ==='android')
            ?
            <ImageBackground source={icon} style={this.props.icon === 'territory' || this.props.icon === "email" ?[{width: "23%", height: 19}]:[{width: "20%", height: 18}]}/>
            :
            <ImageBackground source={icon} style={this.props.icon === 'territory' || this.props.icon === "email" ?[{width: "23%", height: 37}]:[{width: "18%", height: 35}]}/>
          }
        </View>
        <View style = {{width:'75%', display:'flex', flexDirection:'column'}}>
          <Text numberOfLines={2} ellipsizeMode='tail' style={{color:'#1D2195',fontSize:16, marginBottom:2, paddingRight:"1%"}}>{this.props.data}</Text>
          <Text style={{color:'#1D2195', fontSize:12}}>{this.props.title}</Text>
        </View>
      </TouchableOpacity>

    );
  }
}
