import React, { Component } from 'react';
import { View,Text,StyleSheet, Image, ImageBackground} from 'react-native';
import PurpleCircle from './PurpleCircle';

export default class ACDHeader extends Component {
        render() {
                return (
                        <View style = {{ display:'flex', flexDirection:'row',width:'100%', alignItems:'center', justifyContent:'center', paddingLeft:35}}>
                                {this.props.circleValue !=null?
                                <View style= {{width:40, height:40,alignItems:'center', justifyContent:'center' ,borderRadius:100,backgroundColor:'#9126FB'}}>
                                         <Text style= {{textAlign:'center', color:'white', fontWeight:'700',fontSize:24}}>{this.props.circleValue}</Text>
                                 </View>
                                 :null
                                 }
                                 <Text style={{fontWeight:"bold", color:"#1D2195"}}>{this.props.screenTitle}</Text>
                        </View>
                );
        }
}
