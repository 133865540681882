import React, { Component } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

export default class PurpleCircle extends Component {
        render() {
                return (
                        <TouchableOpacity onPress={this.props.onPress} style={{width:'20%', alignItems:'center', justifyContent:'center'}}>

                        <View style= {{width:30, height:30,alignItems:'center', justifyContent:'center' ,borderRadius:100,backgroundColor:'#9126FB'}}>
                                <Text style= {{textAlign:'center', color:'white', fontWeight:'700',fontSize:24}}>{this.props.circleText}</Text>
                        </View>
                        </TouchableOpacity>
                );
        }
}
