import React, {Component} from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Navigator from './src/Navigator/Navigator'
import { connectActionSheet } from '@expo/react-native-action-sheet'
import { AppearanceProvider } from 'react-native-appearance';

class App extends Component{
  constructor(){
    super()
    global.url = 'https://palcare-backend-dev.herokuapp.com'
    // global.url = 'https://palcare-backend-internal.herokuapp.com'


  }

  render()
  {
    return(
      <AppearanceProvider>
      <Navigator/>
      </AppearanceProvider>
    )
  }
}

const ConnectedApp = connectActionSheet(App)
export default ConnectedApp;
