import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, TextInput,TouchableOpacity, ImageBackground, ScrollView , ActivityIndicator} from 'react-native';
import ACDfooter from '../../../Components/ACDfooter'
import CustomButton from '../../../Components/CustomButton';
import UserComponent from '../../../Components/UserComponent'
import { AsyncStorage } from 'react-native';
import global from "../../../Styles/global"
import axios from 'axios'
import EventBus from 'eventing-bus'

export default class ACDOverview10 extends Component {
  constructor(){
    super()
    this.state={
      providerText :'You have now applied for a PALCARE account. When your account has been approved you will be able to login.',
      patientText : 'You have now signed up for PALCARE You will receive an email to confirm your email and sign-up.',
      verificationPending: 'Email verification is pending, Confirm your email then you will be able to login.',
      code:'',
      isLoading:false,
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />
    }
        this.getLocalKeys()
  }


  getLocalKeys = async () =>{
        const value = await AsyncStorage.getItem('@UserData');
        if (value !== null) {
          var data = JSON.parse(value)
          console.log("UserData :: ", data);
    
    
            this.setState({userName:data.name})
            this.setState({userAddress:data.address})
    
            this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
          }
        }

handleAcdOverview = (e) =>{
        console.log("overview clicked ");
        this.props.navigation.navigate('ACDOverview18')
      }
      

  render() {
    console.log("props :: ", this.props);
    return (
      <View style = {styles.container}>
        <View style= {Platform.OS === 'ios' || Platform.OS ==='android'? [global.ItemsContainerMobile,{paddingBottom:30}]:global.ItemsContainerWeb}>
        <ScrollView style={{width:'100%'}} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
            {this.state.UserComponent}
            </TouchableOpacity>
         
            <Text>WebView Acdoverview 10 screen</Text>

             
          
        </ScrollView>
        <CustomButton title = "➔" navigation= {this.props.navigation} marginBottom={40} onPress = {this.handleAcdOverview}/>
        <ACDfooter  hidePurple = {true}  />

        </View>

      </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
  },

  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },

  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  congratulationsweb:{
    width:'75%',
    height:500,
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
  congratulations:{
    width:'75%',
    height:'77%',
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
})
