import React, { Component } from 'react';
import { View,Text,StyleSheet, Image, ImageBackground, TouchableOpacity, Platform, AsyncStorage
} from 'react-native';
import { Avatar } from 'react-native-elements';
import EventBus from 'eventing-bus'
import CustomMainScreenItem from '../Components/CustomMainScreenItem';

var moment = require('moment');

export default class UserComponentProvider extends Component {

  constructor(){
    super()
    this.state = {

    }

  }

  componentDidMount (){
    this.getprofileImg()
    EventBus.on("changeProfile", this.changeProfile)

  }

  changeProfile = async ()=>{
    // console.log('changing profile');
  }
  getprofileImg = async ()=>{
    // require('../../assets/Profile.png')

    const user = await AsyncStorage.getItem('@UserData');
    // const timePeriods = await AsyncStorage.getItem('@timePeriod');
    if(user !== null){
      let userParsed = JSON.parse(user)
      // let timeParsed = JSON.parse(timePeriods)

      console.log('this.props.createdOn', this.props.createdOn);
      if(this.props.createdOn !== undefined && this.props.createdOn !== null && this.props.createdOn !== '' ){
        if(this.props.createdOn.length > 0){
          console.log('this.props.createdOn', this.props.createdOn);
          this.props.createdOn.map((x, idx, z) =>{
            if(idx == z.length-1){
              let today = new Date(Date.now())
              let updatedDate = new Date(x.createdOn)
              // console.log('subtracting', today, updatedDate);
              let daysUpdate = today.getTime() - updatedDate.getTime()
              var updatedDays = parseInt(daysUpdate / (1000 * 3600 * 24));

              if(updatedDays){
                if(updatedDays < 10){
                  this.setState({sinceUpdated: "0" + updatedDays})
                }else{
                  this.setState({sinceUpdated: updatedDays})
                }
              }else{
                this.setState({sinceUpdated: '00'})
              }
              console.log('updated date', moment(x.createdOn).format('DD, YYYY, MM'));
              // console.log('updated days', updatedDays);
            }
          })

          let today = new Date(Date.now())
          let createdDate = new Date(this.props.createdOn[0].createdOn)
          // console.log('subtracting created', today , updatedDate);

          let daysCreated = today.getTime() - createdDate.getTime()
          var createdDays = parseInt(daysCreated / (1000 * 3600 * 24));

          if(createdDays){
            if(createdDays < 10){
              this.setState({sinceCreated: "0" + createdDays})
            }else{
              this.setState({sinceCreated: createdDays})
            }
          }else{
            this.setState({sinceCreated: '00'})

          }

          // console.log('submitted date', this.props.createdOn[0]);
          console.log('created days', createdDays);

          // console.log('today date', moment().format());
        }else{
          this.setState({sinceUpdated: '00'})
          this.setState({sinceCreated: '00'})
        }
      }else{
        this.setState({sinceUpdated: '00'})
        this.setState({sinceCreated: '00'})
      }
      if(userParsed.profileImg){
        this.setState({profileImg: userParsed.profileImg})
      }
    }

  }

        render() {
                return (
                        <TouchableOpacity onPress={this.props.onPress} style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{display:'flex', flexDirection:'row',width:'100%',minHeight:80, marginBottom:20,backgroundColor:'#F9F1FF',borderColor:'#BCE0FD', borderWidth:2, justifyContent:'space-between'}]:
                          [{display:'flex', flexDirection:'row',width:'100%',minHeight:80, marginBottom:20,backgroundColor:'#F9F1FF',borderColor:'#BCE0FD', borderWidth:2, justifyContent:'space-between', marginTop:10}]}>
                                <View style ={{width:"70%",display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                                  <TouchableOpacity style = {{ width:"30%", maxWidth:100, flexDirection:'row'}} >
                                        <Avatar
                                          size="large"
                                          rounded
                                          source={{
                                            uri: this.props.profileImg
                                          }}
                                          containerStyle={{marginLeft: 4,height:65, width:65}}
                                          />
                                  </TouchableOpacity>

                                <View style = {this.props.type === 'home'? {width:"70%", whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', flexDirection:'column'} : {width:"70%", whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', flexDirection:'column'}}>
                                        <Text numberOfLines={1} ellipsizeMode='tail' style={{color:'#1D2195',fontWeight:'bold' ,fontSize:20}}>{this.props.name}</Text>
                                        <Text numberOfLines={4} ellipsizeMode='tail' style={{color:'#1D2195', fontSize:10}} >{this.props.address}</Text>
                                </View>

                               </View>
                               {
                                 /*
                                 <View style = {{width:"20%", flexDirection:'column',justifyContent:'center', alignItems:'center'}}>
                                         <Text style={{color:'#1D2195',fontWeight:'bold' ,fontSize:20}}>{this.state.sinceCreated}</Text>
                                         <Text style={{color:'#1D2195',fontWeight:'bold' ,fontSize:20}}>{this.state.sinceUpdated}</Text>
                                 </View>
                                 */
                               }



                                {
                                  this.props.type === 'home'
                                  ?
                                  <View style = {{width:"10%", flexDirection:'column',justifyContent:'center', alignItems:'center'}}>

                                    {this.props.isComplete === true?
                                     <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
                                     <ImageBackground source={require('../../assets/Checkbox_check.png')} style={{width: 20, height: 20}}/>
                                     </View>
                                     :
                                     <View style={{ width:'10%', backgroundColor:'#9126FB',height:25,width:25, alignItems:'center', justifyContent:'center'}}>
                                     </View>
                                    }
                                  </View>
                                  :
                                  null
                                }



                        </TouchableOpacity>
                );
        }
}
