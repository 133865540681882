import React, { Component } from 'react';
import { View,Dimensions, Text, StyleSheet, Platform, TextInput,TouchableOpacity, ImageBackground, ScrollView , ActivityIndicator} from 'react-native';
import ACDfooter from '../../Components/ACDfooter'
import CustomButton from '../../Components/CustomButton';
// import UserComponent from '../../Components/UserComponent'
import UserComponentProvider from '../../Components/UserComponentProvider'

import { AsyncStorage } from 'react-native';
import global from "../../Styles/global"
import axios from 'axios'
import EventBus from 'eventing-bus'
import { WebView } from 'react-native-webview';
import MyHR from './MyHR'

export default class HealthStart extends Component {
  constructor(){
    super()
    this.state={
      providerText :'You have now applied for a PALCARE account. When your account has been approved you will be able to login.',
      patientText : 'You have now signed up for PALCARE You will receive an email to confirm your email and sign-up.',
      verificationPending: 'Email verification is pending, Confirm your email then you will be able to login.',
      code:'',
      isLoading:false,
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
      winHeight:Dimensions.get("window").height,
      showButton:true
    }
        this.getLocalKeys()
  }


  getLocalKeys = async () =>{
        const value = await AsyncStorage.getItem('@UserData');
        if (value !== null) {
          var data = JSON.parse(value)
          console.log("UserData :: ", data);


            this.setState({userName:data.name})
            this.setState({userAddress:data.address})
            this.setState({UserComponent:<UserComponentProvider name={this.props.navigation.state.params.allData.name} address={this.props.navigation.state.params.allData.address} profileImg = {this.props.navigation.state.params.allData.profileImg}/>})

            // this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
          }
        }

handleAcdOverview = (e) =>{
        console.log("overview clicked ");
        this.props.navigation.navigate('ACDOverview18')
      }

      componentWillMount(){
        Dimensions.addEventListener("change", this.handler);
      }

      componentWillUnmount(){
        Dimensions.removeEventListener("change", this.handler);
      }
      handler =(e) =>{
        console.log('eee', e);
        this.setState({winHeight: e.window.height-500})
      }

      handleWebViewNavigationStateChange = newNavState => {
  const { url } = newNavState;


  if (url.includes('expo')) {
    this.setState({showButton:false})
  }
};



  render() {
    console.log("props :: ", this.props);
    return (
      <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
        <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>

          <TouchableOpacity style={{width:'100%'}} onPress={()=>this.handleacd()}>
            {this.state.UserComponent}
            </TouchableOpacity>

            <View style={{width:"90%", height:this.state.winHeight-100, paddingBottom:50}}>
              {Platform.OS === 'web'
              ?<iframe src='https://sofit.ltd' height="100%" width="100%"/>
              :<WebView
                originWhitelist={['*']}
                source={{ uri: 'https://my.gov.au/LoginServices/main/login?execution=e1s1' }}
                style={{flex:1 }}
                onNavigationStateChange={this.handleWebViewNavigationStateChange}/>}
              </View>
            </View>

        </ScrollView>

        <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
          contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
          {this.state.showButton
            ?<CustomButton title = "➔"    navigation= {this.props.navigation} onPress = {this.handleNext}/>
          :null
          }
        </ScrollView>


      </View>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#501D95',
  },

  inputField:{
    width:'90%',
    flexDirection:'row',
    height:50,
    marginTop:16,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'


  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },

  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  congratulationsweb:{
    width:'75%',
    height:500,
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
  congratulations:{
    width:'75%',
    height:'77%',
    alignItems:'center',
    // justifyContent:'center',
    backgroundColor:'#F9F1FF',
  },
})
