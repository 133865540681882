import React, { Component } from 'react';
import { View,Text,StyleSheet, Image, ImageBackground, TouchableOpacity} from 'react-native';

export default class LogBookComponent extends Component {
        render() {
                return (
                        <TouchableOpacity onPress = {this.props.onPress} style = {{ display:'flex', flexDirection:'column',width:'100%', alignItems:'flex-start', justifyContent:'center', marginBottom:10}}>
                                <Text style={{marginLeft:20, color:'#1D2195', fontSize:20, marginBottom:10,fontWeight:'bold'}}>{this.props.date}</Text>
                                <View  style={this.props.type === 'seven' ? [{backgroundColor:'#f1f8fe', width:'100%',height:80, alignItems:'center', flexDirection:'row'}] : this.props.type === 'resource' ?[{backgroundColor:'#efefef', width:'100%',height:80, alignItems:'center', flexDirection:'row'}]: [{backgroundColor:'#F9F1FF', width:'100%',height:80, alignItems:'center', flexDirection:'row'}]}>
                                        <View style={{width:'30%', alignItems:'center', justifyContent:'center'}}>
                                          {
                                          this.props.type === 'seven'
                                          ?
                                          <ImageBackground source={require('../../assets/Sevenpdf.png')} style={{width: 40, height: 50}}/>
                                          :
                                          this.props.type === 'resource'
                                          ?
                                          <ImageBackground source={require('../../assets/resourcePdf11.png')} style={{width: 40, height: 50}}/>
                                          :
                                          <ImageBackground source={require('../../assets/pdff.png')} style={{width: 40, height: 50}}/>
                                          }

                                        </View>
                                        <View style={{flexDirection:'column',alignItems:'flex-start', justifyContent:'center', width:'70%'}}>
                                                <Text style={{color:'#7F7F7F', fontSize:25, fontWeight:'700', marginBottom:4}}>{this.props.stepName}</Text>
                                                <Text style={{color:'#1D2195', fontSize:20, fontWeight:'700'}}>{this.props.doctorName}</Text>
                                        </View>
                                </View>
                        </TouchableOpacity>
                );
        }
}
