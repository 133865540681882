import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform} from 'react-native';

export default class ACDfooterNorthern extends Component {
  render() {
    return (
      <View style={{display:'flex', flexDirection:'row', width:'90%', alignItems: 'center', height:30, position:"absolute", bottom:20,backgroundColor:"transparent" }}>



        {
          this.props.hidePurple == true && this.props.title === "A"
          ?
          <View style= {{width:30,alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100, borderWidth:1, borderColor:'#1D2195',backgroundColor:"#9126FB"}}>
            <Text style={{fontSize:20, fontWeight:'bold', color:"#fff"}}>A</Text>
          </View>
          :
          <View style= {{width:30,alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100, borderWidth:1, borderColor:'#1D2195',backgroundColor:"white"}}>
            <Text style={{fontSize:20, fontWeight:'bold', color:"#1D2195"}}>A</Text>
          </View>
        }



        <View
          style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{width:'84.5%', height:5, backgroundColor:'#f9f1ff'}]:[{width:'92%', height:5, backgroundColor:'#f9f1ff'}]}

        >

          {this.props.hidePurple == true ? null :
        <View style= {{width:30,marginTop:-12, marginLeft:this.props.marginLeft ,alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100,backgroundColor:"#9126FB"}}>
          <Text style={{fontSize:20, fontWeight:'bold', color:"#fff"}}>{this.props.title}</Text>
        </View>
          }
        </View>


        {
          this.props.hidePurple == true && this.props.title === "E"
          ?
          <View style={{flex:1, flexDirection:'row', justifyContent:'flex-end'}}>
            <View style= {{width:30, alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100, borderWidth:1, borderColor:'#1D2195', backgroundColor:"#9126FB"}}>
              <Text style={{fontSize:20, fontWeight:'bold', color:"#fff"}}>E</Text>
            </View>
          </View>
          :
          <View style={{flex:1, flexDirection:'row', justifyContent:'flex-end'}}>
            <View style= {{width:30, alignItems: 'center', justifyContent: 'center', height:30, borderRadius:100, borderWidth:1, borderColor:'#1D2195', backgroundColor:"white"}}>
              <Text style={{fontSize:20, fontWeight:'bold', color:"#1D2195"}}>E</Text>
            </View>
          </View>
        }

      </View>
    );
  }
}
