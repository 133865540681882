import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  AsyncStorage,
  ActivityIndicator,
  ScrollView,
  Linking,
  Dimensions,
  NetInfo
} from 'react-native';
import {  Button, CheckBox } from 'react-native-elements'
import UserComponent from '../../Components/UserComponent'
import UserDetail from '../../Components/UserDetail'
import CustomButton from '../../Components/CustomButton'
import ACDfooter from '../../Components/ACDfooter'
import global from '../../Styles/global';
import DatePicker from 'react-native-datepicker'
import * as FileSystem from "expo-file-system";
import * as Print from 'expo-print';
import * as WebBrowser from 'expo-web-browser';
import axios from 'axios';

export default class WitnessScreen54 extends Component {
  constructor(props) {
    super(props);
    this.state={
      UserComponent:<ActivityIndicator size="small" color="#9126fb" />,
    data:'',
    winHeight:Dimensions.get("window").height,
    name: '',
    email: '',
    address: '',
    birthday:'1999-05-15',
    mainSubstitute:false,
    skipSubstitute:false,
    inScreenText0: false,
    inScreenText1: false,
    inScreenText2: false,
    inScreenText3: false,
    inScreenText4: false,
    inScreenText5: false,
    inScreenText6: false,
    inScreenText7: false,
    inScreenText8: false,
    inScreenText9: false,
    inScreenText10: false,
  }
  // global.jsonData = require('../../Data/screens_data_new.json')

  global.jsonData.forEach((i,idx,x)=>{
    if (i.title == 'What is important to me - A (example) – 54') {
      this.jsonData = i
      console.log('inScreenText', this.jsonData.inScreenText);

    }
  })
  this.jsonUser = global.userData.userData.acd.witness

  this.getLocalKeys()
}
componentDidMount = () =>{
  // this.setValues();
}
setValues =()=>{
  const checks = []
  console.log('this.jsonData', this.jsonData);
  this.jsonData.inScreenText.map(x =>{
    console.log('x ix', x);
  const array =
        <View style={ styles.bulletText }>
          {/*<Text style={ styles.bullet }>{'\u25E6' + " "}</Text>*/}
              <CheckBox
                  title={x}
                  textStyle={styles.CheckBoxTitle}
                  size={25}
                  containerStyle={styles.CheckBoxContainerStyle}
                  checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                  uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                  checked={this.state.mainSubstitute}
                  onPress={() => this.setState({mainSubstitute: !this.state.mainSubstitute})}
                  />
        </View>


    checks.push(array)
  })
  this.setState({checks:checks})

}
getLocalKeys = async () =>{
  const value = await AsyncStorage.getItem('@UserData');
  this.token = await AsyncStorage.getItem('@token');
  this.user = await AsyncStorage.getItem('@UserData');
  if (value !== null) {
    var data = JSON.parse(value)
    console.log("UserData :: ", data);
    if (data.accountStatus == "pending") {
      this.props.navigation.navigate('SignedUp', {comingFrom:"login", data:data});
    }
    else {
      this.setState({data:data})
      this.setState({UserComponent:<UserComponent name={data.name} address={data.address} profileImg = {data.profileImg}/>})
    }
  }
}

handlelogout = () =>{
  AsyncStorage.clear()
  this.props.navigation.navigate("Login")
}
goBack = (e) =>{
  console.log("goBack");
  this.props.navigation.goBack()
}
handleHelpText =() =>{
  console.log('in handleinf');
}
handleDownload=()=>{
  console.log("downloading ", FileSystem.documentDirectory);
  if (Platform.OS == 'web' ) {
    WebBrowser.openBrowserAsync('https://res.cloudinary.com/qmr-cloudinary/image/upload/v1571659834/Process_for_correct_witnessing_e9zeub.pdf')

    // Linking.openURL('https://res.cloudinary.com/qmr-cloudinary/image/upload/v1570097578/Giving_my_Advanced_Care_Directive_wpjfud.pdf')
  }
  else {
    WebBrowser.openBrowserAsync('https://res.cloudinary.com/qmr-cloudinary/image/upload/v1571659834/Process_for_correct_witnessing_e9zeub.pdf')
    //   FileSystem.downloadAsync(
    //   'https://res.cloudinary.com/qmr-cloudinary/image/upload/v1570097578/Giving_my_Advanced_Care_Directive_wpjfud.pdf',
    //   FileSystem.documentDirectory + "InformationStatement.pdf"
    // )
    //   .then(({ uri }) => {
    //     console.log('Finished downloading to ', uri);
    //   })
    //   .catch(error => {
    //     console.error("error occured: ",error);
    //   });
  }
}
handlePrint =async (e) => {
  console.log("print");
  if(Platform.OS === 'ios' || Platform.OS ==='android'){
    console.log("print mobile inactive");

  }else{
    var options = {uri: "https://res.cloudinary.com/qmr-cloudinary/image/upload/v1570097578/Giving_my_Advanced_Care_Directive_wpjfud.pdf"}
    const response = await Print.printToFileAsync(options);
      console.log("Printing response: ",response);
  }

    // const createDirectory = await FileSystem.makeDirectoryAsync('file://data/user/0/com.scoreboardtech.walao');
    // console.log(createDirectory);
}
handleNext = (e) =>{
  console.log("handleNext");
  this.props.navigation.navigate("AcdFinalReview")
}
componentWillMount(){
  Dimensions.addEventListener("change", this.handler);
}
componentWillUnmount(){
  Dimensions.removeEventListener("change", this.handler);
}
handler =(e) =>{
  console.log('eee', e);
  this.setState({winHeight: e.window.height})
}
toCertifiedCopies =(e)=>{
  this.props.navigation.navigate("CertifiedCopies")

}
emailToWitness =(e)=>{
  console.log('this.jsonUser', this.jsonUser);
  let inScreenText = this.jsonData.inScreenText
  let userData = {inScreenText}
  let form = {userData}
  // console.log('witness_process', witness_process);
  console.log('userData', userData);
  console.log('form', form);
  // this.jsonData.inScreenText.map((x,idx,z) =>{
  //   if(x.isChecked){
  //     console.log('x', x);
  //     witness_process.push(x)
  //   }
  // })
  // console.log('witness_process', witness_process);
  NetInfo.getConnectionInfo().then(connectionInfo => {
    console.log(
      'Initial, type: ' + connectionInfo.type + ', effectiveType: ' + connectionInfo.effectiveType
    );

    if (connectionInfo.type == "none") {
      alert("Your need internet access to send email")
      // global.url = 'https://palcare-backend-dev.herokuapp.com'
      // this.getLocalData()
      console.log("OS :: ", Platform.OS);
      this.setState({downloading:false})
    }
    else {

      this.setState({downloading: true})
      let optionsAxios = ''
      let user = JSON.parse(this.user)

        optionsAxios = {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization': JSON.parse(this.token)},
          url: global.url+'/api/emailWitnessForm',
          data: {witnessEmail: this.jsonUser.email, form:form}
        }

      console.log(" email Options axios ", optionsAxios );
      axios(optionsAxios)
      .then((response)=> {
        this.setState({downloading:false})

        console.log("/email Acd Response recieved ", response);
        if(response.data.success){
          alert(response.data.message)

          // global.userData.userData.acd.isComplete = true

          // WebBrowser.openBrowserAsync(response.data.pdf)


        }else{
          console.log(response.data.message);
        }
      })
      .catch((error)=>{
        console.log("error", error);
        console.log("error message", error.data.message);
      })
    }
  });

}
checkScreen=(e,value)=>{
  console.log(this.jsonData.inScreenText[e].isChecked);
  this.jsonData.inScreenText[e].isChecked = !this.jsonData.inScreenText[e].isChecked


  // let inScreentext = 'this.state.inScreenText' + e.toString()
  // console.log('inScreentext', inScreentext);
  if (e == 0) {
    console.log('in 0 screen', e, value);
    this.setState({inScreenText0: !this.state.inScreenText0})
  }else if (e == 1) {
      console.log('in 1 screen', e, value);
      this.setState({inScreenText1: !this.state.inScreenText1})
    }
    else if (e == 2) {
        console.log('in 2 screen', e, value);
        this.setState({inScreenText2: !this.state.inScreenText2})
      }
      else if (e == 3) {
          console.log('in 3 screen', e, value);
          this.setState({inScreenText3: !this.state.inScreenText3})
        }
        else if (e == 4) {
            console.log('in 4 screen', e, value);
            this.setState({inScreenText4: !this.state.inScreenText4})
          }
          else if (e == 5) {
              console.log('in 5 screen', e, value);
              this.setState({inScreenText5: !this.state.inScreenText5})
            }
            else if (e == 6) {
                console.log('in 6 screen', e, value);
                this.setState({inScreenText6: !this.state.inScreenText6})
              }else if (e == 7) {
                  console.log('in 7 screen', e, value);
                  this.setState({inScreenText7: !this.state.inScreenText7})
                }else if (e == 8) {
                    console.log('in 8 screen', e, value);
                    this.setState({inScreenText8: !this.state.inScreenText8})
                  }else if (e == 9) {
                      console.log('in 9 screen', e, value);
                      this.setState({inScreenText9: !this.state.inScreenText9})
                    }else if (e == 10) {
                        console.log('in 10 screen', e, value);
                        this.setState({inScreenText10: !this.state.inScreenText10})
                      }
  //
  // setTimeout(function () {
  //   console.log('state is 0', this.state);
  // }, 100);
  //
  // console.log(this.jsonData.inScreenText[e].isChecked);
}

render() {
  return (
    <View style={[global.container], {height:this.state.winHeight, maxHeight:800, justifyContent:"center", alignItems:"center"}}>
      <ScrollView style={ Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'75%',width:'100%'}]:[{height:'70%',width:'100%'}]} contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <View style = {Platform.OS === 'ios' || Platform.OS ==='android' ?[global.ItemsContainerMobile, {marginBottom:20}]:[global.ItemsContainerWeb,{maxHeight:1500}]}>
          {this.state.UserComponent}
          <View style={{width:"95%", alignItems: 'center', justifyContent: 'center'}}>


            <View style={{display:"flex", justifyContent:"space-between", flexDirection:"row",backgroundColor:"#F9F1FF", width:"100%", borderWidth:2, borderColor:"#1D2195" , borderRadius:4 , padding:8}}>
              <View>
                <Text style={{fontSize:14, color:"#1D2195"}} onPress={this.handleDownload}>Download</Text>
                <Text style={{fontSize:14, color:"#1D2195"}} onPress={this.handlePrint}>Print</Text>
              </View>
              <View style={{display:"flex", flexDirection:"row"}}>
                <TouchableOpacity onPress={this.handleDownload}>
                  <ImageBackground source={require('../../../assets/download.png')} style={{width: 43, height:35, marginRight:20}}/>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.handlePrint}>
                  <ImageBackground source={require('../../../assets/printer.png')} style={{width: 37, height:35, marginLeft:5 ,marginRight:15}}/>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{marginBottom:10}}>
            </View>
            <CustomButton border = "true" color="#fff" type="inScreen" onPress={this.emailToWitness} title= "Email to Witness" backgroundColor = '#9126FB' />

            <View style={{width:"100%", alignItems: 'center', justifyContent: 'center'}}>
              <Text style={[styles.descriptionText,{fontWeight:'bold',marginTop:10}]}>{this.jsonData.instructions[0]}</Text>
              <Text style={styles.descriptionText}>
                {this.jsonData.description[0]}
              </Text>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[0].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText0}
                        onPress={() => this.checkScreen(0,this.state.inScreentext0)}

                        />
              </View>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[1].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText1}
                        onPress={() => this.checkScreen(1)}

                        />
              </View>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[2].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText2}
                        onPress={() => this.checkScreen(2)}
                        />
              </View>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[3].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText3}
                        onPress={() => this.checkScreen(3)}
                        />
              </View>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[4].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText4}
                        onPress={() => this.checkScreen(4)}
                        />
              </View>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[5].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText5}
                        onPress={() => this.checkScreen(5)}
                        />
              </View>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[6].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText6}
                        onPress={() => this.checkScreen(6)}
                        />
              </View>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[7].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText7}
                        onPress={() => this.checkScreen(7)}
                        />
              </View>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[8].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText8}
                        onPress={() => this.checkScreen(8)}
                        />
              </View>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[9].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText9}
                        onPress={() => this.checkScreen(9)}
                        />
              </View>
              <View style={ styles.bulletText }>
                    <CheckBox
                        title={this.jsonData.inScreenText[10].question}
                        textStyle={styles.CheckBoxTitle}
                        size={25}
                        containerStyle={styles.CheckBoxContainerStyle}
                        checkedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_pressed.png')} />}
                        uncheckedIcon={<Image style={{width: 30, height: 30}} source={require('../../../assets/Radio_button_notpressed.png')} />}
                        checked={this.state.inScreenText10}
                        onPress={() => this.checkScreen(10)}
                        />
              </View>
              <Text style={styles.descriptionText}>
                {this.jsonData.description[1]}
              </Text>
            </View>
            <CustomButton border = "true" color="#1D2195" type="multiples" onPress = {this.toCertifiedCopies} title= "Information on making certified copies" backgroundColor = '#F9F1FF' />
          </View>
        </View>
      </ScrollView>
      <ScrollView scrollEnabled={false} style={Platform.OS === 'ios' || Platform.OS ==='android' ?[{height:'25%', width:'100%'}]:[{height:'30%', width:'100%', maxWidth:800, minWidth:800}]}
        contentContainerStyle={{width:'100%', alignItems:'center', justifyContent:'center'}}>
        <CustomButton title = "➔" navigation= {this.props.navigation} onPress = {this.handleNext}/>
          <ACDfooter  title="6" marginLeft="80%" />
      </ScrollView>
    </View>
  );
}
}
const styles = StyleSheet.create({
  buttonContainer:{
    alignItems:'center',
    marginTop:50,
    marginBottom:10,
    width:'90%',
    // justifyContent:'center',
  },
  loginButton:{
    borderRadius:5,
  },
  arrow:{
    fontSize: 20,
  },
  inputField:{
    width:'100%',
    flexDirection:'row',
    height:50,
    alignItems:'center',
    paddingLeft:10,
    paddingRight:10,
    marginBottom:8,
    justifyContent:'space-between',
    borderWidth:.5,
    borderColor:'#1D2195'
  },
  textInputStyle:{
    height: 40,
    width:'78%',
    alignSelf:'flex-end',
    // outline:'none',
    borderColor:'transparent',
  },
  textstyle:{
    alignItems:'center',
    justifyContent:'center',
    color:'#1D2195',
    fontSize:12,
    textAlign:'center'
  },
  CheckBoxTitle:{
    width:"90%",
    color:'#1D2195',
    fontWeight:"normal"
  },
  CheckBoxContainerStyle:{
    backgroundColor:"transparent",
    borderWidth:0,
    width:"100%"
  },
  descriptionText:{
   marginBottom:10,
   // fontWeight:'bold',
   width:'90%',
   marginLeft:10,
   marginRight:10,
   textAlign:'left',
   color:'#1D2195',
 },
 bulletText:{
   // fontWeight:'bold',
   width:'90%',
   marginLeft:10,
   marginRight:10,
   textAlign:'left',
   color:'#1D2195',
   display:"flex",
   flexDirection:"row"
},
  buttonContainer:{
    flexDirection:'row',
    alignItems:'center',
    marginBottom:30,
    width:'85%',
    // justifyContent:'center',
  },
  bullet: {
    color:'black',
    fontSize:50,
    display:"flex",
    alignItems:'center'
  },
  bulletFont: {
  },
})
